import React, { Image } from "react";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// import Item from '@mui/material/Item';
import Paper from '@mui/material/Paper';
import ClassIcon from '@mui/icons-material/Class';
import InboxIcon from '@mui/icons-material/Inbox';
import PersonIcon from '@mui/icons-material/Person';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { getAuth, signOut, sendEmailVerification, sendPasswordResetEmail, connectAuthEmulator} from "firebase/auth";
import {where, collection, addDoc, getFirestore, doc, getDoc, setDoc, updateDoc, arrayUnion, query, getDocs, increment, serverTimestamp, arrayRemove, orderBy, deleteDoc, onSnapshot} from "firebase/firestore"; 
import {getDownloadURL, getStorage, ref, uploadBytes, deleteObject} from "firebase/storage"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import FlatList from 'flatlist-react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReplayIcon from '@mui/icons-material/Replay';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ReplyIcon from '@mui/icons-material/Reply';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import banner1 from './banner1.png';
import banner2 from './banner2.png';
import banner3 from './banner3.png';
import banner4 from './banner4.png';

import classBanner1 from './Assets/ClassBanners/banner1.png'
import classBanner2 from './Assets/ClassBanners/banner2.png'
import classBanner3 from './Assets/ClassBanners/banner3.png'
import classBanner4 from './Assets/ClassBanners/banner4.png'
import classBanner5 from './Assets/ClassBanners/banner5.png'
import classBanner6 from './Assets/ClassBanners/banner6.png'
import classBanner7 from './Assets/ClassBanners/banner7.png'
import classBanner8 from './Assets/ClassBanners/banner8.png'
import classBanner9 from './Assets/ClassBanners/banner9.png'
import classBanner10 from './Assets/ClassBanners/banner10.png'
import classBanner11 from './Assets/ClassBanners/banner11.png'
import classBanner12 from './Assets/ClassBanners/banner12.png'
import classBanner13 from './Assets/ClassBanners/banner13.png'

import ProfileBannerPlaceholder from './ProfileBannerPlaceholder1.jpg'
import SettingsIcon from '@mui/icons-material/Settings';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddCommentIcon from '@mui/icons-material/AddComment';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import Snackbar from '@mui/material/Snackbar';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import ImgsViewer from "react-images-viewer";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EquationEditor from "equation-editor-react";
import QuizIcon from '@mui/icons-material/Quiz';
// import {Walkthrough, Beacon} from 'react-walkthrough';
// import Walkthrough from 'mb-react-walkthrough'
import tutorialIcon1 from './Assets/class.png';
import tutorialIcon2 from './Assets/chat.png';
import logo from './peertopeerlogo.png';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import MathView from 'react-math-view';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


class MainDashboard extends React.Component{  
    
    constructor(props){
        super(props);
        this.state = {
            tutorialModalOpen: false,
            settingSignedInView: true,
            dashboardView: 'currentUserProfile',
            activeTab: 'account',
            auth: getAuth(),
            db: getFirestore(),
            storage: getStorage(),

            currentUserEmail: "",
            currentUserFirstName: "",
            currentUserLastName: "",
            currentUserRole: "",
            currentUserClasses: [],
            currentUserQuestions: [],
            currentUserSolutions: [],
            currentUserBanner: "",
            currentUserProfilePicture: "",
            currentUserBanner: "",
            

            focusedClass: "",
            focusedClassMobileJoinLink: "",
            focusedClassFeed: [],
            focusedClassName: "",
            focusedClassBanner: "",
            focusedClassInstructorEmail: "",
            focusedClassInstructorFullName: "",
            focusedClassNumPosts: "",
            focusedClassNumStudents: "",
            focusedClassStudents: [],
            focusedClassNumSolvedPosts: "",
            codeSnippetsOpened: [],

            // new modal stuff
            newModalOpen: false,
            newModalView: "main",

            // new post stuff
            newPostToolsDropdown: [
                {
                    name: 'Image'
                },
                {
                    name: 'Code Snippet'
                },
                {
                    name: 'Equation'
                }
            ],
            newPostSelectedTool: '',

            newPostCodeSnippet: '<pre class="ql-syntax" spellcheck="false">System.out.println("Add your code here");</pre>',
            newPostEquationValue: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}",
            def: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}",
            newPostCodeSnippetsList: [],
            newPostTargetClass: "",
            newPostTargetClassPostingEnabled: true,
            newPostTitle: "",
            newPostTextContent: "",
            newPostError: "",
            newPostImages: [],
            newPostEquationEditorOpen: false,
            newPostEquationsList: [],
            newPostAttachments: [],

            // focused post stuff
            focusedPostID: "",
            focusedPostSolutions: [],
            focusedPostSolutionsCodeSnippetsOpened: [],
            
            focusedPostCreator: "",
            focusedPostCreatorFName: "",
            focusedPostCreatorLName: "",
            focusedPostCreatorProfilePicture: "",
            focusedPostNumKnowledgeBits: 0,
            focusedPostTitle: "",
            focusedPostCodeSnippets: [],
            focusedPostCodeSnippetsOpen: false,
            focusedPostEquations: [],
            focusedPostTextContent: "",
            focusedPostCreationTimestamp: "",
            focusedPostKnowledgeBits: [],
            focusedPostImages: [],
            focusedPostEquations: [],
            focusedPostSolutionsUpdateAction: "add",
            focusedPostSolutionsUpdateSolver: "",
            focusedPostHighlightedKnowledgeBit: "",
            focusedPostOpenedCodeSnippets: [],

            viewSolutionsModalOpen: false,
            viewSolutionsModalFullData: [],
            viewSolutionsModalView: 'loading',

            // new knowledge bit stuff
            newKnowledgeBitModalOpen: false,
            newKnowledgeBitModalView: "main",
            newKnowledgeBitTextContent: "",
            newKnowledgeBitError: "",
            newKnowledgeBitImages: [],
            newKnowledgeBitCodeSnippet: '<pre class="ql-syntax" spellcheck="false">System.out.println("Add your code here");</pre>',
            newKnowledgeBitCodeSnippetsList: [],
            newKnowledgeBitEquationsList: [],
            newKnowledgeBitEquationValue: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}",

            // new child knowledge bit stuff
            newChildKnowledgeBitParentID: "",
            newChildKnowledgeBitRootID: "",
            newChildKnowledgeBitParentCreatorFName: "",
            newChildKnowledgeBitParentCreatorFullName: "",
            newChildKnowledgeBitModalOpen: false,
            newChildKnowledgeBitModalView: "main",
            newChildKnowledgeBitTextContent: "",
            newChildKnowledgeBitError: "",
            newChildKnowledgeBitImages: [],
            newChildKnowledgeBitCodeSnippet: '<pre class="ql-syntax" spellcheck="false">System.out.println("Add your code here");</pre>',
            newChildKnowledgeBitCodeSnippetsList: [],
            newChildKnowledgeBitEquationsList: [],
            newChildKnowledgeBitEquationValue: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}",

            // new class stuff
            newClassName: '',
            newClassID: '',
            newClassError: '',
            newClassBannerID: '',
            newClassJoinLink: '',

            // add class stuff
            addClassID: '',
            addClassError: '',

            openedChildKnowledgeBits: [],

            newClassBanners: [
                {id: 1, banner: classBanner1},
                {id: 2, banner: classBanner2},
                {id: 3, banner: classBanner3},
                {id: 4, banner: classBanner4},
                {id: 5, banner: classBanner5},
                {id: 6, banner: classBanner6},
                {id: 7, banner: classBanner7},
                {id: 8, banner: classBanner8},
                {id: 9, banner: classBanner9},
                {id: 10, banner: classBanner10},
                {id: 11, banner: classBanner11},
                {id: 12, banner: classBanner12},
                {id: 13, banner: classBanner13},

            ],

            // account settings
            accountSettingsModalOpen: false,
            accountSettingsModalView: 'main',
            accountSettingsProfilePictureUpdate: false,
            accountSettingsProfilePictureError: '',
            accountSettingsBannerError: '',
            accountSettingsBannerUpdate: false,
            
            // sidebar stuff
            classListExpaned: false,

            // class settings modal stuff
            classSettingsModalOpen: false,
            classSettingsModalView: 'main',
            classSettingsID: '',
            classSettingsJoinLink: '',
            classSettingsName: '',
            classSettingsMembers: [],
            classSettingsLeaveConfirmationText: '',
            classSettingsLeaveError: '',
            classSettingsDeleteConfirmationText: '',
            classSettingsDeleteError: '',
            classSettingsOpen: '',
            classSettingsPostingEnabled: '',
            classSettingsBanner: 1,
            classSettingsBannerError: '',
            classSettingsUpdateNameError: '',

            // post settings modal stuff
            postSettingsModalOpen: false,
            postSettingsModalView: 'main',
            postSettingsPostID: '',
            postSettingsClassID: '',
            postSettingsTitle: '',
            postSettingsTextContent: '',
            postSettingsContentUpdateError: '',
            postSettingsDeleteConfirmationTextContent: '',
            postSettingsDeleteConfirmationError: '',

            // inbox shit
            currentUserInbox: [],
            inboxFilter: 'new',
            currentUserNumNewInboxItems: 0,

            snackbarOpen: false,
            snackbarText: '',

            imageViewerOpen: false,
            imageViewerImages: [],
            imageViewerCurrentImage: 0

        }

        // binding functions
        this.signOut = this.signOut.bind(this);
        this.getCurrentUser = this.getCurrentUser.bind(this);
        this.handleNewPostTitleChange = this.handleNewPostTitleChange.bind(this);
        this.handleNewPostTextContentChange = this.handleNewPostTextContentChange.bind(this);
        this.uploadNewPost = this.uploadNewPost.bind(this);
        this.resetNewModal = this.resetNewModal.bind(this);
        this.getPost = this.getPost.bind(this);
        this.handNewKnowledgeBitTextContentChange = this.handNewKnowledgeBitTextContentChange.bind(this);
        this.uploadNewKnowledgeBit = this.uploadNewKnowledgeBit.bind(this);
        this.updateFocusedPostSolutions = this.updateFocusedPostSolutions.bind(this);
        this.uploadNewChildKnowledgeBit = this.uploadNewChildKnowledgeBit.bind(this);
        this.handNewClassNameChange = this.handNewClassNameChange.bind(this);
        this.createNewClass = this.createNewClass.bind(this);
        this.getClass = this.getClass.bind(this);
        this.getPost2 = this.getPost2.bind(this);
        this.getPost3 = this.getPost3.bind(this);

        this.sendNewPostInbox = this.sendNewPostInbox.bind(this);
        this.sendNewClassMemberInbox = this.sendNewClassMemberInbox.bind(this);

    }
    
    modules = {
        toolbar: [
          [],
          ['code-block'],
        //   [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        //   ['link', 'image'],
        //   ['clean']
        ],
    }

    listModules = {
        toolbar: false
    }
    // mobile notifs
    async sendMobileNotificationExample(){
        const FIREBASE_API_KEY = "AAAAJ1fyvzw:APA91bF798Q-cea9qges5FFHucUYWrMDOA4kxM0YwOFpA9GXHlC7GEzIC80FVaPP33JHP252PcVW0IP5jGdVhy0eXw6kXi2RTIaD7Fe1Zyf16a4TxB-hfiScKQDiwbQBa8kWdzN8IVF-";

        const message = {
            registration_ids: [
                'e9yPVfxE60rivYBXOm6mYA:APA91bHa0sIidX0Nly0VstQRpNsG86M5FtGGa80uoLki8hv2M_wCr3XfvCrYvrnpLa3_Y2jJFrgqhJ1rEx2o-x3uVhiIoC6j1nxOkjevN9097aY7Q8PizBkaVuomEAwchVd5KUPRuUG4'                
            ],
            notification: {
                title: "Notification Title",
                body: "Notification Body",
                vibrate: 1,
                sound: 1,
                show_in_foreground: true,
                priority: "high",
                content_available: true,
            },
            data: {
                classID: '',
                postID: ''
            },
        }    

        let headers = new Headers({
            "Content-Type": "application/json",
            Authorization: "key=" + FIREBASE_API_KEY,
        });

        fetch("https://fcm.googleapis.com/fcm/send", {
            method: "POST",
            headers,
            body: JSON.stringify(message),
        }).catch((error) => {
            // alert(error);
        }); 
    }


    async leaveClass(){
        this.setState({classSettingsModalView: 'loading'});
        this.setState({classSettingsLeaveError: ""});

        await updateDoc(doc(this.state.db, "Classes/" + this.state.classSettingsID), {
            peers: arrayRemove(this.state.currentUserEmail),
            numPeers: increment(-1) 
        }).then(() => {
            (async () => {
                await updateDoc(doc(this.state.db, "Users/" + this.state.currentUserEmail), {
                    classes: arrayRemove(this.state.classSettingsID)
                }).then(() => {
                    this.showSnackbar("You have left this class");
                    this.setState({classSettingsModalOpen: false})
                    this.setState({classSettingsLeaveError: ''});
                    this.setState({classSettingsLeaveConfirmationText: ''});
                    this.setState({classSettingsID: ''});
                    this.setState({classSettingsModalView: 'main'});
                    this.getCurrentUser();
                }).catch((error) => {   
                    this.setState({classSettingsLeaveError: 'There was an issue removing you from this class'});
                    this.setState({classSettingsModalView: 'leaveClassConfirmation'});
                });
            })().catch((error) => {});
        }).catch((error) => {
            this.setState({classSettingsLeaveError: 'There was an issue removing you from this class'});
            this.setState({classSettingsModalView: 'leaveClassConfirmation'});
        });
    }

    async removeStudent(studentToRemove){
        this.setState({classSettingsModalView: 'loading'});

        await getDoc(doc(this.state.db, "Users/" + this.state.currentUserEmail)).then((currentUserDoc) => {
            if(currentUserDoc.data()['role'] == 'instructor'){
                (async () => {
                    await updateDoc(doc(this.state.db, "Classes/" + this.state.classSettingsID), {
                        peers: arrayRemove(studentToRemove),
                        numPeers: increment(-1) 
                    }).then(() => {
                        (async () => {
                            await updateDoc(doc(this.state.db, "Users/" + studentToRemove), {
                                classes: arrayRemove(this.state.classSettingsID)
                            }).then(() => {
                                this.setState({classSettingsModalOpen: false})
                                this.setState({classSettingsID: ''});
                                this.setState({classSettingsModalView: 'main'});
                                this.setState({classSettingsMembers: []});
                                this.setState({classSettingsName: ""});
                                this.showSnackbar("Removed student from class");
                                this.getCurrentUser();
                            }).catch((error) => {   
                                this.setState({classSettingsModalView: 'classMembers'});
                            });
                        })()
                    }).catch((error) => {
                        this.setState({classSettingsModalView: 'classMembers'});
                    });
                })().catch((error) => {});
            }else{
                this.setState({classSettingsModalView: 'classMembers'});
            }
        }).catch((error) => {
            this.setState({classSettingsModalView: 'classMembers'});
        })
    }

    async deleteClass(){
        this.setState({classSettingsModalView: 'loading'});
        await getDoc(doc(this.state.db, "Classes/" + this.state.classSettingsID)).then((classToDeleteDoc) => {
            classToDeleteDoc.data()['peers'].forEach((peer) => {
                (async () => {
                    await updateDoc(doc(this.state.db, "Users/" + peer), {
                        classes: arrayRemove(this.state.classSettingsID)
                    }).catch((error) => {});
                })().catch((error) => {});
            });
        }).then(() => {
            (async () => {
                await deleteDoc(doc(this.state.db, "Classes/" + this.state.classSettingsID)).then(() => {
                    this.setState({classSettingsModalOpen: false})
                    this.setState({classSettingsDeleteError: ''});
                    this.setState({classSettingsID: ''});
                    this.setState({classSettingsModalView: 'main'});
                    this.setState({classSettingsDeleteConfirmationText: ''});
                    this.getCurrentUser();
                }).catch((error) => {
                    this.setState({classSettingsDeleteError: 'There was an issue deleting this class'});
                    this.setState({classSettingsModalView: 'deleteClassConfirmation'});
                });
            })()
        }).catch((error) => {
            this.setState({classSettingsDeleteError: 'There was an issue deleting this class'});
            this.setState({classSettingsModalView: 'deleteClassConfirmation'});
        });
    }

    async deletePost(postClassID, postID){
        await getDoc(doc(this.state.db, "Classes/" + postClassID + "/Feed/" + postID)).then((postToDeleteDoc) => {
            (async () => {
                await deleteDoc(doc(this.state.db, "Classes/" + postClassID + "/Feed/" + postID)).then(() => {
                    (async () => {
                        await updateDoc(doc(this.state.db, "Classes/" + postClassID), {
                            numPosts: increment(-1)
                        }).then(() => {
                            if(postToDeleteDoc.data()['solutions'].length > 0){
                                (async () => {
                                    await updateDoc(doc(this.state.db, "Classes/" + postClassID), {
                                        numSolvedPosts: increment(-1)
                                    }).catch((error) => {
                                        console.log(error);
                                    });
                                })()
                            }
                            (async () => {
                                await updateDoc(doc(this.state.db, "Users/" + this.state.currentUserEmail), {
                                    posts: arrayRemove(postClassID + "*" + postID)
                                }).then(() => {

                                }).catch((error) => {
                                    console.log(error);
                                });
                            })()
                        })
                    })()
                })
            })()
        }).catch((error) => {
            console.log(error);
        });
    }
    // to inbox functions
    async sendNewPostInbox(newPostID, newPostTitle){
        const targetClassDocRef = doc(this.state.db, "Classes", this.state.newPostTargetClass);
        const targetClassDocSnap = await getDoc(targetClassDocRef);        
        const targetClassPeers = targetClassDocSnap.data()['peers'];
        for(let p = 0; p < targetClassPeers.length; p++){
            if(targetClassPeers[p] != this.state.currentUserEmail){
                (async () => {
                    const peerNewPostInboxCollectionRefString = "Users/" + targetClassPeers[p] + "/Inbox";
                    const peerNewPostInboxCollectionRef = collection(this.state.db, peerNewPostInboxCollectionRefString);
                    await addDoc(peerNewPostInboxCollectionRef, {
                        type: 'newPost',
                        postID: newPostID,
                        postTitle: newPostTitle,
                        postCreator: this.state.currentUserEmail,
                        postCreatorFullName: this.state.currentUserFirstName + " " + this.state.currentUserLastName,
                        postTargetClass: this.state.newPostTargetClass,
                        postTargetClassName: targetClassDocSnap.data()['name'],
                        addedTimestamp: serverTimestamp(),
                        new: true
                    }).then(() => {
                        this.sendNewPostNotification(targetClassPeers[p], this.state.currentUserFirstName, this.state.currentUserLastName, targetClassDocSnap.data()['name'], newPostTitle, this.state.newPostTargetClass, newPostID);
                    }).catch((error) => {})
                })()
            }   
        }
    }

    async sendNewPostNotification(receivingUser, postCreatorFName, postCreatorLName, postTargetClassName, postTitle, dataClassID, dataPostID){
        await getDoc(doc(this.state.db, "Users/" + receivingUser)).then((userDoc) => {
            if(userDoc.data()['notificationsToken'] != ""){
                const FIREBASE_API_KEY = "AAAAJ1fyvzw:APA91bF798Q-cea9qges5FFHucUYWrMDOA4kxM0YwOFpA9GXHlC7GEzIC80FVaPP33JHP252PcVW0IP5jGdVhy0eXw6kXi2RTIaD7Fe1Zyf16a4TxB-hfiScKQDiwbQBa8kWdzN8IVF-";

                const message = {
                    registration_ids: [
                        userDoc.data()['notificationsToken']
                    ],
                    notification: {
                        title: postTargetClassName + ": New Question",
                        body: postCreatorFName + " " + postCreatorLName + ": "  + postTitle,
                        vibrate: 1,
                        sound: 1,
                        show_in_foreground: true,
                        priority: "high",
                        content_available: true,
                    },
                    data: {
                        classID: dataClassID,
                        postID: dataPostID,
                        postClassName: postTargetClassName
                    },                    
                }    
        
                let headers = new Headers({
                    "Content-Type": "application/json",
                    Authorization: "key=" + FIREBASE_API_KEY,
                });
        
                fetch("https://fcm.googleapis.com/fcm/send", {
                    method: "POST",
                    headers,
                    body: JSON.stringify(message),
                }).catch((error) => {}); 
            }
        }).catch((error) => {});
    }

    async sendNewClassMemberInbox(classID){
        const classDocRef = doc(this.state.db, "Classes", classID);
        const classDocSnap = await getDoc(classDocRef);
        const classInstructorEmail = classDocSnap.data()['instructor'];

        const classInstructorInboxCollectionRefString = "Users/" + classInstructorEmail + "/Inbox";
        const classInstructorInboxCollectionRef = collection(this.state.db, classInstructorInboxCollectionRefString);
        await addDoc(classInstructorInboxCollectionRef, {
            type: 'newClassMember',
            newMemberFullName: this.state.currentUserFirstName + " " + this.state.currentUserLastName,
            newMemberClassName: classDocSnap.data()['name'],
            addedTimestamp: serverTimestamp(),
            new: true
        }).then(() => {
            // this.sendNewClassMemberNotification(classInstructorEmail, this.state.currentUserFirstName + " " + this.state.currentUserLastName, classDocSnap.data()['name'])
        }).catch((error) => {});
    }

    async sendNewClassMemberNotification(receivingUser, newMemberFullName, newMemberClassName){
        await getDoc(doc(this.state.db, "Users/" + receivingUser)).then((userDoc) => {
            if(userDoc.data()['notificationsToken'] != ""){
                const FIREBASE_API_KEY = "AAAAJ1fyvzw:APA91bF798Q-cea9qges5FFHucUYWrMDOA4kxM0YwOFpA9GXHlC7GEzIC80FVaPP33JHP252PcVW0IP5jGdVhy0eXw6kXi2RTIaD7Fe1Zyf16a4TxB-hfiScKQDiwbQBa8kWdzN8IVF-";

                const message = {
                    registration_ids: [
                        userDoc.data()['notificationsToken']
                    ],
                    notification: {
                        title: newMemberClassName + ": New class member",
                        body: newMemberFullName + " joined your class",
                        vibrate: 1,
                        sound: 1,
                        show_in_foreground: true,
                        priority: "high",
                        content_available: true,
                    }
                }    
        
                let headers = new Headers({
                    "Content-Type": "application/json",
                    Authorization: "key=" + FIREBASE_API_KEY,
                });
        
                fetch("https://fcm.googleapis.com/fcm/send", {
                    method: "POST",
                    headers,
                    body: JSON.stringify(message),
                }).catch((error) => {}); 
            }
        }).catch((error) => {});        
    }

    async sendNewParentKnowledgeBitInbox(newKnowledgeBitTextContent, classID, postID){
        const postCreatorInboxCollectionRefString = "Users/" + this.state.focusedPostCreator + "/Inbox";
        const postCreatorInboxCollectionRef = collection(this.state.db, postCreatorInboxCollectionRefString);
        await addDoc(postCreatorInboxCollectionRef, {
            type: 'newParentKnowledgeBit',
            focusedPostTitle: this.state.focusedPostTitle,
            knowledgeBitCreatorFullName: this.state.currentUserFirstName + " " + this.state.currentUserLastName,
            knowledgeBitTextContent: newKnowledgeBitTextContent,
            addedTimestamp: serverTimestamp(),
            new: true,
            knowledgeBitClass: this.state.focusedClassName
        }).then(() => {
            this.sendNewParentKnowledgeBitNotification(this.state.focusedPostCreator, this.state.currentUserFirstName + " " + this.state.currentUserLastName, this.state.focusedClassName, newKnowledgeBitTextContent, classID, postID);
        }).catch((error) => {});
    }    

    async sendNewParentKnowledgeBitNotification(receivingUser, knowledgeBitCreatorFullName, knowledgeBitClass, knowledgeBitTextContent, dataClassID, dataPostID){
        await getDoc(doc(this.state.db, "Users/" + receivingUser)).then((userDoc) => {
            if(userDoc.data()['notificationsToken'] != ""){
                const FIREBASE_API_KEY = "AAAAJ1fyvzw:APA91bF798Q-cea9qges5FFHucUYWrMDOA4kxM0YwOFpA9GXHlC7GEzIC80FVaPP33JHP252PcVW0IP5jGdVhy0eXw6kXi2RTIaD7Fe1Zyf16a4TxB-hfiScKQDiwbQBa8kWdzN8IVF-";

                const message = {
                    registration_ids: [
                        userDoc.data()['notificationsToken']
                    ],
                    notification: {
                        title: knowledgeBitClass + ": New Knowledge Bit",
                        body: knowledgeBitCreatorFullName + " added a Knowledge Bit to your post: " + knowledgeBitTextContent,
                        vibrate: 1,
                        sound: 1,
                        show_in_foreground: true,
                        priority: "high",
                        content_available: true,
                    },
                    data: {
                        classID: dataClassID,
                        postID: dataPostID,
                        postClassName: knowledgeBitClass
                    },                       
                }    
        
                let headers = new Headers({
                    "Content-Type": "application/json",
                    Authorization: "key=" + FIREBASE_API_KEY,
                });
        
                fetch("https://fcm.googleapis.com/fcm/send", {
                    method: "POST",
                    headers,
                    body: JSON.stringify(message),
                }).catch((error) => {}); 
            }
        }).catch((error) => {});
    }

    // sent to post creator => kb creator replied to reciepient kb creator on your post postTitle: kb text content
    async sendNewKnowledgeBitInPostThreadInbox(){
        const postCreatorInboxCollectionRefString = "Users/" + this.state.focusedPostCreator + "/Inbox";
        const postCreatorInboxCollectionRef = collection(this.state.db, postCreatorInboxCollectionRefString);
        await addDoc(postCreatorInboxCollectionRef, {
            type: 'newChildKnowledgeBit',
            focusedPostTitle: this.state.focusedPostTitle,
            knowledgeBitCreatorFullName: this.state.currentUserFirstName + " " + this.state.currentUserLastName,
            knowledgeBitTextContent: this.state.newChildKnowledgeBitTextContent,
            addedTimestamp: serverTimestamp(),
            new: true,
            knowledgeBitClass: this.state.focusedClassName
        }).then(() => {
            // send mobile notifications
            console.log('sent new parent knowledge bit to inbox of:' + this.state.focusedPostCreator)
            // this.sendNewKnowledgeBitInPostThreadNotification(focusedPostCreator, );
        }).catch((error) => {
            console.log('issue sending new parent knowledge bit to inbox of:' + this.state.focusedPostCreator);
        })
    }

    // async sendNewKnowledgeBitInPostThreadNotification(receivingUser, ){
    //     await getDoc(doc(this.state.db, "Users/" + receivingUser)).then((userDoc) => {
    //         if(userDoc.data()['notificationsToken'] != ""){
    //             const FIREBASE_API_KEY = "AAAAcLJKB7I:APA91bH2EH98il678Zsmt6z8_SdNBZ-9SJNT1mA74sID5kSkFVd992-cRZZZfJsju5lopZrZcaTHZ6Q078OYAfcF-hwxUGRrqlwU_-Iu45ngshJhrGeR3t2KxOYfV2QZTLagluz-jGVM";

    //             const message = {
    //                 registration_ids: [
    //                     userDoc.data()['notificationsToken']
    //                 ],
    //                 notification: {
    //                     title: "someone replied to someone else in your post in class 1",
    //                     body: knowledgeBitTextContent,
    //                     vibrate: 1,
    //                     sound: 1,
    //                     show_in_foreground: true,
    //                     priority: "high",
    //                     content_available: true,
    //                 }
    //             }    
        
    //             let headers = new Headers({
    //                 "Content-Type": "application/json",
    //                 Authorization: "key=" + FIREBASE_API_KEY,
    //             });
        
    //             fetch("https://fcm.googleapis.com/fcm/send", {
    //                 method: "POST",
    //                 headers,
    //                 body: JSON.stringify(message),
    //             }).catch((error) => {
    //                 alert(error);
    //             }); 
    //         }
    //     }).catch((error) => {
    //         console.log(error);
    //     });         
    // }

    async sendNewKnowledgeBitReplyInbox(reciever){
        const parentKnowledgeBitInboxCollectionRefString = "Users/" + reciever + "/Inbox";
        const parentKnowledgeBitInboxCollectionRef = collection(this.state.db, parentKnowledgeBitInboxCollectionRefString);
        await addDoc(parentKnowledgeBitInboxCollectionRef, {
            type: 'newReplyKnowledgeBit',
            knowledgeBitCreatorFullName: this.state.currentUserFirstName + " " + this.state.currentUserLastName,
            knowledgeBitTextContent: this.state.newChildKnowledgeBitTextContent,
            parentKnowledgeBitTextContent: this.state.newChildKnowledgeBitParentKnowledgeBitTextContent,
            addedTimestamp: serverTimestamp(),
            new: true,
            knowledgeBitClass: this.state.focusedClassName
        }).then(() => {
            this.sendNewKnowledgeBitReplyNotification(this.state.newChildKnowledgeBitParentCreator, this.state.currentUserFirstName + " " + this.state.currentUserLastName, this.state.focusedClassName, this.state.newChildKnowledgeBitTextContent, this.state.focusedPostID, this.state.focusedClass);
        }).catch((error) => {
            console.log('issue sending new parent knowledge bit to inbox of:' + this.state.focusedPostCreator)
        })        
    }

    async sendNewKnowledgeBitReplyNotification(receivingUser, knowledgeBitCreatorFullName, knowledgeBitClassName, knowledgeBitTextContent, dataPostID, dataClassID){
        console.log(knowledgeBitTextContent);
        await getDoc(doc(this.state.db, "Users/" + receivingUser)).then((userDoc) => {
            if(userDoc.data()['notificationsToken'] != ""){
                const FIREBASE_API_KEY = "AAAAJ1fyvzw:APA91bF798Q-cea9qges5FFHucUYWrMDOA4kxM0YwOFpA9GXHlC7GEzIC80FVaPP33JHP252PcVW0IP5jGdVhy0eXw6kXi2RTIaD7Fe1Zyf16a4TxB-hfiScKQDiwbQBa8kWdzN8IVF-";

                const message = {
                    registration_ids: [
                        userDoc.data()['notificationsToken']
                    ],
                    notification: {
                        title: knowledgeBitClassName + ": New Reply",
                        body: knowledgeBitCreatorFullName + " replied to your Knowledge Bit: " + knowledgeBitTextContent,
                        vibrate: 1,
                        sound: 1,
                        show_in_foreground: true,
                        priority: "high",
                        content_available: true,
                    },
                    data: {
                        classID: dataClassID,
                        postID: dataPostID,
                        postClassName: knowledgeBitClassName
                    }
                }    
        
                let headers = new Headers({
                    "Content-Type": "application/json",
                    Authorization: "key=" + FIREBASE_API_KEY,
                });
        
                fetch("https://fcm.googleapis.com/fcm/send", {
                    method: "POST",
                    headers,
                    body: JSON.stringify(message),
                }).catch((error) => {
                    // alert(error);
                }); 
            }
        }).catch((error) => {
            console.log(error);
        }); 
    }

    async sendMarkedSolutionInbox(){
        const solverInboxCollectionRefString = "Users/" + this.state.focusedPostSolutionsUpdateSolver + "/Inbox";
        const solverInboxCollectionRef = collection(this.state.db, solverInboxCollectionRefString);
        await addDoc(solverInboxCollectionRef, {
            type: 'newMarkedSolution',
            postCreatorName: this.state.currentUserFirstName + " " + this.state.currentUserLastName,
            solutionKnowledgeBitTextContent: this.state.solutionKnowledgeBitTextContent,
            postTitle: this.state.focusedPostTitle,
            addedTimestamp: serverTimestamp(),
            new: true,
            postClassName: this.state.focusedClassName
        }).then(() => {
            // send mobile notifications
            this.sendMarkedSolutionNotification(this.state.focusedPostSolutionsUpdateSolver, this.state.currentUserFirstName + " " + this.state.currentUserLastName, this.state.solutionKnowledgeBitTextContent, this.state.focusedClassName, this.state.focusedClass, this.state.focusedPostID);
            console.log('sent new parent knowledge bit to inbox of:' + this.state.focusedPostCreator)
        }).catch((error) => {
            console.log('issue sending new parent knowledge bit to inbox of:' + this.state.focusedPostCreator)
        })   
    }


    async sendMarkedSolutionNotification(receivingUser, postCreatorName, solutionKnowledgeBitTextContent, className, dataClassID, dataPostID){
        await getDoc(doc(this.state.db, "Users/" + receivingUser)).then((userDoc) => {
            if(userDoc.data()['notificationsToken'] != ""){
                const FIREBASE_API_KEY = "AAAAJ1fyvzw:APA91bF798Q-cea9qges5FFHucUYWrMDOA4kxM0YwOFpA9GXHlC7GEzIC80FVaPP33JHP252PcVW0IP5jGdVhy0eXw6kXi2RTIaD7Fe1Zyf16a4TxB-hfiScKQDiwbQBa8kWdzN8IVF-";

                const message = {
                    registration_ids: [
                        userDoc.data()['notificationsToken']
                    ],
                    notification: {
                        title: className + ": Marked Solution",
                        body: postCreatorName + " marked your Knowledge Bit as a solution to their post: " + solutionKnowledgeBitTextContent,
                        vibrate: 1,
                        sound: 1,
                        show_in_foreground: true,
                        priority: "high",
                        content_available: true,
                    },
                    data: {
                        classID: dataClassID,
                        postID: dataPostID,
                        postClassName: className
                    },                      

                }    
        
                let headers = new Headers({
                    "Content-Type": "application/json",
                    Authorization: "key=" + FIREBASE_API_KEY,
                });
        
                fetch("https://fcm.googleapis.com/fcm/send", {
                    method: "POST",
                    headers,
                    body: JSON.stringify(message),
                }).catch((error) => {
                    // alert(error);
                }); 
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    // DONE
    async getCurrentUser(){ 
        const userDocRefSnap = await getDoc(doc(this.state.db, "Users", this.state.auth.currentUser['email'])).catch((error) => {});
        if(userDocRefSnap.exists()){
            
            this.setState({currentUserEmail: this.state.auth.currentUser['email']});
            this.setState({currentUserRole: userDocRefSnap.data()['role']})
            this.setState({currentUserFirstName: userDocRefSnap.data()['fName']});
            this.setState({currentUserLastName: userDocRefSnap.data()['lName']});
            this.setState({currentUserClasses: []});
            this.setState({currentUserSolutions: []});
            this.setState({currentUserQuestions: []});

            if(userDocRefSnap.data()['tutorialCompleted'] == false){
                this.setState({tutorialModalOpen: true});
            }

            if(userDocRefSnap.data()['banner'] != ""){
                const currentUserBannerRef = ref(this.state.storage, "ProfileBanners/" + userDocRefSnap.data()['banner']);
                await getDownloadURL(currentUserBannerRef).then((url) => {
                    this.setState({currentUserBanner: url});
                }).catch((error) => {
                    this.setState({currentUserBanner: ''});
                });
            }else{
                this.setState({currentUserBanner: ""})
            }

            if(userDocRefSnap.data()['profilePicture'] != ""){
                const currentUserProfilePictureRef = ref(this.state.storage, "ProfilePictures/" + userDocRefSnap.data()['profilePicture']);
                await getDownloadURL(currentUserProfilePictureRef).then((url) => {
                    this.setState({currentUserProfilePicture: url})
                }).catch((error) => {
                    this.setState({currentUserProfilePicture: ''})
                });
            }else{
                this.setState({currentUserProfilePicture: ""})
            }            

            await getDocs(query(collection(this.state.db, "Classes/"), where('peers', 'array-contains', this.state.auth.currentUser['email']), orderBy('lastPostedTo', 'desc'))).then((userClasses) => {
                let currentUserClassesList = [];
                for(const userClassDoc of userClasses.docs){
                    currentUserClassesList.push(
                        {
                            classID: userClassDoc.id, 
                            classJoinLink: userClassDoc.data()['joinLink'], 
                            name: userClassDoc.data()['name'],
                            bannerTing: userClassDoc.data()['banner'],
                            open: userClassDoc.data()['open'],
                            postingEnabled: userClassDoc.data()['postingEnabled']

                        }
                    ); 
                    this.setState({currentUserClasses: currentUserClassesList});                       
                }
            }).catch((error) => {
                this.setState({dashboardView: 'error'})
            });   

            let currentUserQuestionsList = [];
            for(let q = 0; q < userDocRefSnap.data()['posts'].length; q++){
                let postClassID = userDocRefSnap.data()['posts'][q].split("*")[0]
                let postID = userDocRefSnap.data()['posts'][q].split("*")[1]
                const postDocRefString = "Classes/" + postClassID + "/Feed/" + postID;
                const postDocRef = doc(this.state.db, postDocRefString)
                await getDoc(postDocRef).then((postDocSnap) => {
                    let postCreationTimestampFinalString = "";
                    let postCreationTimestampArray = postDocSnap.data()['creationTimestamp'].toDate().toString().split(" ");
                    let postCreationTimestampPeriod = "pm";
                    let postCreationTimestampHour = postCreationTimestampArray[4].split(":")[0];
                    let postCreationTimestampMinute = postCreationTimestampArray[4].split(":")[1]
                    if(postCreationTimestampHour > 12){
                        postCreationTimestampHour = postCreationTimestampHour - 12;
                        if(postCreationTimestampHour >= 12){
                            postCreationTimestampPeriod = "pm";
                        }
                    }else{
                        if(postCreationTimestampHour == "00"){
                            postCreationTimestampHour = "12"    
                        }else{
                            postCreationTimestampHour = postCreationTimestampHour;
                        }
                        postCreationTimestampPeriod = "am";
                    }
                    postCreationTimestampFinalString = postCreationTimestampArray[1] + " " + postCreationTimestampArray[2] + " " + postCreationTimestampHour + ":" + postCreationTimestampMinute + postCreationTimestampPeriod;                
    
                    currentUserQuestionsList.push({
                        questionID: userDocRefSnap.data()['posts'][q].split("*")[1],
                        questionClassID: userDocRefSnap.data()['posts'][q].split("*")[0],
                        questionTitle: postDocSnap.data()['title'],
                        questionTextContent: postDocSnap.data()['textContent'],
                        questionCreationTimestamp: postCreationTimestampFinalString,
                        questionNumKnowledgeBits: postDocSnap.data()['numKnowledgeBits'],
                        questionHidden: postDocSnap.data()['hidden'],
                    });

                    this.setState({currentUserQuestions: currentUserQuestionsList});
                }).catch((error) => {});
            }

            let currentUserSolutionsList = [];
            for (let s = 0; s < userDocRefSnap.data()['solutions'].length; s++) {
                let solution = userDocRefSnap.data()['solutions'][s];
                let solutionClassID = solution.split("*")[0];
                let solutionPostID = solution.split("*")[1];

                const solutionPostRefString = "Classes/" + solutionClassID + "/Feed/" + solutionPostID;
                const solutionPostRef = doc(this.state.db, solutionPostRefString);
                const solutionPostDocSnap = await getDoc(solutionPostRef).then((solutionPostDocSnap) => {
                    if(solutionPostDocSnap.exists){
                        let postCreationTimestampFinalString = "";
                        let postCreationTimestampArray = solutionPostDocSnap.data()['creationTimestamp'].toDate().toString().split(" ");
                        let postCreationTimestampPeriod = "pm";
                        let postCreationTimestampHour = postCreationTimestampArray[4].split(":")[0];
                        let postCreationTimestampMinute = postCreationTimestampArray[4].split(":")[1]
                        if(postCreationTimestampHour > 12){
                            postCreationTimestampHour = postCreationTimestampHour - 12;
                            if(postCreationTimestampHour >= 12){
                                postCreationTimestampPeriod = "pm";
                            }
                        }else{
                            if(postCreationTimestampHour == "00"){
                                postCreationTimestampHour = "12"    
                            }else{
                                postCreationTimestampHour = postCreationTimestampHour;
                            }
                            postCreationTimestampPeriod = "am";
                        }
                        postCreationTimestampFinalString = postCreationTimestampArray[1] + " " + postCreationTimestampArray[2] + " " + postCreationTimestampHour + ":" + postCreationTimestampMinute + postCreationTimestampPeriod;                
        
                        currentUserSolutionsList.push({
                            postClassID: solutionClassID,
                            postID: solutionPostID,
                            postCreationTimestamp: postCreationTimestampFinalString,
                            postTitle: solutionPostDocSnap.data()['title'],
                            postNumKnowledgeBits: solutionPostDocSnap.data()['numKnowledgeBits'],
                        
                        });
                        this.setState({currentUserSolutions: currentUserSolutionsList});
                    }
                }).catch((error) => {});
            }

            this.setState({settingSignedInView: false});
            this.setState({dashboardView: 'currentUserProfile'});
        }else{
            this.setState({dashboardView: 'error'})
            console.log('you dont exist :(');
        }
    }

    async onMount(){
        const userDocRefSnap = await getDoc(doc(this.state.db, "Users", this.state.auth.currentUser['email']));
        let currentUserClassesList = [];

        if(userDocRefSnap.exists()){
            const q = query(collection(this.state.db, "Users/" + this.state.auth.currentUser['email'] + "/Inbox"), where("new", "==", true));
            onSnapshot(q, (querySnapshot) => {
                this.setState({currentUserNumNewInboxItems: querySnapshot.docs.length})
            });
    
            if(userDocRefSnap.data()['classes'].length > 0){
                this.setState({currentUserEmail: this.state.auth.currentUser['email']});
                this.setState({currentUserRole: userDocRefSnap.data()['role']})
                this.setState({currentUserFirstName: userDocRefSnap.data()['fName']});
                this.setState({currentUserLastName: userDocRefSnap.data()['lName']});
                
                await getDocs(query(collection(this.state.db, "Classes/"), where('peers', 'array-contains', this.state.auth.currentUser['email']), orderBy('lastPostedTo', 'desc'))).then((userClasses) => {
                    for(const userClassDoc of userClasses.docs){
                        currentUserClassesList.push(
                            {
                                classID: userClassDoc.id, 
                                classJoinLink: userClassDoc.data()['joinLink'], 
                                name: userClassDoc.data()['name'],
                                bannerTing: userClassDoc.data()['banner'],
                                open: userClassDoc.data()['open']
                            }
                        ); 
                        this.setState({currentUserClasses: currentUserClassesList});                       
                    }
                }).then(() => {
                    // get inbox num
                    (async () => {
                        await getDocs(query(collection(this.state.db, "Users/" + this.state.currentUserEmail + "/Inbox"), where('new', '==', true), orderBy('addedTimestamp', 'desc'))).then((currentUserInboxSnap) => {
                            this.setState({currentUserNumNewInboxItems: currentUserInboxSnap.docs.length})
                        }).catch((error) => {
                            console.log(error);
                        });
                    })()
                }).catch((error) => {
                    this.setState({dashboardView: 'error'})
                    console.log(error);
                }); 

                this.setState({settingSignedInView: false});
                this.setState({activeTab: 'class'});
                this.setState({focusedClass: this.state.currentUserClasses[0].classID}, this.getClass2);
            }else{
                this.getCurrentUser();

            }
        }else{
            this.setState({dashboardView: 'error'})
            console.log('you dont exist :(');
        }
        
    }

    async getClass(){
        this.setState({dashboardView: 'loading'})
        const storage = getStorage()
        const focusedClassDocRefString = "Classes/" + this.state.focusedClass;
        const focusedClassDocRef = doc(this.state.db, focusedClassDocRefString)
        const focusedClassDocSnap = await getDoc(focusedClassDocRef)

        this.setState({focusedClassName: focusedClassDocSnap.data()['name']});
        this.setState({focusedClassInstructorEmail: focusedClassDocSnap.data()['instructor']});
        this.setState({focusedClassBanner: focusedClassDocSnap.data()['banner']});
        this.setState({focusedClassNumPosts: focusedClassDocSnap.data()['numPosts']});
        this.setState({focusedClassNumSolvedPosts: focusedClassDocSnap.data()['numSolvedPosts']})

        const focusedClassInstructorDocRefString = "Users/" + focusedClassDocSnap.data()['instructor'];
        const focusedClassInstructorDocRef = doc(this.state.db, focusedClassInstructorDocRefString)
        const focusedClassInstructorDocSnap = await getDoc(focusedClassInstructorDocRef)

        this.setState({focusedClassInstructorFullName: focusedClassInstructorDocSnap.data()['fName'] + " " + focusedClassInstructorDocSnap.data()['lName']});
        let focusedClassStudentList = []

        for(let l = 0; l < focusedClassDocSnap.data()['peers']; l++){
            focusedClassStudentList.push(focusedClassDocSnap.data()['peers'][l]);
        }
        this.setState({focusedClassStudents: focusedClassStudentList}, () => {
            console.log('TINGINGINGINGINGINGIGN')
            console.log(this.state.focusedClassStudents)
        });
        
        const feedQuery = query(collection(this.state.db, "Classes", this.state.focusedClass, "Feed"));
        const feedQuerySnapshot = await getDocs(feedQuery)
        let feedList = []
        
        feedQuerySnapshot.forEach((post) => {
            
            (async () => {
                const postCreatorDocRef = doc(this.state.db, "Users", post.data()['creator']);
                const postCreatorDocSnap = await getDoc(postCreatorDocRef);
            
                let postSolved = "false";

                if(post.data()['solutions'].length > 0){
                    postSolved = "true";
                }else{
                    postSolved = "false";
                }

                let finalPostDateString = "";
                let finalPostPeriod = "pm";
                let postTimestampArray = post.data()['creationTimestamp'].toDate().toString().split(" ")
                let postTimestampHour = postTimestampArray[4].split(":")[0]
                if(postTimestampHour > 12){
                    postTimestampHour = postTimestampHour - 12
                    if(postTimestampHour >= 12){
                        finalPostPeriod = "pm"
                    }
                }else{
                    postTimestampHour = postTimestampHour;
                    finalPostPeriod = "am"
                }

                let postTimestampMinute = postTimestampArray[4].split(":")[1]
                finalPostDateString = postTimestampArray[1] + " " + postTimestampArray[2] + " " + postTimestampHour + ":" + postTimestampMinute + finalPostPeriod

                let postCreatorProfilePicure = "";
                if(postCreatorDocSnap.data()['profilePicture'] != ""){
                    (async () => {
                        const postCreatorProfilePictureRef = ref(storage, "ProfilePictures/" + postCreatorDocSnap.data()['profilePicture']);
                        await getDownloadURL(postCreatorProfilePictureRef).then((url) => {
                            postCreatorProfilePicure = url;
                        }).catch((error) => {
                            console.log("error getting ornwload ur dfor user pp")
                            postCreatorProfilePicure = "";
                        });
                    })()
                }else{
                    console.log("doesnt have profile picture")
                    postCreatorProfilePicure = "";
                }

                feedList.push({
                    postID: post.id,
                    postTitle: post.data()['title'],
                    postTextContent: post.data()['textContent'],
                    postCreatorFName: postCreatorDocSnap.data()['fName'],
                    postCreatorLName: postCreatorDocSnap.data()['lName'],
                    postCreatorProfilePicure: postCreatorProfilePicure,
                    postCreationTimestamp: finalPostDateString,
                    postSolved: postSolved,
                    postNumKnowledgeBits: post.data()['numKnowledgeBits']
                });
            })()
        });

        this.setState({focusedClassFeed: feedList}, (this.setState({dashboardView: 'classFeed'})));
        this.setState({activeTab: 'class'});
        this.setState({newPostTargetClass: ""});
        this.setState({addClassID: ""});
        this.setState({newClassID: ""});


    }    

    async getClass2(){
        const storage = getStorage();
        this.setState({dashboardView: 'loading'});

        await getDoc(doc(this.state.db, "Classes/" + this.state.focusedClass)).then((focusedClassDocSnap) => {
            if(focusedClassDocSnap.data()['peers'].includes(this.state.currentUserEmail)){
                (async () => {
                    this.setState({focusedClassBanner: focusedClassDocSnap.data()['banner']});
        
                    let focusedClassFeedList = [];
                    const focusedClassFeedSnap = await getDocs(query(collection(this.state.db, "Classes/" + this.state.focusedClass + "/Feed"), orderBy("creationTimestamp", "desc"), where("hidden", "==", false)));

                    for(let p = 0; p < focusedClassFeedSnap.docs.length; p++){

                        let post = focusedClassFeedSnap.docs[p];

                        await getDoc(doc(this.state.db, "Users/" + post.data()['creator'])).then((postCreatorDoc) => {

                            let postCreatorProfilePicture = "" 
                            // if(postCreatorDoc.data()['profilePicture'] != ""){
                            //     (async () => {
                            //         await getDownloadURL(ref(storage, "ProfilePictures/" + postCreatorDoc.data()['profilePicture'])).then((url) => {
                            //             postCreatorProfilePicture = url;
                            //         }).catch((error) => {});  
                            //     })().catch((error) => {});
                            // }
    
                            let postCreationTimestampFinalString = "";
                            let postCreationTimestampArray = post.data()['creationTimestamp'].toDate().toString().split(" ");
                            let postCreationTimestampPeriod = "pm";
                            let postCreationTimestampHour = postCreationTimestampArray[4].split(":")[0];
                            let postCreationTimestampMinute = postCreationTimestampArray[4].split(":")[1]
                            if(postCreationTimestampHour > 12){
                                postCreationTimestampHour = postCreationTimestampHour - 12;
                                if(postCreationTimestampHour >= 12){
                                    postCreationTimestampPeriod = "pm";
                                }
                            }else{
                                
                                postCreationTimestampPeriod = "am";
                            }
                            postCreationTimestampFinalString = postCreationTimestampArray[1] + " " + postCreationTimestampArray[2] + " " + postCreationTimestampHour + ":" + postCreationTimestampMinute + postCreationTimestampPeriod;            
            
                            let postSolved = false;
                            if(post.data()['solutions'].length > 0){
                                postSolved = true;
                            }
                            focusedClassFeedList.push({
                                postID: post.id,
                                postTitle: post.data()['title'],
                                postTextContent: post.data()['textContent'],
                                postCreatorFName: post.data()['creatorFullName'].split(" ")[0],
                                postCreatorLName: post.data()['creatorFullName'].split(" ")[1],
                                postCreatorProfilePicture: postCreatorProfilePicture,
                                postCreationTimestamp: postCreationTimestampFinalString,
                                postSolved: postSolved,
                                postNumKnowledgeBits: post.data()['numKnowledgeBits']
                            });
                            // console.log(postCreatorProfilePicture)
                        }).catch((error) => {});


                        

                    }
    
                    this.setState({focusedClassStudents: focusedClassDocSnap.data()['peers']});
    
                    this.setState({focusedClassName: focusedClassDocSnap.data()['name']});
                    this.setState({focusedClassMobileJoinLink: focusedClassDocSnap.data()['joinLink']});
                    // this.setState({focusedClassNumPosts: focusedClassDocSnap.data()['numPosts']});
                    this.setState({focusedClassNumPosts: focusedClassFeedList.length});
                    this.setState({focusedClassNumStudents: focusedClassDocSnap.data()['peers'].length})
                    this.setState({focusedClassNumSolvedPosts: focusedClassDocSnap.data()['numSolvedPosts']});
                    this.setState({focusedClassInstructorEmail: focusedClassDocSnap.data()['instructor']});
                    this.setState({focusedClassFeed: focusedClassFeedList}, (this.setState({dashboardView: 'classFeed'})))
                    this.setState({activeTab: 'class'});
                    this.setState({newPostTargetClass: ""});
                    this.setState({addClassID: ""});
                    this.setState({newClassID: ""});
                })()
            }else{
                this.setState({dashboardView: 'error'});    
            }
        }).catch((error) => {
            this.setState({dashboardView: 'error'});
        });
    }

    async getClass3(){
        this.setState({dashboardView: 'loading'});
        const storage = getStorage();

        await getDoc(doc(this.state.db, "Classes/" + this.state.focusedClass)).then((focusedClassDocSnap) => {
            this.setState({focusedClassBanner: focusedClassDocSnap.data()['banner']});
            (async () => {
                await getDocs(query(collection(this.state.db, "Classes/" + this.state.focusedClass + "/Feed"))).then((focusedClassFeedSnap) => {
                    let focusedClassFeedList = [];
                    focusedClassFeedSnap.docs.forEach((post) => {
                        (async () => {
                            await getDoc(doc(this.state.db, "Users/" + post.data()['creator'])).then((postCreatorDocSnap) => {
                                
                            }).catch((error) => {
                                console.log(error);
                            });
                        })()
                    });
                }).catch((error) => {
                    console.log(error);
                });
            })()
        }).catch((error) => {
            console.log(error);
            this.setState({dashboardView: 'error'});
        });



    }

    async getPost3(){
        this.setState({dashboardView: 'loading'});
        const storage = getStorage()

        await getDoc(doc(this.state.db, "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID)).then((postDocSnap) => {
            (async () => {
                await getDoc(doc(this.state.db, "Users/" + postDocSnap.data()['creator'])).then((postCreatorDocSnap) => {

                    let equationsListToPush = [];
                    let index = 1;
                    postDocSnap.data()['equations'].forEach((equation) => {
                        
                        equationsListToPush.push({
                            index: index,
                            equationValue: equation
                        });
                        index = index + 1;
                        this.setState({focusedPostEquations: equationsListToPush}, () => {
                            console.log(this.state.focusedPostEquations);
                        });
                    })     

                    if(postCreatorDocSnap.data()['profilePicture'] != ""){
                        (async () => {
                            const postCreatorProfilePictureRef = ref(storage, "ProfilePictures/" + postCreatorDocSnap.data()['profilePicture']);
                            await getDownloadURL(postCreatorProfilePictureRef).then((url) => {
                                this.setState({focusedPostCreatorProfilePicture: url})
                            }).catch((error) => {
                                console.log(error);
                                this.setState({focusedPostCreatorProfilePicture: ""});
                            });
                        })().catch((error) => {
                            console.log(error);
                        });
                    }else{
                        this.setState({focusedPostCreatorProfilePicture: ""});
                    }

                    let postCreationTimestampFinalString = "";
                    let postCreationTimestampArray = postDocSnap.data()['creationTimestamp'].toDate().toString().split(" ");
                    let postCreationTimestampPeriod = "pm";
                    let postCreationTimestampHour = postCreationTimestampArray[4].split(":")[0];
                    let postCreationTimestampMinute = postCreationTimestampArray[4].split(":")[1]
                    if(postCreationTimestampHour > 12){
                        postCreationTimestampHour = postCreationTimestampHour - 12;
                        if(postCreationTimestampHour >= 12){
                            postCreationTimestampPeriod = "pm";
                        }
                    }else{
                        if(postCreationTimestampHour == "00"){
                            postCreationTimestampHour = "12"    
                        }else{
                            postCreationTimestampHour = postCreationTimestampHour;
                        }

                        postCreationTimestampPeriod = "am";
                    }
                    postCreationTimestampFinalString = postCreationTimestampArray[1] + " " + postCreationTimestampArray[2] + " " + postCreationTimestampHour + ":" + postCreationTimestampMinute + postCreationTimestampPeriod;

                    let postSolutionsList = [];
                    for(let s = 0; s < postDocSnap.data()['solutions'].length; s++){
                        postSolutionsList.push(postDocSnap.data()['solutions'][s]);
                    }

                    let postImagesList = []
                    let imageViewerURLSList = []

                    for(let pI = 0; pI < postDocSnap.data()['images'].length; pI++){
                        (async () => {
                            const postImageRef = ref(storage, "PostsImages/" + postDocSnap.data()['images'][pI]);
                            await getDownloadURL(postImageRef).then((url) => {
                                imageViewerURLSList.push({
                                    src: url
                                });
                                postImagesList.push({
                                    postImageURL: url,
                                    imageViewerURLS: imageViewerURLSList
                                });                                
                            }).catch((error) => {
                                console.log(error)
                            });
                        })().catch((error) => {
                            console.log(error)
                        })
                    }



                    getDocs(query(collection(this.state.db, "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID + "/KnowledgeBits"), where("hierarchy", "==", "parent"), orderBy('lastInteracted', 'desc'))).then((parentKnowledgeBits) => {
                        let postKnowledgeBitsList = [];

                        for(const knowledgeBitDoc of parentKnowledgeBits.docs){

                            let parentKnowledgeBitImagesList = []    
                            let imageViewerURLSList = []

                            for(const parentKnowledgeBitImage of knowledgeBitDoc.data()['images']){
                                (async () => {
                                    const parentKnowledgeBitRef = ref(storage, "KnowledgeBitsImages/" + parentKnowledgeBitImage);
                                    await getDownloadURL(parentKnowledgeBitRef).then((url) => {
                                        imageViewerURLSList.push({
                                            src: url
                                        });
                                        parentKnowledgeBitImagesList.push({
                                            parentKnowledgeBitImageURL: url,
                                            imageViewerURLS: imageViewerURLSList
                                        });
                                    }).catch((error) => {
                                        console.log(error);
                                    });   
                                })().catch((error) => {
                                    console.log(error)
                                });                                 
                            }



                            let childKnowledgeBitsList = [];
                            getDocs(query(collection(this.state.db, "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID + "/KnowledgeBits"), where("rootID", "==", knowledgeBitDoc.id), orderBy('lastInteracted', 'desc'))).then((childKnowledgeBits) => {
                                for(const childKnowledgeBitDoc of childKnowledgeBits.docs){

                                    (async () => {
                                            let childKnowledgeBitsImages = [];          
                                            let imageViewerURLSList = []
        
                                            for(const childKnowledgeBitImage of childKnowledgeBitDoc.data()['images']){
        
                                                (async () => {
                                                    const childKnowledgeBitRef = ref(storage, "KnowledgeBitsImages/" + childKnowledgeBitImage);
                                                    await getDownloadURL(childKnowledgeBitRef).then((url) => {
                                                        imageViewerURLSList.push({
                                                            src: url
                                                        });                                                
                                                        childKnowledgeBitsImages.push({
                                                            childKnowledgeBitImageURL: url,
                                                            imageViewerURLS: imageViewerURLSList
                                                        });
                                                    }).catch((error) => {
                                                        console.log(error);
                                                    });   
                                                })().catch((error) => {});                                 
                                            }
                                            let childKnowledgeBitCreatorProfilePicture = "";
                                            
        
                                            let childKnowledgeBitCreationTimestampFinalString = "";
        
                                            let childKnowledgeBitCreationTimestampArray = childKnowledgeBitDoc.data()['creationTimestamp'].toDate().toString().split(" ");
                                            let childKnowledgeBitCreationTimestampPeriod = "pm";
                                            let childKnowledgeBitCreationTimestampHour = childKnowledgeBitCreationTimestampArray[4].split(":")[0];
                                            let childKnowledgeBitCreationTimestampMinute = childKnowledgeBitCreationTimestampArray[4].split(":")[1]
                                            if(childKnowledgeBitCreationTimestampHour > 12){
                                                childKnowledgeBitCreationTimestampHour = childKnowledgeBitCreationTimestampHour - 12;
                                                if(childKnowledgeBitCreationTimestampHour >= 12){
                                                    childKnowledgeBitCreationTimestampPeriod = "pm";
                                                }
                                            }else{
                                                if(childKnowledgeBitCreationTimestampHour == "00"){
                                                    childKnowledgeBitCreationTimestampHour = "12"    
                                                }else{
                                                    childKnowledgeBitCreationTimestampHour = childKnowledgeBitCreationTimestampHour;
                                                }
                                            
                                                childKnowledgeBitCreationTimestampPeriod = "am";
                                            }
                                            childKnowledgeBitCreationTimestampFinalString = childKnowledgeBitCreationTimestampArray[1] + " " + childKnowledgeBitCreationTimestampArray[2] + " " + childKnowledgeBitCreationTimestampHour + ":" + childKnowledgeBitCreationTimestampMinute + childKnowledgeBitCreationTimestampPeriod;
        

                                            let equationListToPush = [];
                                            let index = 1;
                                            childKnowledgeBitDoc.data()['equations'].forEach((equation) => {
                                                equationListToPush.push({
                                                    index: index,
                                                    equationValue: equation
                                                })
                                                index = index + 1;
                                            });

                                            childKnowledgeBitsList.push({
                                                id: childKnowledgeBitDoc.id,
                                                rootID: knowledgeBitDoc.id,
                                                textContent: childKnowledgeBitDoc.data()['textContent'],
                                                creator: childKnowledgeBitDoc.data()['creator'],
                                                creatorFName: childKnowledgeBitDoc.data()['creatorFullName'].split(" ")[0],
                                                creatorLName: childKnowledgeBitDoc.data()['creatorFullName'].split(" ")[1],
                                                creationTimestamp: childKnowledgeBitCreationTimestampFinalString,
                                                creatorProfilePictureURL: "",
                                                images: childKnowledgeBitsImages,   
                                                replyingToID: childKnowledgeBitDoc.data()['parentID'],
                                                replyingToFullName: childKnowledgeBitDoc.data()['parentFullName'],
                                                codeSnippets: childKnowledgeBitDoc.data()['codeSnippets'],
                                                equations: equationListToPush
        
                                            });
                                    })().catch((error) => {console.log(error)});
                                }

                            }).catch((error) => {
                                // alert(error);
                                console.log(error)});   

                            let parentKnowledgeBitCreationTimestampFinalString = "";

                            let parentKnowledgeBitCreationTimestampArray = knowledgeBitDoc.data()['creationTimestamp'].toDate().toString().split(" ");
                            let parentKnowledgeBitCreationTimestampPeriod = "pm";
                            let parentKnowledgeBitCreationTimestampHour = parentKnowledgeBitCreationTimestampArray[4].split(":")[0];
                            let parentKnowledgeBitCreationTimestampMinute = parentKnowledgeBitCreationTimestampArray[4].split(":")[1]
                            if(parentKnowledgeBitCreationTimestampHour > 12){
                                parentKnowledgeBitCreationTimestampHour = parentKnowledgeBitCreationTimestampHour - 12;
                                if(parentKnowledgeBitCreationTimestampHour >= 12){
                                    parentKnowledgeBitCreationTimestampPeriod = "pm";
                                }
                            }else{
                                if(parentKnowledgeBitCreationTimestampHour == "00"){
                                    parentKnowledgeBitCreationTimestampHour = "12"    
                                }else{
                                    parentKnowledgeBitCreationTimestampHour = parentKnowledgeBitCreationTimestampHour;
                                }

                                parentKnowledgeBitCreationTimestampPeriod = "am";
                            }
                            parentKnowledgeBitCreationTimestampFinalString = parentKnowledgeBitCreationTimestampArray[1] + " " + parentKnowledgeBitCreationTimestampArray[2] + " " + parentKnowledgeBitCreationTimestampHour + ":" + parentKnowledgeBitCreationTimestampMinute + parentKnowledgeBitCreationTimestampPeriod;


                            let equationListToPush = [];
                            let index = 1;
                            knowledgeBitDoc.data()['equations'].forEach((equation) => {
                                equationListToPush.push({
                                    index: index,
                                    equationValue: equation
                                })
                                index = index + 1;
                            });

                            postKnowledgeBitsList.push({
                                id: knowledgeBitDoc.id,
                                textContent: knowledgeBitDoc.data()['textContent'],
                                creator: knowledgeBitDoc.data()['creator'],
                                creatorFName: knowledgeBitDoc.data()['creatorFullName'].split(" ")[0],
                                creatorLName: knowledgeBitDoc.data()['creatorFullName'].split(" ")[1],
                                creationTimestamp: parentKnowledgeBitCreationTimestampFinalString,
                                creatorProfilePictureURL: "",
                                images: parentKnowledgeBitImagesList,
                                children: childKnowledgeBitsList,
                                codeSnippets: knowledgeBitDoc.data()['codeSnippets'],
                                equations: equationListToPush
                            });                              
                          
                        }

                        setTimeout(() => {
                            this.setState({focusedPostTitle: postDocSnap.data()['title']});
                            this.setState({focusedPostCodeSnippets: postDocSnap.data()['codeSnippets']});
                            // this.setState({focusedPostEquations: postDocSnap.data()['equations']});
                            this.setState({focusedPostTextContent: postDocSnap.data()['textContent']});
                            this.setState({focusedPostCreator: postDocSnap.data()['creator']});
                            this.setState({focusedPostCreatorFName: postCreatorDocSnap.data()['fName']});
                            this.setState({focusedPostCreatorLName: postCreatorDocSnap.data()['lName']});
                            this.setState({focusedPostCreationTimestamp: postCreationTimestampFinalString});
                            this.setState({focusedPostSolutions: postSolutionsList});
                            this.setState({focusedPostImages: postImagesList});
                            this.setState({focusedPostHidden: postDocSnap.data()['hidden']});
                            this.setState({focusedPostKnowledgeBits: postKnowledgeBitsList});
                            this.setState({focusedPostNumKnowledgeBits: postDocSnap.data()['numKnowledgeBits']});
                            this.setState({dashboardView: 'post'});                            
                        }, 1000);
                    }).catch((error) => {
                        this.setState({focusedPostKnowledgeBits: []});
                        console.log(error)
                    });
                }).catch((error) => {
                    this.setState({dashboardView: 'error'});
                    console.log(error)
                });
            })().catch((error) => {
                console.log(error)
            });
        }).catch((error) => {
            this.setState({dashboardView: 'error'})
            console.log(error)
        });
    
    } 

    async getPost4(){
        const storage = getStorage()
        this.setState({dashboardView: 'loading'});

        await getDoc(doc(this.state.db, "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID)).then((postDocSnap) => {
            (async () => {
                await getDoc(doc(this.state.db, "Users/" + postDocSnap.data()['creator'])).then((postCreatorDocSnap) => {
                    if(postCreatorDocSnap.data()['profilePicture'] != ""){
                        (async () => {
                            const postCreatorProfilePictureRef = ref(storage, "ProfilePictures/" + postCreatorDocSnap.data()['profilePicture']);
                            await getDownloadURL(postCreatorProfilePictureRef).then((url) => {
                                this.setState({focusedPostCreatorProfilePicture: url})
                            }).catch((error) => {
                                console.log(error);
                            });    
                        })()
                    }
            
                    let postCreationTimestampFinalString = "";
                    let postCreationTimestampArray = postDocSnap.data()['creationTimestamp'].toDate().toString().split(" ");
                    let postCreationTimestampPeriod = "pm";
                    let postCreationTimestampHour = postCreationTimestampArray[4].split(":")[0];
                    let postCreationTimestampMinute = postCreationTimestampArray[4].split(":")[1]
                    if(postCreationTimestampHour > 12){
                        postCreationTimestampHour = postCreationTimestampHour - 12;
                        if(postCreationTimestampHour >= 12){
                            postCreationTimestampPeriod = "pm";
                        }
                    }else{
                        
                        postCreationTimestampPeriod = "am";
                    }
                    postCreationTimestampFinalString = postCreationTimestampArray[1] + " " + postCreationTimestampArray[2] + " " + postCreationTimestampHour + ":" + postCreationTimestampMinute + postCreationTimestampPeriod;

                    let postSolutionsList = [];
                    for(let s = 0; s < postDocSnap.data()['solutions'].length; s++){
                        postSolutionsList.push(postDocSnap.data()['solutions'][s]);
                    }
            
                    let postImagesList = []
                    for(let pI = 0; pI < postDocSnap.data()['images'].length; pI++){
                        (async () => {
                            const postImageRef = ref(storage, "PostsImages/" + postDocSnap.data()['images'][pI]);
                            await getDownloadURL(postImageRef).then((url) => {
                                postImagesList.push({postImageURL: url});
                            }).catch((error) => {
                                console.log(error)
                            });
                        })()
                    }

                    let postKnowledgeBitsList = [];
                    (async () => {
                        await getDocs(query(collection(this.state.db, "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID + "/KnowledgeBits"))).then((postKnowledgeBitsQuerySnap) => {
                            console.log(postKnowledgeBitsQuerySnap.docs)
                            for(let kb = 0; kb < postKnowledgeBitsQuerySnap.docs.length; kb++){
                                let knowledgeBitDoc = postKnowledgeBitsQuerySnap.docs[kb]
                                
                                if(knowledgeBitDoc.data()['hierarchy'] == 'parent'){
                                    (async () => {
                                        await getDoc(doc(this.state.db, "Users/" + knowledgeBitDoc.data()['creator'])).then((parentKnowledgeBitCreatorSnap) => {
                                            let parentKnowledgeBitCreatorProfilePicture = ""
                                            if(parentKnowledgeBitCreatorSnap.data()['profilePicture'] != ""){
                                                (async () => {
                                                    await getDownloadURL(ref(storage, "ProfilePictures/" + parentKnowledgeBitCreatorSnap.data()['profilePicture'])).then((url) => {
                                                        parentKnowledgeBitCreatorProfilePicture = url;
                                                    }).catch((error) => {
                                                        console.log(error);
                                                    });   
                                                })()         
                                            }

                                            let parentKnowledgeBitCreationTimestampFinalString = "";
                            
                                            let parentKnowledgeBitCreationTimestampArray = knowledgeBitDoc.data()['creationTimestamp'].toDate().toString().split(" ");
                                            let parentKnowledgeBitCreationTimestampPeriod = "pm";
                                            let parentKnowledgeBitCreationTimestampHour = parentKnowledgeBitCreationTimestampArray[4].split(":")[0];
                                            let parentKnowledgeBitCreationTimestampMinute = parentKnowledgeBitCreationTimestampArray[4].split(":")[1]
                                            if(parentKnowledgeBitCreationTimestampHour > 12){
                                                parentKnowledgeBitCreationTimestampHour = parentKnowledgeBitCreationTimestampHour - 12;
                                                if(parentKnowledgeBitCreationTimestampHour >= 12){
                                                    parentKnowledgeBitCreationTimestampPeriod = "pm";
                                                }
                                            }else{
                                                if(parentKnowledgeBitCreationTimestampHour == "00"){
                                                    parentKnowledgeBitCreationTimestampHour = "12"    
                                                }else{
                                                    parentKnowledgeBitCreationTimestampHour = parentKnowledgeBitCreationTimestampHour;
                                                }
                            
                                                parentKnowledgeBitCreationTimestampPeriod = "am";
                                            }
                                            parentKnowledgeBitCreationTimestampFinalString = parentKnowledgeBitCreationTimestampArray[1] + " " + parentKnowledgeBitCreationTimestampArray[2] + " " + parentKnowledgeBitCreationTimestampHour + ":" + parentKnowledgeBitCreationTimestampMinute + parentKnowledgeBitCreationTimestampPeriod;                                        
                                            
                                            let parentKnowledgeBitImagesList = []
                                            for(let pkbI = 0; pkbI < knowledgeBitDoc.data()['images'].length; pkbI++){
                                                (async () => {
                                                    await getDownloadURL(ref(storage, "KnowledgeBitsImages/" + knowledgeBitDoc.data()['images'][pkbI])).then((url) => {
                                                        parentKnowledgeBitImagesList.push({parentKnowledgeBitImageURL: url});
                                                    }).catch((error) => {
                                                        console.log(error);
                                                    });
                                                })()
                                            }

                                            let childKnowledgeBitsList = [];
                                            for(const ckb of knowledgeBitDoc.data()['children']){
                                                (async () => {
                                                    await getDoc(doc(this.state.db, "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID + "/KnowledgeBits/" + ckb)).then((childKnowledgeBitSnap) => {
                                                        (async () => {
                                                            await getDoc(doc(this.state.db, "Users/" + childKnowledgeBitSnap.data()['creator'])).then((childKnowledgeBitCreatorSnap) => {
                                                                let childKnowledgeBitCreatorProfilePicture = "";
                                                                if(childKnowledgeBitCreatorSnap.data()['profilePicture'] != ""){
                                                                    (async () => {
                                                                        await getDownloadURL(ref(storage, "ProfilePictures/" + childKnowledgeBitCreatorSnap.data()['profilePicture'])).then((url) => {
                                                                            childKnowledgeBitCreatorProfilePicture = url;
                                                                        }).catch((error) => {
                                                                            console.log(error);
                                                                        });  
                                                                    })()          
                                                                }
                                                                
                                                                let childKnowledgeBitCreationTimestampFinalString = "";
                                            
                                                                let childKnowledgeBitCreationTimestampArray = childKnowledgeBitSnap.data()['creationTimestamp'].toDate().toString().split(" ");
                                                                let childKnowledgeBitCreationTimestampPeriod = "pm";
                                                                let childKnowledgeBitCreationTimestampHour = childKnowledgeBitCreationTimestampArray[4].split(":")[0];
                                                                let childKnowledgeBitCreationTimestampMinute = childKnowledgeBitCreationTimestampArray[4].split(":")[1]
                                                                if(childKnowledgeBitCreationTimestampHour > 12){
                                                                    childKnowledgeBitCreationTimestampHour = childKnowledgeBitCreationTimestampHour - 12;
                                                                    if(childKnowledgeBitCreationTimestampHour >= 12){
                                                                        childKnowledgeBitCreationTimestampPeriod = "pm";
                                                                    }
                                                                }else{
                                                                    if(childKnowledgeBitCreationTimestampHour == "00"){
                                                                        childKnowledgeBitCreationTimestampHour = "12"    
                                                                    }else{
                                                                        childKnowledgeBitCreationTimestampHour = childKnowledgeBitCreationTimestampHour;
                                                                    }
                                                                
                                                                    childKnowledgeBitCreationTimestampPeriod = "am";
                                                                }
                                                                childKnowledgeBitCreationTimestampFinalString = childKnowledgeBitCreationTimestampArray[1] + " " + childKnowledgeBitCreationTimestampArray[2] + " " + childKnowledgeBitCreationTimestampHour + ":" + childKnowledgeBitCreationTimestampMinute + childKnowledgeBitCreationTimestampPeriod;
        
                                                                let childKnowledgeBitImagesList = []
                                                                for(let cpkbI = 0; cpkbI < childKnowledgeBitSnap.data()['images'].length; cpkbI++){
                                                                    (async () => {
                                                                        await getDownloadURL(ref(storage, "KnowledgeBitsImages/" + childKnowledgeBitSnap.data()['images'][cpkbI])).then((url) => {
                                                                            childKnowledgeBitImagesList.push({childKnowledgeBitImageURL: url});
                                                                        }).catch((error) => {
                                                                            console.log(error);
                                                                        });
                                                                    })()
                                                                }
                                                                childKnowledgeBitsList.push({
                                                                    id: childKnowledgeBitSnap.id,
                                                                    rootID: knowledgeBitDoc.id,
                                                                    textContent: childKnowledgeBitSnap.data()['textContent'],
                                                                    creator: childKnowledgeBitSnap.data()['creator'],
                                                                    creatorFName: childKnowledgeBitCreatorSnap.data()['fName'],
                                                                    creatorLName: childKnowledgeBitCreatorSnap.data()['lName'],
                                                                    creationTimestamp: childKnowledgeBitCreationTimestampFinalString,
                                                                    creatorProfilePictureURL: childKnowledgeBitCreatorProfilePicture,
                                                                    images: childKnowledgeBitImagesList,
                                            
                                                                });                                                    
        
                                                            }).catch((error) => {
                                                                console.log(error);
                                                            });
                                                        })()
                                                    }).catch((error) => {
                                                        console.log(error);
                                                    });
                                                })()
                                            }
                                            // for(let ckb = 0; ckb < knowledgeBitDoc.data()['children'].length; ckb++){

                                            // }
                                            
                                            postKnowledgeBitsList.push({
                                                id: knowledgeBitDoc.id,
                                                textContent: knowledgeBitDoc.data()['textContent'],
                                                creator: knowledgeBitDoc.data()['creator'],
                                                creatorFName: parentKnowledgeBitCreatorSnap.data()['fName'],
                                                creatorLName: parentKnowledgeBitCreatorSnap.data()['lName'],
                                                creationTimestamp: parentKnowledgeBitCreationTimestampFinalString,
                                                creatorProfilePictureURL: parentKnowledgeBitCreatorProfilePicture,
                                                images: parentKnowledgeBitImagesList,
                                                children: childKnowledgeBitsList,
                                            });
                                            this.setState({focusedPostKnowledgeBits: postKnowledgeBitsList});
                                        }).catch((error) => {
                                            console.log(error);
                                        });
                                    })()
                                }                                
                            }
                        }).catch((error) => {
                            console.log(error);
                        });
                    })()

                    this.setState({focusedPostTitle: postDocSnap.data()['title']});
                    this.setState({focusedPostTextContent: postDocSnap.data()['textContent']});
                    this.setState({focusedPostCreator: postDocSnap.data()['creator']});
                    this.setState({focusedPostCreatorFName: postCreatorDocSnap.data()['fName']});
                    this.setState({focusedPostCreatorLName: postCreatorDocSnap.data()['lName']});
                    this.setState({focusedPostCreationTimestamp: postCreationTimestampFinalString});
                    this.setState({focusedPostSolutions: postSolutionsList});
                    this.setState({focusedPostImages: postImagesList});
                    this.setState({focusedPostNumKnowledgeBits: postDocSnap.data()['numKnowledgeBits']});
                    this.setState({dashboardView: 'post'});                    
                }).catch((error) => {
                    console.log(error);
                });
            })()
        }).catch((error) => {
            this.setState({dashboardView: 'error'});
            console.log(error);
        });
    }

    async getPost2(event){
        this.setState({dashboardView: 'loading'});

        let postRefString = "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID;
        const postRef = doc(this.state.db, postRefString);
        const postDocSnap = await getDoc(postRef);
        let postCreatorRefString  = "Users/" + postDocSnap.data()['creator'];
        let postCreatorRef  = doc(this.state.db, postCreatorRefString);
        const postCreatorDocSnap = await getDoc(postCreatorRef);        
        let postTimestampString = "";
        let postTimestampPeriod = "pm";
        let postTimestampArray = postDocSnap.data()['creationTimestamp'].toDate().toString().split(" ")
        let postTimestampHour = postTimestampArray[4].split(":")[0]
        let postTimestampMinute = postTimestampArray[4].split(":")[1]
        let postKnowledgeBitsList = [];
        let postSolutionsList = []
        let postImagesList = []
        const storage = getStorage()

        for(let p = 0; p < postDocSnap.data()['images'].length; p++){
            console.log('doing stuff')
            const focusedPostImageRef = ref(storage, "PostsImages/" + postDocSnap.data()['images'][p]);
            await getDownloadURL(focusedPostImageRef).then((url) => {
                postImagesList.push({focusedPostImageURL: url});
            }).catch((error) => {
                console.log("error getting download url for post image")
            });
        }
        // console.log('list')
        // console.log(postImagesList)

        this.setState({focusedPostImages: postImagesList})
        console.log(this.state.focusedPostImages)

        if(postTimestampHour > 12){
            postTimestampHour = postTimestampHour - 12
            if(postTimestampHour >= 12){
                postTimestampPeriod = "pm"
            }
        }else{
            postTimestampHour = postTimestampHour;
            postTimestampPeriod = "am"
        } 
        postTimestampString = postTimestampArray[1] + " " + postTimestampArray[2] + " " + postTimestampHour + ":" + postTimestampMinute + postTimestampPeriod;

        for(let k = 0; k < postDocSnap.data()['solutions'].length; k++){
            postSolutionsList.push(postDocSnap.data()['solutions'][k]);
        }
        this.setState({focusedPostSolutions: postSolutionsList});

        // get focused post knowledge bits
        let postKnowledgeBitsRefString = "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID + "/KnowledgeBits"
        const postKnowledgeBitsQuery = query(collection(this.state.db, postKnowledgeBitsRefString));
        const postKnowledgeBitsQuerySnapshot = await getDocs(postKnowledgeBitsQuery);

        for(let i = 0; i < postKnowledgeBitsQuerySnapshot.docs.length; i++){
            let parentKnowledgeBitDoc = postKnowledgeBitsQuerySnapshot.docs[i]

            if(parentKnowledgeBitDoc.data()['hierarchy'] == 'parent'){
                let childKnowledgeBits = [];
                if(parentKnowledgeBitDoc.data()['children'].length > 0){
                    for(let j = 0; j < parentKnowledgeBitDoc.data()['children'].length; j++){

                        let childKnowledgeBitRefString = "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID + "/KnowledgeBits/" + parentKnowledgeBitDoc.data()['children'][j]
                        const childKnowledgeBitRef = doc(this.state.db, childKnowledgeBitRefString);
                        const childKnowledgeBitSnap = await getDoc(childKnowledgeBitRef);                    

                        // child timestamp stuff
                        let finalChildKBDateString = "";
                        let finalChildKBPeriod = "pm";
                        let childKBTimestampArray = childKnowledgeBitSnap.data()['creationTimestamp'].toDate().toString().split(" ")
                        let childKBTimestampHour = childKBTimestampArray[4].split(":")[0]
                        if(childKBTimestampHour > 12){
                            childKBTimestampHour = childKBTimestampHour - 12
                            if(childKBTimestampHour >= 12){
                                finalChildKBPeriod = "pm"
                            }
                        }else{
                            childKBTimestampHour = childKBTimestampHour;
                            finalChildKBPeriod = "am"
                        }

                        let childKBTimestampMinute = childKBTimestampArray[4].split(":")[1]
                        finalChildKBDateString = childKBTimestampArray[1] + " " + childKBTimestampArray[2] + " " + childKBTimestampHour + ":" + childKBTimestampMinute + finalChildKBPeriod

                        childKnowledgeBits.push(
                            {
                                id: childKnowledgeBitSnap.id,
                                rootID: parentKnowledgeBitDoc.id,
                                textContent: childKnowledgeBitSnap.data()['textContent'],
                                creatorName: childKnowledgeBitSnap.data()['creatorFullName'],
                                creationTimestamp: finalChildKBDateString,                           
                            }
                        )                        
                        // console.log(childKnowledgeBitSnap.data(['textContent']));
                    }
                }
                // timestamp stuff
                let finalParentKBDateString = "";
                let finalParentKBPeriod = "pm";
                let parentKBTimestampArray = parentKnowledgeBitDoc.data()['creationTimestamp'].toDate().toString().split(" ")
                let parentKBTimestampHour = parentKBTimestampArray[4].split(":")[0]
                if(parentKBTimestampHour > 12){
                    parentKBTimestampHour = parentKBTimestampHour - 12
                    if(parentKBTimestampHour >= 12){
                        finalParentKBPeriod = "pm"
                    }
                }else{
                    parentKBTimestampHour = parentKBTimestampHour;
                    finalParentKBPeriod = "am"
                }

                let parentKBTimestampMinute = parentKBTimestampArray[4].split(":")[1]
                finalParentKBDateString = parentKBTimestampArray[1] + " " + parentKBTimestampArray[2] + " " + parentKBTimestampHour + ":" + parentKBTimestampMinute + finalParentKBPeriod                

                postKnowledgeBitsList.push(
                    {
                        id: parentKnowledgeBitDoc.id,
                        textContent: parentKnowledgeBitDoc.data()['textContent'],
                        creator: parentKnowledgeBitDoc.data()['creator'],
                        creatorName: parentKnowledgeBitDoc.data()['creatorFullName'],
                        creationTimestamp: finalParentKBDateString,
                        children: childKnowledgeBits
                    }
                );                
            }
            // console.log(postKnowledgeBitsQuerySnapshot.docs[i].data())
        }
        this.setState({focusedPostKnowledgeBits: postKnowledgeBitsList})
        // const { forEach } = require('p-iteration');
        // const fs = require('fs-promise');
        // postKnowledgeBitsQuerySnapshot.forEach((knowledgeBit) => {
        // for(const knowledgeBit of postKnowledgeBitsQuerySnapshot){
        // for await (const contents of postKnowledgeBitsQuerySnapshot) {
        // await forEach(postKnowledgeBitsQuerySnapshot, async (file) => {
        //     const contents = await fs.readFile(file, 'utf8');
        //     console.log(contents);            
            //     const contents = await fs.readFile(file, 'utf8');
            //     console.log(contents);
            //   })
            // console.log(contents)
            // (async () => {  
            //     let parentIsSolution = 'false';
            //     if(knowledgeBit.data()['solution'] == false){
            //         parentIsSolution = 'false'
            //     }else{
            //         parentIsSolution = 'true'
            //     }

            //     if(knowledgeBit.data()['heirarchy'] == 'parent'){
            //         let childKnowledgeBits = [];
            //         if(knowledgeBit.data()['children'].length > 0){
            //             knowledgeBit.data()['children'].forEach((childKnowledgeBit) => {
            //                 (async () => {  
            //                     let isSolution = "false"
            //                     const childKnowledgeBitRefString = "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID + "/KnowledgeBits/" + childKnowledgeBit;
            //                     const childKnowledgeBitRef = doc(this.state.db, childKnowledgeBitRefString)
            //                     const childKnowledgeBitSnap = await getDoc(childKnowledgeBitRef)                        
            //                     if(childKnowledgeBitSnap.data()['solution'] == true){
            //                         isSolution = "true"
            //                     }else{
            //                         isSolution = "false"
            //                     }
            //                     childKnowledgeBits.push(
            //                         {
            //                             id: childKnowledgeBitSnap.id,
            //                             textContent: childKnowledgeBitSnap.data()['textContent'],
            //                             creatorName: knowledgeBit.data()['creatorFullName'],
            //                             solution: isSolution,
            //                             creationTimestamp: "",                           
            //                         }
            //                     )
            //                 })()
            //             });

            //         }
            //         postKnowledgeBitsList.push(
            //             {
            //                 id: knowledgeBit.id,
            //                 textContent: knowledgeBit.data()['textContent'],
            //                 creator: knowledgeBit.data()['creator'],
            //                 creatorName: knowledgeBit.data()['creatorFullName'],
            //                 solution: parentIsSolution,
            //                 creationTimestamp: '',
            //                 children: childKnowledgeBits
            //             }
            //         );
            //     }
            // })()  
        // })

        this.setState({focusedPostTitle: postDocSnap.data()['title']})
        this.setState({focusedPostTextContent: postDocSnap.data()['textContent']})
        this.setState({focusedPostCreator: postDocSnap.data()['creator']})
        this.setState({focusedPostCreatorFName: postCreatorDocSnap.data()['fName']});
        this.setState({focusedPostCreatorLName: postCreatorDocSnap.data()['lName']});
        this.setState({focusedPostNumKnowledgeBits: postDocSnap.data()['numKnowledgeBits']})        
        this.setState({focusedPostCreationTimestamp: postTimestampString});
        this.setState({focusedPostKnowledgeBits: postKnowledgeBitsList});
        this.setState({dashboardView: 'post'})

    }
    async getPost(event){
        this.setState({dashboardView: 'loading'});
        let postRefString = "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID;
        const postRef = doc(this.state.db, postRefString);
        const postDocSnap = await getDoc(postRef);

        let postCreatorRefString  = "Users/" + postDocSnap.data()['creator'];
        let postCreatorRef  = doc(this.state.db, postCreatorRefString);
        const postCreatorDocSnap = await getDoc(postCreatorRef);

        if(postDocSnap.data()['solved'] == false){
            this.setState({focusedPostSolved: 'false'})
        }else{
            this.setState({focusedPostSolved: 'true'})
        }

        let finalPostDateString = "";
        let finalPostPeriod = "pm";
        let postTimestampArray = postDocSnap.data()['creationTimestamp'].toDate().toString().split(" ")
        let postTimestampHour = postTimestampArray[4].split(":")[0]
        if(postTimestampHour > 12){
            postTimestampHour = postTimestampHour - 12
            if(postTimestampHour >= 12){
                finalPostPeriod = "pm"
            }
        }else{
            postTimestampHour = postTimestampHour;
            finalPostPeriod = "am"
        } 
        let postTimestampMinute = postTimestampArray[4].split(":")[1]
        finalPostDateString = postTimestampArray[1] + " " + postTimestampArray[2] + " " + postTimestampHour + ":" + postTimestampMinute + finalPostPeriod

        this.setState({focusedPostTitle: postDocSnap.data()['title']})
        this.setState({focusedPostTextContent: postDocSnap.data()['textContent']})
        this.setState({focusedPostCreator: postDocSnap.data()['creator']})
        this.setState({focusedPostCreatorFName: postCreatorDocSnap.data()['fName']});
        this.setState({focusedPostCreatorLName: postCreatorDocSnap.data()['lName']});
        this.setState({focusedPostNumKnowledgeBits: postDocSnap.data()['numKnowledgeBits']})        
        this.setState({focusedPostCreationTimestamp: finalPostDateString})
        // figure out timestamp thing

        // get post knowledge bits
        let postKnowledgeBitsRefString = "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID + "/KnowledgeBits"
        const postKnowledgeBitsQuery = query(collection(this.state.db, postKnowledgeBitsRefString));
        const postKnowledgeBitsQuerySnapshot = await getDocs(postKnowledgeBitsQuery);
        let postKnowledgeBitsList = []

        postKnowledgeBitsQuerySnapshot.forEach((knowledgeBit) => {
    
            let finalKnowledgeBitDateString = "";
            let finalKnowledgeBitPeriod = "pm";
            let knowledgeBitTimestampArray = knowledgeBit.data()['creationTimestamp'].toDate().toString().split(" ")
            let knowledgeBitTimestampHour = knowledgeBitTimestampArray[4].split(":")[0]
            if(knowledgeBitTimestampHour > 12){
                knowledgeBitTimestampHour = knowledgeBitTimestampHour - 12
                if(knowledgeBitTimestampHour >= 12){
                    finalKnowledgeBitPeriod = "pm"
                }
            }else{
                knowledgeBitTimestampHour = knowledgeBitTimestampHour;
                finalKnowledgeBitPeriod = "am"
            }

            let knowledgeBitTimestampMinute = knowledgeBitTimestampArray[4].split(":")[1]
            finalKnowledgeBitDateString = knowledgeBitTimestampArray[1] + " " + knowledgeBitTimestampArray[2] + " " + knowledgeBitTimestampHour + ":" + knowledgeBitTimestampMinute + finalKnowledgeBitPeriod

            postKnowledgeBitsList.push(
                {
                    id: knowledgeBit.id,
                    textContent: knowledgeBit.data()['textContent'],
                    creator: knowledgeBit.data()['creator'],
                    creatorName: knowledgeBit.data()['creatorFullName'],
                    creationTimestamp: finalKnowledgeBitDateString,
                    children: {}
                }
            )
        });
        this.setState({focusedPostKnowledgeBits: postKnowledgeBitsList});        
        // set view to post view with title and stuff and knowledhge bits list
        this.setState({dashboardView: 'post'})
    }
    async getInbox(filter){
        this.setState({dashboardView: "loading"});
        this.setState({activeTab: "inbox"});   
        this.setState({focusedClass: ""});
        this.setState({currentUserInbox: []});
        const currentUserInboxRefString = "Users/" + this.state.currentUserEmail + "/Inbox";

        if(filter == 'new'){
            const currentUserInboxQuery = query(collection(this.state.db, currentUserInboxRefString), orderBy('addedTimestamp', 'desc'), where('new', '==', true));
            await getDocs(currentUserInboxQuery).then((currentUserInboxSnap) => {
                let currentUserInboxList = [];
                for(const inboxItem of currentUserInboxSnap.docs){
                    if(inboxItem.data()['type'] == 'newPost'){
                        let postCreationTimestampFinalString = "";
                        let postCreationTimestampArray = inboxItem.data()['addedTimestamp'].toDate().toString().split(" ");
                        let postCreationTimestampPeriod = "pm";
                        let postCreationTimestampHour = postCreationTimestampArray[4].split(":")[0];
                        let postCreationTimestampMinute = postCreationTimestampArray[4].split(":")[1]
                        if(postCreationTimestampHour > 12){
                            postCreationTimestampHour = postCreationTimestampHour - 12;
                            if(postCreationTimestampHour >= 12){
                                postCreationTimestampPeriod = "pm";
                            }
                        }else{
                            if(postCreationTimestampHour == "00"){
                                postCreationTimestampHour = "12"    
                            }else{
                                postCreationTimestampHour = postCreationTimestampHour;
                            }
                            postCreationTimestampPeriod = "am";
                        }
                        postCreationTimestampFinalString = postCreationTimestampArray[1] + " " + postCreationTimestampArray[2] + " " + postCreationTimestampHour + ":" + postCreationTimestampMinute + postCreationTimestampPeriod;                
        
                        currentUserInboxList.push({
                            type: 'newPost',
                            postCreationTimestampFormatted: postCreationTimestampFinalString,
                            postTitle: inboxItem.data()['postTitle'],
                            postCreator: inboxItem.data()['postCreator'],
                            postCreatorFullName: inboxItem.data()['postCreatorFullName'],
                            postID: inboxItem.data()['postID'],
                            postTargetClassID: inboxItem.data()['postTargetClass'],
                            postTargetClassName: inboxItem.data()['postTargetClassName'],
                        });
                    }else if(inboxItem.data()['type'] == 'newClassMember'){
                        let newMemberJoinedTimestampFinalString = "";
                        let newMemberJoinedTimestampArray = inboxItem.data()['addedTimestamp'].toDate().toString().split(" ");
                        let newMemberJoinedTimestampPeriod = "pm";
                        let newMemberJoinedTimestampHour = newMemberJoinedTimestampArray[4].split(":")[0];
                        let newMemberJoinedTimestampMinute = newMemberJoinedTimestampArray[4].split(":")[1]
                        if(newMemberJoinedTimestampHour > 12){
                            newMemberJoinedTimestampHour = newMemberJoinedTimestampHour - 12;
                            if(newMemberJoinedTimestampHour >= 12){
                                newMemberJoinedTimestampPeriod = "pm";
                            }
                        }else{
                            if(newMemberJoinedTimestampHour == "00"){
                                newMemberJoinedTimestampHour = "12"    
                            }else{
                                newMemberJoinedTimestampHour = newMemberJoinedTimestampHour;
                            }
                            newMemberJoinedTimestampPeriod = "am";
                        }
                        newMemberJoinedTimestampFinalString = newMemberJoinedTimestampArray[1] + " " + newMemberJoinedTimestampArray[2] + " " + newMemberJoinedTimestampHour + ":" + newMemberJoinedTimestampMinute + newMemberJoinedTimestampPeriod;                
        
                        currentUserInboxList.push({
                            type: 'newClassMember',
                            newMemberFullName: inboxItem.data()['newMemberFullName'],
                            newMemberJoinedFormattedTimestamp: newMemberJoinedTimestampFinalString,
                            newMemberClassName: inboxItem.data()['newMemberClassName'],
                        });
                    }else if(inboxItem.data()['type'] == 'newParentKnowledgeBit'){  
                        let newKnowledgeBitCreationTimestampFinalString = "";
                        let newKnowledgeBitCreationTimestampArray = inboxItem.data()['addedTimestamp'].toDate().toString().split(" ");
                        let newKnowledgeBitCreationTimestampPeriod = "pm";
                        let newKnowledgeBitCreationTimestampHour = newKnowledgeBitCreationTimestampArray[4].split(":")[0];
                        let newKnowledgeBitCreationTimestampMinute = newKnowledgeBitCreationTimestampArray[4].split(":")[1]
                        if(newKnowledgeBitCreationTimestampHour > 12){
                            newKnowledgeBitCreationTimestampHour = newKnowledgeBitCreationTimestampHour - 12;
                            if(newKnowledgeBitCreationTimestampHour >= 12){
                                newKnowledgeBitCreationTimestampPeriod = "pm";
                            }
                        }else{
                            if(newKnowledgeBitCreationTimestampHour == "00"){
                                newKnowledgeBitCreationTimestampHour = "12"    
                            }else{
                                newKnowledgeBitCreationTimestampHour = newKnowledgeBitCreationTimestampHour;
                            }
                            newKnowledgeBitCreationTimestampPeriod = "am";
                        }
                        newKnowledgeBitCreationTimestampFinalString = newKnowledgeBitCreationTimestampArray[1] + " " + newKnowledgeBitCreationTimestampArray[2] + " " + newKnowledgeBitCreationTimestampHour + ":" + newKnowledgeBitCreationTimestampMinute + newKnowledgeBitCreationTimestampPeriod;                
        
                        currentUserInboxList.push({
                            type: 'newParentKnowledgeBit',
                            newKnowledgeBitClass: inboxItem.data()['knowledgeBitClass'],
                            newKnowledgeBitCreatorFullName: inboxItem.data()['knowledgeBitCreatorFullName'],
                            newKnowledgeBitFocusedPostTitle: inboxItem.data()['focusedPostTitle'],
                            newKnowledgeBitTextContent: inboxItem.data()['knowledgeBitTextContent'],
                            newKnowledgeBitCreationFormattedTimestamp: newKnowledgeBitCreationTimestampFinalString,
                        });                
                    }else if(inboxItem.data()['type'] == 'newReplyKnowledgeBit'){
                        let newReplyKnowledgeBitCreationTimestampFinalString = "";
                        let newReplyKnowledgeBitCreationTimestampArray = inboxItem.data()['addedTimestamp'].toDate().toString().split(" ");
                        let newReplyKnowledgeBitCreationTimestampPeriod = "pm";
                        let newReplyKnowledgeBitCreationTimestampHour = newReplyKnowledgeBitCreationTimestampArray[4].split(":")[0];
                        let newReplyKnowledgeBitCreationTimestampMinute = newReplyKnowledgeBitCreationTimestampArray[4].split(":")[1]
                        if(newReplyKnowledgeBitCreationTimestampHour > 12){
                            newReplyKnowledgeBitCreationTimestampHour = newReplyKnowledgeBitCreationTimestampHour - 12;
                            if(newReplyKnowledgeBitCreationTimestampHour >= 12){
                                newReplyKnowledgeBitCreationTimestampPeriod = "pm";
                            }
                        }else{
                            if(newReplyKnowledgeBitCreationTimestampHour == "00"){
                                newReplyKnowledgeBitCreationTimestampHour = "12"    
                            }else{
                                newReplyKnowledgeBitCreationTimestampHour = newReplyKnowledgeBitCreationTimestampHour;
                            }
                            newReplyKnowledgeBitCreationTimestampPeriod = "am";
                        }
                        newReplyKnowledgeBitCreationTimestampFinalString = newReplyKnowledgeBitCreationTimestampArray[1] + " " + newReplyKnowledgeBitCreationTimestampArray[2] + " " + newReplyKnowledgeBitCreationTimestampHour + ":" + newReplyKnowledgeBitCreationTimestampMinute + newReplyKnowledgeBitCreationTimestampPeriod;                
        
                        currentUserInboxList.push({
                            type: 'newKnowledgeBitReply',
                            newReplyKnowledgeBitClass: inboxItem.data()['knowledgeBitClass'],
                            newReplyKnowledgeBitCreatorFullName: inboxItem.data()['knowledgeBitCreatorFullName'],
                            newReplyKnowledgeBitTextContent: inboxItem.data()['knowledgeBitTextContent'],
                            newReplyKnowledgeBitParentKnowledgeBitTextContent: inboxItem.data()['parentKnowledgeBitTextContent'],
                            newReplyKnowledgeBitCreationTimestampFinalString: newReplyKnowledgeBitCreationTimestampFinalString,
                        });                 
                    }else if(inboxItem.data()['type'] == 'newMarkedSolution'){
                        let newMarkedSolutionTimestampFinalString = "";
                        let newMarkedSolutionTimestampArray = inboxItem.data()['addedTimestamp'].toDate().toString().split(" ");
                        let newMarkedSolutionTimestampPeriod = "pm";
                        let newMarkedSolutionTimestampHour = newMarkedSolutionTimestampArray[4].split(":")[0];
                        let newMarkedSolutionTimestampMinute = newMarkedSolutionTimestampArray[4].split(":")[1]
                        if(newMarkedSolutionTimestampHour > 12){
                            newMarkedSolutionTimestampHour = newMarkedSolutionTimestampHour - 12;
                            if(newMarkedSolutionTimestampHour >= 12){
                                newMarkedSolutionTimestampPeriod = "pm";
                            }
                        }else{
                            if(newMarkedSolutionTimestampHour == "00"){
                                newMarkedSolutionTimestampHour = "12"    
                            }else{
                                newMarkedSolutionTimestampHour = newMarkedSolutionTimestampHour;
                            }
                            newMarkedSolutionTimestampPeriod = "am";
                        }
                        newMarkedSolutionTimestampFinalString = newMarkedSolutionTimestampArray[1] + " " + newMarkedSolutionTimestampArray[2] + " " + newMarkedSolutionTimestampHour + ":" + newMarkedSolutionTimestampMinute + newMarkedSolutionTimestampPeriod;                
        
                        currentUserInboxList.push({
                            type: 'newMarkedSolution',
                            postClassName: inboxItem.data()['postClassName'],                            
                            postCreatorName: inboxItem.data()['postCreatorName'],
                            solutionKnowledgeBitTextContent: inboxItem.data()['solutionKnowledgeBitTextContent'],
                            postTitle: inboxItem.data()['postTitle'],
                            newMarkedSolutionTimestampFinalString: newMarkedSolutionTimestampFinalString,
                        });  
                    }                
                    this.setState({currentUserInbox: currentUserInboxList});                      
                }
                this.setState({dashboardView: 'inbox'})             
            }).catch((error) => {
                this.setState({dashboardView: 'error'});
            });             
        }else if(filter == 'all'){
            const currentUserInboxQuery = query(collection(this.state.db, currentUserInboxRefString), orderBy('addedTimestamp', 'desc'));
            await getDocs(currentUserInboxQuery).then((currentUserInboxSnap) => {
                let currentUserInboxList = [];
                for(const inboxItem of currentUserInboxSnap.docs){
                    if(inboxItem.data()['type'] == 'newPost'){
                        let postCreationTimestampFinalString = "";
                        let postCreationTimestampArray = inboxItem.data()['addedTimestamp'].toDate().toString().split(" ");
                        let postCreationTimestampPeriod = "pm";
                        let postCreationTimestampHour = postCreationTimestampArray[4].split(":")[0];
                        let postCreationTimestampMinute = postCreationTimestampArray[4].split(":")[1]
                        if(postCreationTimestampHour > 12){
                            postCreationTimestampHour = postCreationTimestampHour - 12;
                            if(postCreationTimestampHour >= 12){
                                postCreationTimestampPeriod = "pm";
                            }
                        }else{
                            if(postCreationTimestampHour == "00"){
                                postCreationTimestampHour = "12"    
                            }else{
                                postCreationTimestampHour = postCreationTimestampHour;
                            }
                            postCreationTimestampPeriod = "am";
                        }
                        postCreationTimestampFinalString = postCreationTimestampArray[1] + " " + postCreationTimestampArray[2] + " " + postCreationTimestampHour + ":" + postCreationTimestampMinute + postCreationTimestampPeriod;                
        
                        currentUserInboxList.push({
                            type: 'newPost',
                            postCreationTimestampFormatted: postCreationTimestampFinalString,
                            postTitle: inboxItem.data()['postTitle'],
                            postCreator: inboxItem.data()['postCreator'],
                            postCreatorFullName: inboxItem.data()['postCreatorFullName'],
                            postID: inboxItem.data()['postID'],
                            postTargetClassID: inboxItem.data()['postTargetClass'],
                            postTargetClassName: inboxItem.data()['postTargetClassName'],
                        });
                    }else if(inboxItem.data()['type'] == 'newClassMember'){
                        let newMemberJoinedTimestampFinalString = "";
                        let newMemberJoinedTimestampArray = inboxItem.data()['addedTimestamp'].toDate().toString().split(" ");
                        let newMemberJoinedTimestampPeriod = "pm";
                        let newMemberJoinedTimestampHour = newMemberJoinedTimestampArray[4].split(":")[0];
                        let newMemberJoinedTimestampMinute = newMemberJoinedTimestampArray[4].split(":")[1]
                        if(newMemberJoinedTimestampHour > 12){
                            newMemberJoinedTimestampHour = newMemberJoinedTimestampHour - 12;
                            if(newMemberJoinedTimestampHour >= 12){
                                newMemberJoinedTimestampPeriod = "pm";
                            }
                        }else{
                            if(newMemberJoinedTimestampHour == "00"){
                                newMemberJoinedTimestampHour = "12"    
                            }else{
                                newMemberJoinedTimestampHour = newMemberJoinedTimestampHour;
                            }
                            newMemberJoinedTimestampPeriod = "am";
                        }
                        newMemberJoinedTimestampFinalString = newMemberJoinedTimestampArray[1] + " " + newMemberJoinedTimestampArray[2] + " " + newMemberJoinedTimestampHour + ":" + newMemberJoinedTimestampMinute + newMemberJoinedTimestampPeriod;                
        
                        currentUserInboxList.push({
                            type: 'newClassMember',
                            newMemberFullName: inboxItem.data()['newMemberFullName'],
                            newMemberJoinedFormattedTimestamp: newMemberJoinedTimestampFinalString,
                            newMemberClassName: inboxItem.data()['newMemberClassName'],
                        });
                    }else if(inboxItem.data()['type'] == 'newParentKnowledgeBit'){  
                        let newKnowledgeBitCreationTimestampFinalString = "";
                        let newKnowledgeBitCreationTimestampArray = inboxItem.data()['addedTimestamp'].toDate().toString().split(" ");
                        let newKnowledgeBitCreationTimestampPeriod = "pm";
                        let newKnowledgeBitCreationTimestampHour = newKnowledgeBitCreationTimestampArray[4].split(":")[0];
                        let newKnowledgeBitCreationTimestampMinute = newKnowledgeBitCreationTimestampArray[4].split(":")[1]
                        if(newKnowledgeBitCreationTimestampHour > 12){
                            newKnowledgeBitCreationTimestampHour = newKnowledgeBitCreationTimestampHour - 12;
                            if(newKnowledgeBitCreationTimestampHour >= 12){
                                newKnowledgeBitCreationTimestampPeriod = "pm";
                            }
                        }else{
                            if(newKnowledgeBitCreationTimestampHour == "00"){
                                newKnowledgeBitCreationTimestampHour = "12"    
                            }else{
                                newKnowledgeBitCreationTimestampHour = newKnowledgeBitCreationTimestampHour;
                            }
                            newKnowledgeBitCreationTimestampPeriod = "am";
                        }
                        newKnowledgeBitCreationTimestampFinalString = newKnowledgeBitCreationTimestampArray[1] + " " + newKnowledgeBitCreationTimestampArray[2] + " " + newKnowledgeBitCreationTimestampHour + ":" + newKnowledgeBitCreationTimestampMinute + newKnowledgeBitCreationTimestampPeriod;                
        
                        currentUserInboxList.push({
                            type: 'newParentKnowledgeBit',
                            newKnowledgeBitClass: inboxItem.data()['knowledgeBitClass'],
                            newKnowledgeBitCreatorFullName: inboxItem.data()['knowledgeBitCreatorFullName'],
                            newKnowledgeBitFocusedPostTitle: inboxItem.data()['focusedPostTitle'],
                            newKnowledgeBitTextContent: inboxItem.data()['knowledgeBitTextContent'],
                            newKnowledgeBitCreationFormattedTimestamp: newKnowledgeBitCreationTimestampFinalString,
                        });                
                    }else if(inboxItem.data()['type'] == 'newReplyKnowledgeBit'){
                        let newReplyKnowledgeBitCreationTimestampFinalString = "";
                        let newReplyKnowledgeBitCreationTimestampArray = inboxItem.data()['addedTimestamp'].toDate().toString().split(" ");
                        let newReplyKnowledgeBitCreationTimestampPeriod = "pm";
                        let newReplyKnowledgeBitCreationTimestampHour = newReplyKnowledgeBitCreationTimestampArray[4].split(":")[0];
                        let newReplyKnowledgeBitCreationTimestampMinute = newReplyKnowledgeBitCreationTimestampArray[4].split(":")[1]
                        if(newReplyKnowledgeBitCreationTimestampHour > 12){
                            newReplyKnowledgeBitCreationTimestampHour = newReplyKnowledgeBitCreationTimestampHour - 12;
                            if(newReplyKnowledgeBitCreationTimestampHour >= 12){
                                newReplyKnowledgeBitCreationTimestampPeriod = "pm";
                            }
                        }else{
                            if(newReplyKnowledgeBitCreationTimestampHour == "00"){
                                newReplyKnowledgeBitCreationTimestampHour = "12"    
                            }else{
                                newReplyKnowledgeBitCreationTimestampHour = newReplyKnowledgeBitCreationTimestampHour;
                            }
                            newReplyKnowledgeBitCreationTimestampPeriod = "am";
                        }
                        newReplyKnowledgeBitCreationTimestampFinalString = newReplyKnowledgeBitCreationTimestampArray[1] + " " + newReplyKnowledgeBitCreationTimestampArray[2] + " " + newReplyKnowledgeBitCreationTimestampHour + ":" + newReplyKnowledgeBitCreationTimestampMinute + newReplyKnowledgeBitCreationTimestampPeriod;                
        
                        currentUserInboxList.push({
                            type: 'newKnowledgeBitReply',
                            newReplyKnowledgeBitClass: inboxItem.data()['knowledgeBitClass'],
                            newReplyKnowledgeBitCreatorFullName: inboxItem.data()['knowledgeBitCreatorFullName'],
                            newReplyKnowledgeBitTextContent: inboxItem.data()['knowledgeBitTextContent'],
                            newReplyKnowledgeBitParentKnowledgeBitTextContent: inboxItem.data()['parentKnowledgeBitTextContent'],
                            newReplyKnowledgeBitCreationTimestampFinalString: newReplyKnowledgeBitCreationTimestampFinalString,
                        });                 
                    }else if(inboxItem.data()['type'] == 'newMarkedSolution'){
                        let newMarkedSolutionTimestampFinalString = "";
                        let newMarkedSolutionTimestampArray = inboxItem.data()['addedTimestamp'].toDate().toString().split(" ");
                        let newMarkedSolutionTimestampPeriod = "pm";
                        let newMarkedSolutionTimestampHour = newMarkedSolutionTimestampArray[4].split(":")[0];
                        let newMarkedSolutionTimestampMinute = newMarkedSolutionTimestampArray[4].split(":")[1]
                        if(newMarkedSolutionTimestampHour > 12){
                            newMarkedSolutionTimestampHour = newMarkedSolutionTimestampHour - 12;
                            if(newMarkedSolutionTimestampHour >= 12){
                                newMarkedSolutionTimestampPeriod = "pm";
                            }
                        }else{
                            if(newMarkedSolutionTimestampHour == "00"){
                                newMarkedSolutionTimestampHour = "12"    
                            }else{
                                newMarkedSolutionTimestampHour = newMarkedSolutionTimestampHour;
                            }
                            newMarkedSolutionTimestampPeriod = "am";
                        }
                        newMarkedSolutionTimestampFinalString = newMarkedSolutionTimestampArray[1] + " " + newMarkedSolutionTimestampArray[2] + " " + newMarkedSolutionTimestampHour + ":" + newMarkedSolutionTimestampMinute + newMarkedSolutionTimestampPeriod;                
        
                        currentUserInboxList.push({
                            type: 'newMarkedSolution',
                            postClassName: inboxItem.data()['postClassName'],                            
                            postCreatorName: inboxItem.data()['postCreatorName'],
                            solutionKnowledgeBitTextContent: inboxItem.data()['solutionKnowledgeBitTextContent'],
                            postTitle: inboxItem.data()['postTitle'],
                            newMarkedSolutionTimestampFinalString: newMarkedSolutionTimestampFinalString,
                        });  
                    }                
                    this.setState({currentUserInbox: currentUserInboxList});                      
                }
                this.setState({dashboardView: 'inbox'})              
            }).catch((error) => {
                this.setState({dashboardView: 'error'});        
            });             
        }

        const currentUserInboxQuery = query(collection(this.state.db, currentUserInboxRefString), orderBy('addedTimestamp', 'desc'), where('new', '==', true));
        await getDocs(currentUserInboxQuery).then((currentUserInboxSnap) => {
            currentUserInboxSnap.docs.forEach((newInboxItem) => {
                (async () => {
                    await updateDoc(doc(this.state.db, "Users/" + this.state.currentUserEmail + "/Inbox/" + newInboxItem.id), {
                        new: false
                    }).catch((error) => {
                        console.log(error);
                    });
                })()
            });
        }).then(() => {
            this.setState({currentUserNumNewInboxItems: 0});
        }).catch((error) => {});
    }

    async uploadNewPost(){
        
        let newPostImageRefsList = [];
        let newPostCodeSnippetsList = [];
        let newPostEquationsList = [];

        for(const image of this.state.newPostImages){
            let newPostImageID = image.imageFileName
            let newPostImageFile = image.imageFileToPush
            let imageModTimestamp = image.imageFileTimestamp
            const storage = getStorage()
            let imagePath = "PostsImages/" + imageModTimestamp + "-" + newPostImageID;
            let imagePathForFirestore = imageModTimestamp + "-" + newPostImageID;
            newPostImageRefsList.push(imagePathForFirestore)
            const newPostImageStorageRef = ref(storage, imagePath)
            uploadBytes(newPostImageStorageRef, newPostImageFile);       
        }
        
        for(const codeSnippet of this.state.newPostCodeSnippetsList){
            newPostCodeSnippetsList.push(codeSnippet.codeSnippetValue);
        };

        for(const equation of this.state.newPostEquationsList){
            newPostEquationsList.push(equation.equationValue);
        }

        this.setState({newPostEquationsList: newPostEquationsList}, () => {
            console.log('this bottom')
            console.log(this.state.newPostEquationsList);
            
        });

        await addDoc(collection(this.state.db, "Classes/" + this.state.newPostTargetClass + "/Feed"), {
            title: this.state.newPostTitle,
            textContent: this.state.newPostTextContent,
            creator: this.state.auth.currentUser['email'],
            creatorFullName: this.state.currentUserFirstName + " " + this.state.currentUserLastName,            
            creationTimestamp: serverTimestamp(),
            numKnowledgeBits: 0,
            solutions: [],
            images: newPostImageRefsList,
            codeSnippets: newPostCodeSnippetsList,
            equations: newPostEquationsList,
            hidden: false,
        }).then((docRef) => {
            (async () => {  
                await updateDoc(doc(this.state.db, "Classes", this.state.newPostTargetClass), {
                    numPosts: increment(1),
                    lastPostedTo: serverTimestamp()
                }).then(() => {
                    (async () => {  
                        let postRefString = this.state.newPostTargetClass + "*" + docRef.id;
                        await updateDoc(doc(this.state.db, "Users", this.state.auth.currentUser['email']), {
                            posts: arrayUnion(postRefString)
                        }).then(() => {
                            this.sendNewPostInbox(docRef.id, this.state.newPostTitle);
                            this.setState({newModalOpen: false});
                            this.setState({newModalView: 'main'});
                            this.setState({newPostTextContent: ""});
                            this.setState({newPostTitle: ""});
                            this.setState({newPostError: ""});
                            this.setState({newPostImages: []});
                            this.setState({activeTab: 'class'});
                            this.setState({newPostEquationsList: []});
                            this.setState({newPostCodeSnippetsList: []});
                            this.setState({focusedClass: this.state.newPostTargetClass}, this.getClass2);
                            this.showSnackbar('Successfully uploaded your post');                            
                        }).catch((error) => {
                            this.setState({newPostError: 'There was an error uploading your post'})
                            this.setState({newModalView: 'newPost'});        
                        });        
                    })()                    
                }).catch((error) => {
                    this.setState({newPostError: 'There was an error uploading your post'})
                    this.setState({newModalView: 'newPost'});        
                });        
            })()
        }).catch((error) => {
            this.setState({newPostError: 'There was an error uploading your post'})
            this.setState({newModalView: 'newPost'});        
        });
    }

    async updateFocusedPostSolutions(rootKBID, currentKBID){
        let focusedPostRefString = "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID;

        await updateDoc(doc(this.state.db, focusedPostRefString), {
            solutions: this.state.focusedPostSolutions
        }).then(() => {
            if(this.state.focusedPostSolutionsUpdateAction == 'add'){
                (async () => {
                    let focusedClassRefString = "Classes/" + this.state.focusedClass;
                    await updateDoc(doc(this.state.db, focusedClassRefString), {
                        numSolvedPosts: increment(1)
                    }).then(() => {
                        (async () => {
                            let rootKBRefString = "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID + "/KnowledgeBits/" + rootKBID;
                            await updateDoc(doc(this.state.db, rootKBRefString), {
                                lastInteracted: serverTimestamp(),
                            }).then(() => {
                                (async () => {
                                    let currentKBRefString = "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID + "/KnowledgeBits/" + currentKBID;
                                    await updateDoc(doc(this.state.db, currentKBRefString), {
                                        lastInteracted: serverTimestamp(),
                                    }).then(() => {
                                        (async () => {
                                            let solverDocRefString = "Users/" + this.state.focusedPostSolutionsUpdateSolver;
                                            await updateDoc(doc(this.state.db, solverDocRefString), {
                                                solutions: arrayUnion(this.state.focusedClass + "*" + this.state.focusedPostID) 
                                            }).then(() => {
                                                this.showSnackbar('Added as solution')
                                                if(this.state.currentUserEmail != this.state.focusedPostSolutionsUpdateSolver){
                                                    if(this.state.focusedClassStudents.includes(this.state.focusedPostSolutionsUpdateSolver)){
                                                        this.sendMarkedSolutionInbox().then(() => {}).catch((error) => {});
                                                    }
                                                }
                                            }).catch((error) => {});
                                        })().catch((error) => {});                                        
                                    }).catch((error) => {});
                                })().catch((error) => {});
                            }).catch((error) => {})  
                        })().catch((error) => {});                      
                    }).catch((error) => {});
                })().catch((error) => {});
            }else{
                (async () => {
                    let focusedClassRefString = "Classes/" + this.state.focusedClass;
                    await updateDoc(doc(this.state.db, focusedClassRefString), {
                        numSolvedPosts: increment(-1)
                    }).then(() => {
                        (async () => {
                            let solverDocRefString = "Users/" + this.state.focusedPostSolutionsUpdateSolver;
                            await updateDoc(doc(this.state.db, solverDocRefString), {
                                solutions: arrayRemove(this.state.focusedClass + "*" + this.state.focusedPostID) 
                            }).then(() => {
                                this.showSnackbar('Removed from solutions')
                            }).catch((error) => {});
                        })()
                    }).catch((error) => {});
                })().catch((error) => {});
            }
        }).catch((error) => {});          
    }

    async uploadNewKnowledgeBit(){
        this.setState({newKnowledgeBitError: ''});

        let knowledgeBitImagesRefsList = [];
        let newKnowledgeBitCodeSnippetsList = [];
        let newKnowledgeBitEquationsList = [];

        for(let i = 0; i < this.state.newKnowledgeBitImages.length; i++){
            let newKnowledgeBitImageID = this.state.newKnowledgeBitImages[i].id
            let newKnowledgeBitImageFile = this.state.newKnowledgeBitImages[i].uploadedImageFileToPush
            let imageModTimestamp = this.state.newKnowledgeBitImages[i].uploadedImageFileModTimestamp;
            const storage = getStorage()
            let imagePath = "KnowledgeBitsImages/" + imageModTimestamp + "-" + newKnowledgeBitImageID;
            let imagePathForFirestore = imageModTimestamp + "-" + newKnowledgeBitImageID;
            knowledgeBitImagesRefsList.push(imagePathForFirestore)
            const newKnowledgeBitsImageStorageRef = ref(storage, imagePath)
            uploadBytes(newKnowledgeBitsImageStorageRef, newKnowledgeBitImageFile).then((snapshot) => {}).catch((error) => {});                
        }


        for(const codeSnippet of this.state.newKnowledgeBitCodeSnippetsList){
            newKnowledgeBitCodeSnippetsList.push(codeSnippet.codeSnippetValue);
        };

        for(const equation of this.state.newKnowledgeBitEquationsList){
            newKnowledgeBitEquationsList.push(equation.equationValue);
        }
        
        this.setState({newKnowledgeBitEquationsList: newKnowledgeBitEquationsList}, () => {
            
        });

        await addDoc(collection(this.state.db, "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID + "/KnowledgeBits"), {
            textContent: this.state.newKnowledgeBitTextContent,
            creator: this.state.auth.currentUser['email'],
            creationTimestamp: serverTimestamp(),
            creatorFullName: this.state.currentUserFirstName + " " + this.state.currentUserLastName,
            hierarchy: "parent",
            children: [],
            images: knowledgeBitImagesRefsList,
            lastInteracted: serverTimestamp(),
            codeSnippets: newKnowledgeBitCodeSnippetsList,
            equations: newKnowledgeBitEquationsList,            
        }).then((docRef) => {
            (async () => {
                let kbParentPostDocRefString = "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID
                await updateDoc(doc(this.state.db, kbParentPostDocRefString), {
                    numKnowledgeBits: increment(1),
                }).then(() => {
                    this.showSnackbar("Uploaded your Knowledge Bit");
                    if(this.state.focusedClassStudents.includes(this.state.focusedPostCreator)){
                        if(this.state.focusedPostCreator != this.state.currentUserEmail){
                            (async () => {
                                this.sendNewParentKnowledgeBitInbox(this.state.newKnowledgeBitTextContent, this.state.focusedClass, this.state.focusedPostID);
                            })().catch((error) => {});
                            this.setState({newKnowledgeBitModalOpen: false});
                            this.setState({newKnowledgeBitModalView: 'main'});
                            this.setState({newKnowledgeBitImages: []});
                            this.setState({newKnowledgeBitError: ''});
                            this.setState({newKnowledgeBitCodeSnippet: '<pre class="ql-syntax" spellcheck="false">System.out.println("Add your code here");</pre>'});
                            this.setState({newKnowledgeBitCodeSnippetsList: []});
                            this.setState({newKnowledgeBitEquationsList: []});
                            this.setState({newKnowledgeBitEquationValue: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}"});
                            this.getPost3()
                        }else{
                            this.setState({newKnowledgeBitModalOpen: false});
                            this.setState({newKnowledgeBitModalView: 'main'});
                            this.setState({newKnowledgeBitImages: []});
                            this.setState({newKnowledgeBitError: ''});
                            this.setState({newKnowledgeBitCodeSnippet: '<pre class="ql-syntax" spellcheck="false">System.out.println("Add your code here");</pre>'});
                            this.setState({newKnowledgeBitCodeSnippetsList: []});
                            this.setState({newKnowledgeBitEquationsList: []});
                            this.setState({newKnowledgeBitEquationValue: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}"});
                            this.getPost3()
                        }
                    }else{
                        this.setState({newKnowledgeBitModalOpen: false});
                        this.setState({newKnowledgeBitModalView: 'main'});
                        this.setState({newKnowledgeBitImages: []});
                        this.setState({newKnowledgeBitError: ''});
                        this.setState({newKnowledgeBitCodeSnippet: '<pre class="ql-syntax" spellcheck="false">System.out.println("Add your code here");</pre>'});
                        this.setState({newKnowledgeBitCodeSnippetsList: []});
                        this.setState({newKnowledgeBitEquationsList: []});
                        this.setState({newKnowledgeBitEquationValue: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}"});                        
                        this.getPost3()
                    }
                }).catch((error) => {});
            })()
        }).catch((error) => {
            this.setState({newKnowledgeBitModalView: 'main'})
            this.setState({newKnowledgeBitError: 'There was an issue uploading your Knowledge Bit'})
        });        
    }

    async uploadNewChildKnowledgeBit(){
        this.setState({newChildKnowledgeBitError: ""});

        let knowledgeBitImagesRefsList = [];
        let newKnowledgeBitCodeSnippetsList = [];
        let newKnowledgeBitEquationsList = [];

        for(let i = 0; i < this.state.newChildKnowledgeBitImages.length; i++){
            let newChildKnowledgeBitImageID = this.state.newChildKnowledgeBitImages[i].id
            let newChildKnowledgeBitImageFile = this.state.newChildKnowledgeBitImages[i].uploadedImageFileToPush
            let imageModTimestamp = this.state.newChildKnowledgeBitImages[i].uploadedImageFileModTimestamp;
            const storage = getStorage()
            let imagePath = "KnowledgeBitsImages/" + imageModTimestamp + "-" + newChildKnowledgeBitImageID;
            let imagePathForFirestore = imageModTimestamp + "-" + newChildKnowledgeBitImageID;
            knowledgeBitImagesRefsList.push(imagePathForFirestore)
            const newChildKnowledgeBitsImageStorageRef = ref(storage, imagePath)
            uploadBytes(newChildKnowledgeBitsImageStorageRef, newChildKnowledgeBitImageFile).catch((error) => {});                
        }
        

        for(const codeSnippet of this.state.newChildKnowledgeBitCodeSnippetsList){
            newKnowledgeBitCodeSnippetsList.push(codeSnippet.codeSnippetValue);
        };

        for(const equation of this.state.newChildKnowledgeBitEquationsList){
            newKnowledgeBitEquationsList.push(equation.equationValue);
        }
        this.setState({newChildKnowledgeBitEquationsList: newKnowledgeBitEquationsList}, () => {
            
        });

        const kbCollectionRef = collection(this.state.db, "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID + "/KnowledgeBits");

        await addDoc(kbCollectionRef, {
            textContent: this.state.newChildKnowledgeBitTextContent,
            creator: this.state.auth.currentUser['email'],
            creationTimestamp: serverTimestamp(),
            hierarchy: "child",
            images: knowledgeBitImagesRefsList,
            parentID: this.state.newChildKnowledgeBitParentID,
            parentFullName: this.state.newChildKnowledgeBitParentCreatorFullName,
            rootID: this.state.newChildKnowledgeBitRootID,
            lastInteracted: serverTimestamp(),
            creatorFullName: this.state.currentUserFirstName + " " + this.state.currentUserLastName,
            codeSnippets: newKnowledgeBitCodeSnippetsList,
            equations: newKnowledgeBitEquationsList,               
        }).then((docRef) => {
            (async () => {
                let kbParentPostDocRefString = "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID
                await updateDoc(doc(this.state.db, kbParentPostDocRefString), {
                    numKnowledgeBits: increment(1),
                }).then(() => {
                    (async () => {
                        if(this.state.newChildKnowledgeBitRootID == ""){
                            let kbParentKnowledgeBitRefString = "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID + "/KnowledgeBits/" + this.state.newChildKnowledgeBitParentID
                            await updateDoc(doc(this.state.db, kbParentKnowledgeBitRefString), {
                                children: arrayUnion(docRef.id),
                                lastInteracted: serverTimestamp(),
                            }).then(() => {
                                this.showSnackbar("Uploaded your Knowledge Bit");
                                // alert(this.state.focusedClassStudents)
                                if(this.state.currentUserEmail != this.state.newChildKnowledgeBitParentCreator){
                                    if(this.state.focusedClassStudents.includes(this.state.newChildKnowledgeBitParentCreator)){
                                        (async () => {
                                            this.sendNewKnowledgeBitReplyInbox(this.state.newChildKnowledgeBitParentCreator).catch((error) => {})
                                        })().catch((error) => {});
                                    }
                                }
                                this.setState({newChildKnowledgeBitModalOpen: false})
                                this.setState({newChildKnowledgeBitModalView: "main"});
                                // this.setState({newChildKnowledgeBitTextContent: ""});
                                this.setState({newChildKnowledgeBitError: ""});
                                this.setState({newChildKnowledgeBitImages: []});
                                this.setState({newChildKnowledgeBitCodeSnippet: '<pre class="ql-syntax" spellcheck="false">System.out.println("Add your code here");</pre>'});
                                this.setState({newChildKnowledgeBitCodeSnippetsList: []});
                                this.setState({newChildKnowledgeBitEquationsList: []});
                                this.getPost3()                                
                            }).catch((error) => {});
                        }else{
                            let kbParentKnowledgeBitRefString = "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID + "/KnowledgeBits/" + this.state.newChildKnowledgeBitRootID
                            await updateDoc(doc(this.state.db, kbParentKnowledgeBitRefString), {
                                children: arrayUnion(docRef.id),
                                lastInteracted: serverTimestamp()
                            }).then(() => {
                                this.showSnackbar("Uploaded your Knowledge Bit");
                                // alert(this.state.focusedClassStudents)
                                if(this.state.currentUserEmail != this.state.newChildKnowledgeBitParentCreator){
                                    if(this.state.focusedClassStudents.includes(this.state.newChildKnowledgeBitParentCreator)){
                                        (async () => {
                                            this.sendNewKnowledgeBitReplyInbox(this.state.newChildKnowledgeBitParentCreator).catch((error) => {})
                                        })().catch((error) => {});
                                    }
                                }
                                this.setState({newChildKnowledgeBitModalOpen: false})
                                this.setState({newChildKnowledgeBitModalView: "main"});
                                // this.setState({newChildKnowledgeBitTextContent: ""});
                                this.setState({newChildKnowledgeBitError: ""});
                                this.setState({newChildKnowledgeBitImages: []});
                                this.setState({newChildKnowledgeBitCodeSnippet: '<pre class="ql-syntax" spellcheck="false">System.out.println("Add your code here");</pre>'});
                                this.setState({newChildKnowledgeBitCodeSnippetsList: []});
                                this.setState({newChildKnowledgeBitEquationsList: []});
                                this.getPost3()                                
                            }).catch((error) => {});
                        }
                    })()
                }).catch((error) => {});
            })().catch((error) => {});
        }).catch((error) => {
            this.setState({newChildKnowledgeBitError: 'There was an issue uploading your Knowledge Bit'});
            this.setState({newChildKnowledgeBitModalView: 'main'});
        }); 
    }

    async createNewClass(){
        this.setState({newClassError: ""})
        this.setState({newClassName: this.state.newClassName.trim()})

        
        await addDoc(collection(this.state.db, "Classes"), {
            name: this.state.newClassName,
            instructor: this.state.auth.currentUser['email'],
            numPeers: 1,
            numPosts: 0,
            numSolvedPosts: 0,
            peers: [this.state.auth.currentUser['email']],
            banner: this.state.newClassBannerID,
            lastPostedTo: serverTimestamp(),
            open: true,
            postingEnabled: true,
            joinLink: "",
        }).then((docRef) => {
            let classID = docRef.id;
            let link = `https://peertopeermobile.page.link/?${"classID"}=${classID}`
            const body = {
                "dynamicLinkInfo": {
                    "domainUriPrefix": 'https://peertopeermobile.page.link',
                    "link": link,
                    "iosInfo": {
                        "iosBundleId": "org.reactjs.native.example.peertopeermobile"
                    }
                }
            }    
            fetch("https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyA2BcvhjEwb4K3J6JV303osDT0ho5SJEto", {
                method: 'POST',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(body)
            }).then((res) => res.json()).then((responseData) => {
                let generatedLink = responseData.shortLink;
                (async () => {
                    await updateDoc(doc(this.state.db, "Users", this.state.auth.currentUser['email']), {
                        classes: arrayUnion(docRef.id)
                    }).then(() => {
                        (async () => {
                            await updateDoc(doc(this.state.db, "Classes", docRef.id), {
                                joinLink: generatedLink
                            }).then(() => {
                                (async () => {
                                    const userDocRef = doc(this.state.db, "Users", this.state.auth.currentUser['email'])
                                    const userDocRefSnap = await getDoc(userDocRef);
                                    let currentUserClassesList = []
                    
                                    for(let i = 0; i < userDocRefSnap.data()['classes'].length; i++){
                                        const classDocRef = doc(this.state.db, "Classes", userDocRefSnap.data()['classes'][i]);
                                        const classDocSnap = await getDoc(classDocRef);  
                    
                                        let bannerToPush = banner4;
                                        if(classDocSnap.data()['banner'] == 1){
                                            bannerToPush = banner1
                                        }else if(classDocSnap.data()['banner'] == 2){
                                            bannerToPush = banner2
                                        }else if(classDocSnap.data()['banner'] == 3){
                                            bannerToPush = banner3
                                        }else if(classDocSnap.data()['banner'] == 4){
                                            bannerToPush = banner4
                                        }                         
                    
                                        currentUserClassesList.push(
                                            {
                                                classID: userDocRefSnap.data()["classes"][i], 
                                                classJoinLink: classDocSnap.data()['joinLink'], 
                                                name: classDocSnap.data()["name"],
                                                bannerTing: bannerToPush
                                            }
                                        );
                                    }    
                    
                                    this.setState({currentUserClasses: currentUserClassesList});
                                    this.setState({newClassID: docRef.id});
                                    this.setState({newClassJoinLink: generatedLink});
                                    this.setState({newModalView: "newClassID"});
                                    this.showSnackbar("Created your class!")
                                })()
                            }).catch((error) => {
                                alert(error);
                            })
                        })()
                    }).catch((error) => {
                        this.setState({newModalView: 'newClass'});
                        this.setState({newClassError: "There was an issue creating this class"});
                    }); 
                })()                
            
            })

        }).catch((error) => {
            this.setState({newModalView: 'newClass'});
            this.setState({newClassError: "There was an issue creating this class"});
        });    
    }

    async addNewClass(){
        this.setState({newModalView: 'loading'});
        this.setState({addClassError: ""});
        this.setState({addClassID: this.state.addClassID.trim()});

        await getDoc(doc(this.state.db, "Classes", this.state.addClassID)).then((toAddClassDocSnap) => {
            if(toAddClassDocSnap.exists){
                if(toAddClassDocSnap.data()['open'] == true){
                    if(toAddClassDocSnap.data()['peers'].includes(this.state.currentUserEmail) == false){
                        (async () => {
                            await updateDoc(doc(this.state.db, "Classes", this.state.addClassID.trim()), {
                                peers: arrayUnion(this.state.currentUserEmail),
                                numPeers: increment(1)
                            }).then(() => {
                                (async () => {
                                    await updateDoc(doc(this.state.db, "Users", this.state.currentUserEmail), {
                                        classes: arrayUnion(this.state.addClassID)
                                    }).then(() => {
                                        this.sendNewClassMemberInbox(this.state.addClassID);
                                        (async () => {
                                            let currentUserClassesList = [];
                                            const userDocRef = doc(this.state.db, "Users", this.state.auth.currentUser['email'])
                                            const userDocRefSnap = await getDoc(userDocRef);
                                            if(userDocRefSnap.exists()){
                                                for(let i = 0; i < userDocRefSnap.data()['classes'].length; i++){
                                                    console.log(userDocRefSnap.data()['classes'][i]);
                                                    const classDocRef = doc(this.state.db, "Classes", userDocRefSnap.data()['classes'][i]);
                                                    const classDocSnap = await getDoc(classDocRef);  
                                                                    
                                                    let bannerToPush = banner4;
    
                                                    if(classDocSnap.data()['banner'] == 1){
                                                        bannerToPush = banner1
                                                    }else if(classDocSnap.data()['banner'] == 2){
                                                        bannerToPush = banner2
                                                    }else if(classDocSnap.data()['banner'] == 3){
                                                        bannerToPush = banner3
                                                    }else if(classDocSnap.data()['banner'] == 4){
                                                        bannerToPush = banner4
                                                    }   
    
                                                    currentUserClassesList.push(
                                                        {
                                                            classID: userDocRefSnap.data()['classes'][i], 
                                                            classJoinLink: classDocSnap.data()['joinLink'], 
                                                            name: classDocSnap.data()['name'],
                                                            bannerTing: bannerToPush
                                                        }
                                                    );                                                                        
                                                }

                                                this.setState({currentUserClasses: currentUserClassesList});                                                                    
                                                this.setState({newModalOpen: false});
                                                this.setState({newModalView: 'main'});
                                                this.setState({activeTab: 'class'});
                                                this.setState({focusedClass: this.state.addClassID}, this.getClass2); 
                                                this.showSnackbar('Successfully added you to the class');
                                            }                                             
                                        })()                                         
                                    }).catch((error) => {
                                        this.setState({addClassError: 'There was an issue adding this class 3'});
                                        this.setState({newModalView: 'addClass'});
                                    });
                                })()
                            }).catch((error) => {
                                this.setState({addClassError: 'There was an issue adding this class'});
                                this.setState({newModalView: 'addClass'});
                            });
                        })()
                    }else{
                        this.setState({addClassError: 'You are already in this class'});
                        this.setState({newModalView: 'addClass'});
                    }
                }else{
                    this.setState({addClassError: 'This class is closed for new students'});
                    this.setState({newModalView: 'addClass'});
                }
            }else{
                this.setState({addClassError: 'This class ID is invalid'});
                this.setState({newModalView: 'addClass'});
            }
        }).catch((error) => {
            this.setState({addClassError: 'There was an issue adding this class'});
            this.setState({newModalView: 'addClass'});
        });
    }

    showSnackbar(snackbarText){
        this.setState({snackbarText: snackbarText}, () => {
            this.setState({snackbarOpen: true}, () => {
                setTimeout(() => {
                    this.setState({snackbarOpen: false});
                    this.setState({snackbarText: ""});
                }, 5000);
            });
        });
    }

    signOut() {
        signOut(this.state.auth).then(() => {
            console.log('Signed Out');
        }).catch((error) => {
            console.log("error signing out" + error);
        });
    }    
   
    setFocusedPost(postID){
        this.setState({focusedPostID: postID}, this.getPost3)
    }

    renderSolutionsImages = (image, idx) => {
        return(
            <li key={idx} style={{display: 'inline-block', verticalAlign: 'top', height: '100%', width: 150, marginRight: 15}}>
                <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 5}} src={image.solutionImage}/>
            </li>
        );        
    }

    renderSolutionsEquations = (equation, idx) => {
        return(
            <li key={idx} style={{display: 'inline-block', position: 'relative', height: 'auto', width: 'auto', marginRight: 15, backgroundColor: '#222', padding: 10, borderRadius: 5}} onClick={() => {
            }}>
                <p style={{margin: 0, padding: 0, marginBottom: 10, color: '#FFF'}}>{equation.index})</p>
                 <MathView
                    style={{fontSize: 25,  width: 'auto', color: '#FFF'}}
                    value={equation.equationValue}
                /> 
                <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'transparent', zIndex: 4}}>
                </div>               
            </li>
        );        
    }

    renderSolutionsCodeSnippets = (codeSnippet, idx) => {
        return(
            <li key={idx} style={{backgroundColor: '#222', position: 'relative', display: 'block', margin: 0, padding: 0, marginBottom: 10}}>
                <ReactQuill
                    readOnly={true}
                    modules={this.listModules}
                    theme="snow"
                    style={{marginBottom: 15}}
                    value={codeSnippet}
                    onChange={(nothingToChange) => {
                        
                    }}
                />   
            </li>
        )
    }
    renderFocusedPostSolutions = (solution, idx) => {
        return(
            <li style={{margin: 0, padding: 0, marginBottom: 25}}>
                <div style={{backgroundColor: '#FFF', margin: 0, padding: 15, borderRadius: 2.5, marginTop: 0, position: 'relative', boxShadow: '0 0 3px 3px #ededed'}}>                                   
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: 15}}>
                        {solution.solutionCreatorProfilePicture != "" ? 
                            <div style={{marginRight: 15, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <img draggable={false} style={{width: 45, height: 45, borderRadius: 5, objectFit: 'cover'}} src={solution.solutionCreatorProfilePicture} alt="logo" />
                            </div>                                  
                            :
                            <div style={{marginRight: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', width: 40, height: 40, backgroundColor: '#f1f6fb', borderRadius: 5}}>
                                <p style={{marginRight: 1, color: '#b0b0b0'}}>{solution.solutionCreatorFullName.split(" ")[0][0]}</p>
                                <p style={{color: '#b0b0b0'}}>{solution.solutionCreatorFullName.split(" ")[1][0]}</p>
                            </div>                                       
                        }     
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <p style={{margin: 0, padding: 0, color: '#AAA'}}>{solution.solutionCreatorFullName.split(" ")[0]} {solution.solutionCreatorFullName.split(" ")[1]}</p>  
                            <p style={{margin: 0, padding: 0, color: '#AAA'}}>{solution.solutionCreationTimestamp}</p>  
                        </div>
                    </div>

                    <p style={{margin: 0, padding: 0, marginBottom: 15}}>{solution.solutionTextContent}</p>

                    {solution.solutionImages.length > 0 ? 
                        <ul style={{marginBottom: 15, width: '100%', display: 'inline-block', overflow: 'auto', maxWidth: '100%', whiteSpace: 'nowrap', padding: 0, height: 200}}>
                            <FlatList
                                style={{margin: 0, padding: 0}}
                                list={solution.solutionImages}
                                renderItem={this.renderSolutionsImages}
                                renderWhenEmpty={() => <div>No Images</div>}                                    
                            />
                        </ul>                           
                    :
                        null
                    }  

                    {solution.solutionEquations.length > 0 ?
                        <ul style={{marginBottom: 15, width: '100%', display: 'inline-block', overflow: 'auto', maxWidth: '100%', whiteSpace: 'nowrap', paddingTop: 15, paddingBottom: 15, paddingRight: 0, paddingLeft: 0, height: 'auto', backgroundColor: '#FFF', verticalAlign: 'center', alignItems: 'center', borderRadius: 5}}>
                            <p style={{margin: 0, padding: 0, fontSize: 17, color: '#000', fontWeight: '600', marginBottom: 10}}>Added Equations ({solution.solutionEquations.length})</p>
                            <FlatList
                                style={{margin: 0, padding: 0}}
                                list={solution.solutionEquations}
                                renderItem={this.renderSolutionsEquations}
                                renderWhenEmpty={() => <div>No Children</div>}                                    
                            />
                        </ul>                                    
                        :
                        null                        
                    }  
                    
                    {solution.solutionCodeSnippets.length > 0 ? 
                        <div>
                            
                            {this.state.focusedPostSolutionsCodeSnippetsOpened.includes(solution.solutionID) == true ? 
                                <div style={{width: '100%'}}>
                                    <Button style={{marginBottom: 15, width: '100%'}} variant="outlined" onClick={() => {
                                        let newOpenedCodeSnippetsList = [];

                                        this.state.focusedPostSolutionsCodeSnippetsOpened.forEach((openedCodeSnippetID) => {
                                            if(openedCodeSnippetID != solution.solutionID){
                                                newOpenedCodeSnippetsList.push(openedCodeSnippetID);
                                            };
                                        })
                                        this.setState({focusedPostSolutionsCodeSnippetsOpened: newOpenedCodeSnippetsList});
                                        
                                    }}>
                                        Hide Attached Code Snippets ({solution.solutionCodeSnippets.length}) 
                                    </Button>   
                                    <ul style={{listStyle: 'none', margin: 0, padding: 0}}>
                                        <FlatList
                                            list={solution.solutionCodeSnippets}
                                            renderItem={this.renderSolutionsCodeSnippets}
                                            renderWhenEmpty={() => 
                                                <div/>
                                            }
                                        />  
                                    </ul>                                                                                                                  
                                </div>                                             
                                :
                                <div style={{width: '100%'}}>
                                    <Button style={{marginBottom: 15, width: '100%'}} variant="outlined" onClick={() => {
                                        let newOpenedCodeSnippetsList = this.state.focusedPostSolutionsCodeSnippetsOpened;
                                        newOpenedCodeSnippetsList.push(solution.solutionID);
                                        this.setState({focusedPostSolutionsCodeSnippetsOpened: newOpenedCodeSnippetsList});
                                    }}>
                                        Show Attached Code Snippets ({solution.solutionCodeSnippets.length}) 
                                    </Button>                                                                          
                                </div> 
                            }
                        </div>
                        :
                        null
                    }                                       
                </div> 
            </li>            
        )
    }    

    renderCurrentUserClasses = (currentUserClass, idx) => {
        return (
            <li style={{width: '100%', display: 'flex', alignItems: 'center', backgroundColor: '#f2f2f2', margin: 0, paddingTop: 10, paddingBottom: 10, paddingRight: 10, paddingLeft: 15, marginLeft: 0, marginRight: 0, marginBottom: 10}}  onClick={() => {
                this.setState({activeTab: 'class'});
                this.setState({focusedClass: currentUserClass.classID}, this.getClass2);
            }}>
                {this.state.focusedClass == currentUserClass.classID ? 
                    <p style={{padding: 0, margin: 0, color: '#1976d2', fontWeight: 'bold'}}>{currentUserClass.name}</p>
                    :
                    <p style={{padding: 0, margin: 0, color: '#AAA'}}>{currentUserClass.name}</p>                    
                }                
            </li>              
        );                
    }
    renderCurrentUserProfileClasses = (currentUserClass, idx) => {
        return(
            <li  style={{display: 'inline-block', verticalAlign: 'top', height: 225, width: 200, overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word', backgroundColor: '#FFF', borderRadius: 5, marginRight: 25, boxShadow: '0 0 3px 3px #ededed'}}>
                <div style={{width: '100%', height: '100%', borderRadius: 5}}>
                    {(() => {
                        switch (currentUserClass.bannerTing) {
                            case 1:
                                return(
                                    <img draggable={false} src={classBanner1} style={{width: '100%', height: 125, objectFit: 'cover', borderTopLeftRadius: 0, borderTopRightRadius: 0, padding: 0, margin: 0}}/> 
                                )
                            case 2:
                                return(
                                    <img draggable={false} src={classBanner2} style={{width: '100%', height: 125, objectFit: 'cover', borderTopLeftRadius: 0, borderTopRightRadius: 0, padding: 0, margin: 0}}/> 
                                )   
                            case 3:
                                return(
                                    <img draggable={false} src={classBanner3} style={{width: '100%', height: 125, objectFit: 'cover', borderTopLeftRadius: 0, borderTopRightRadius: 0, padding: 0, margin: 0}}/>            
                                )
                            case 4:
                                return(
                                    <img draggable={false} src={classBanner4} style={{width: '100%', height: 125, objectFit: 'cover', borderTopLeftRadius: 0, borderTopRightRadius: 0, padding: 0, margin: 0}}/> 
                                ) 
                            case 5:
                                return(
                                    <img draggable={false} src={classBanner5} style={{width: '100%', height: 125, objectFit: 'cover', borderTopLeftRadius: 0, borderTopRightRadius: 0, padding: 0, margin: 0}}/> 
                                ) 
                            case 6:
                                return(
                                    <img draggable={false} src={classBanner6} style={{width: '100%', height: 125, objectFit: 'cover', borderTopLeftRadius: 0, borderTopRightRadius: 0, padding: 0, margin: 0}}/> 
                                ) 
                            case 7:
                                return(
                                    <img draggable={false} src={classBanner7} style={{width: '100%', height: 125, objectFit: 'cover', borderTopLeftRadius: 0, borderTopRightRadius: 0, padding: 0, margin: 0}}/> 
                                ) 
                            case 8:
                                return(
                                    <img draggable={false} src={classBanner8} style={{width: '100%', height: 125, objectFit: 'cover', borderTopLeftRadius: 0, borderTopRightRadius: 0, padding: 0, margin: 0}}/> 
                                ) 
                            case 9:
                                return(
                                    <img draggable={false} src={classBanner9} style={{width: '100%', height: 125, objectFit: 'cover', borderTopLeftRadius: 0, borderTopRightRadius: 0, padding: 0, margin: 0}}/> 
                                ) 
                            case 10:
                                return(
                                    <img draggable={false} src={classBanner10} style={{width: '100%', height: 125, objectFit: 'cover', borderTopLeftRadius: 0, borderTopRightRadius: 0, padding: 0, margin: 0}}/> 
                                ) 
                            case 11:
                                return(
                                    <img draggable={false} src={classBanner11} style={{width: '100%', height: 125, objectFit: 'cover', borderTopLeftRadius: 0, borderTopRightRadius: 0, padding: 0, margin: 0}}/> 
                                ) 
                            case 12:
                                return(
                                    <img draggable={false} src={classBanner12} style={{width: '100%', height: 125, objectFit: 'cover', borderTopLeftRadius: 0, borderTopRightRadius: 0, padding: 0, margin: 0}}/> 
                                ) 
                            case 13:
                                return(
                                    <img draggable={false} src={classBanner13} style={{width: '100%', height: 125, objectFit: 'cover', borderTopLeftRadius: 0, borderTopRightRadius: 0, padding: 0, margin: 0}}/> 
                                )                                                                                                                                                                                                                                                                                                                                 
                            default:
                                return(
                                    <img draggable={false} src={classBanner13} style={{width: '100%', height: 125, objectFit: 'cover', borderTopLeftRadius: 0, borderTopRightRadius: 0, padding: 0, margin: 0}}/> 
                                )   
                        }                                
                    })()} 
                    <div style={{width: '100%', margin: 0, padding: 0, marginTop: 10, height: 25, overflow: 'hidden', display: 'block', whiteSpace: 'nowrap', paddingRight: 10, paddingLeft: 10}}>
                        <p style={{color: '#000', padding: 0, margin: 0, fontWeight: 'bold', textOverflow: 'ellipsis', overflow: 'hidden', display: 'block', whiteSpace: 'nowrap', fontSize: 19}}>{currentUserClass.name}</p>
                    </div>   
                    <IconButton style={{padding: 0, margin: 0, alignSelf: 'flex-start', marginLeft: 10}} onClick={() => {
                        this.setState({classSettingsID: currentUserClass.classID});
                        this.setState({classSettingsJoinLink: currentUserClass.classJoinLink});
                        this.setState({classSettingsName: currentUserClass.name});
                        this.setState({classSettingsOpen: currentUserClass.open});
                        this.setState({classSettingsPostingEnabled: currentUserClass.postingEnabled});
                        this.setState({classSettingsModalOpen: true});
                    }}>
                        <MoreHorizIcon style={{padding: 0, margin: 0, color: '#AAA', fontSize: 30}}/>
                    </IconButton>                  
                </div>
            </li>
        )
    } 

    renderCurrentUserProfileQuestions = (question, idx) => {
        return(
            <li  style={{display: 'inline-block', verticalAlign: 'top', height: 225, width: 200, overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word', backgroundColor: '#FFF', borderRadius: 5, marginRight: 25, boxShadow: '0 0 3px 3px #ededed'}}>
                <div style={{width: '100%', height: '100%', borderRadius: 5, paddingRight: 10, paddingLeft: 10, paddingTop: 20}}>

                    <div style={{width: '100%', margin: 0, padding: 0,height: 30, overflow: 'hidden', display: 'block', whiteSpace: 'nowrap'}}>
                        <p style={{color: '#000', padding: 0, margin: 0, fontWeight: 'bold', textOverflow: 'ellipsis', overflow: 'hidden', display: 'block', whiteSpace: 'nowrap', fontSize: 19}}>{question.questionTitle}</p>
                        
                    </div>                         
                    <p style={{color: '#AAA', padding: 0, margin: 0, paddingLeft: 0, marginBottom: 5}}>{question.questionCreationTimestamp}</p>      

                    {question.questionHidden == true ?
                        <Button style={{padding: 0, margin: 0}} onClick={() => {
                            (async () => {
                                await updateDoc(doc(this.state.db, "Classes/" + question.questionClassID + "/Feed/" + question.questionID), {
                                    hidden: false
                                }).then(() => {
                                    this.getCurrentUser();
                                    this.showSnackbar("Question will be shown in class feed");
                                }).catch((error) => {});
                            })().catch((error) => {});
                        }}>
                            <VisibilityIcon style={{color: '#000'}}/>
                            <p style={{padding: 0, margin: 0, marginLeft: 5, color: '#AAA'}}>Show In Feed</p>
                        </Button>
                        :
                        <Button style={{padding: 0, margin: 0}} onClick={() => {
                            (async () => {
                                await updateDoc(doc(this.state.db, "Classes/" + question.questionClassID + "/Feed/" + question.questionID), {
                                    hidden: true
                                }).then(() => {
                                    this.getCurrentUser();
                                    this.showSnackbar("Question will be hidden from class feed. You can change this in your Profile screen.");
                                }).catch((error) => {});
                            })().catch((error) => {});                            
                        }}>
                            <VisibilityOffIcon style={{color: '#000'}}/>
                            <p style={{padding: 0, margin: 0, marginLeft: 5, color: '#AAA'}}>Hide From Feed</p>
                        </Button>                            
                    } 

                    <div style={{display: 'flex', flexDirection: 'row', marginTop: 15, alignItems: 'center'}}>
                        <ChatBubbleOutlineIcon fontSize="small" style={{margin: 0, padding: 0}}/>
                        <p style={{margin: 0, padding: 0, marginLeft: 5}}>{question.questionNumKnowledgeBits}</p>                     
                    </div>                                          
                </div>                
            </li>            
        )
    }

    renderCurrentUserSolutions = (solution, idx) => {
        return(
            <li  style={{display: 'inline-block', verticalAlign: 'top', height: 225, width: 200, overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word', backgroundColor: '#FFF', borderRadius: 5, marginRight: 25, boxShadow: '0 0 3px 3px #ededed'}}>
                <div style={{width: '100%', height: '100%', borderRadius: 5, paddingRight: 10, paddingLeft: 10, paddingTop: 20}}>

                    <div style={{width: '100%', margin: 0, padding: 0,height: 30, overflow: 'hidden', display: 'block', whiteSpace: 'nowrap'}}>
                        <p style={{color: '#000', padding: 0, margin: 0, fontWeight: 'bold', textOverflow: 'ellipsis', overflow: 'hidden', display: 'block', whiteSpace: 'nowrap', fontSize: 19}}>{solution.postTitle}</p>
                        
                    </div>                         
                    <p style={{color: '#AAA', padding: 0, margin: 0, paddingLeft: 0}}>{solution.postCreationTimestamp}</p>                    
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: 15, alignItems: 'center'}}>
                        <ChatBubbleOutlineIcon fontSize="small" style={{margin: 0, padding: 0}}/>
                        <p style={{margin: 0, padding: 0, marginLeft: 5}}>{solution.postNumKnowledgeBits}</p>
                    </div>                    
                </div>                
            </li>   
          
        )        
    }
    renderCurrentUserClassesNewPost = (currentUserClass, idx) => {
        return (
            <li key={idx} style={{display: 'inline-block', verticalAlign: 'top', padding: 0, margin: 0, paddingTop: 5, paddingBottom: 5}}>
                {this.state.newPostTargetClass == currentUserClass.classID ? 
                    <button style={{width: 200, backgroundColor: '#000', color: '#FFF'}} onClick={() => {
                        this.setState({newPostTargetClass: currentUserClass.classID})
                    }}>
                        <b>{currentUserClass.name}</b>  
                    </button>    
                    :
                    <button style={{width: 200}} onClick={() => {
                        this.setState({newPostTargetClass: currentUserClass.classID})
                    }}>
                        <b>{currentUserClass.name}</b>  
                    </button>                                
                }
            </li>
        );    
    }
    renderFocusedClassFeed = (post, idx) => {
        return (
            <li key={idx} style={{marginBottom: 25, position: 'relative'}}>            
                <Paper sx={{display: 'flex', flexDirection: 'column', padding: 2}}>
                    <h2 style={{margin: 0, padding: 0}} onClick={() => {
                        this.setFocusedPost(post.postID);
                    }}>
                        {post.postTitle}
                    </h2>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        {post.postCreatorProfilePicture != "" ? 
                            <div style={{marginRight: 15, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <img draggable={false} style={{width: 40, height: 40, borderRadius: 5, objectFit: 'cover'}} src={post.postCreatorProfilePicture} alt="logo" />
                            </div>                                  
                            :
                            <div style={{marginRight: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', width: 40, height: 40, backgroundColor: '#f1f6fb', borderRadius: 5}}>
                                <p style={{marginRight: 1, color: '#b0b0b0'}}>{post.postCreatorFName[0]}</p>
                                <p style={{color: '#b0b0b0'}}>{post.postCreatorLName[0]}</p>
                            </div>                                       
                        }
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <Typography style={{color: '#AAA'}}>
                                {post.postCreatorFName}
                                {" "}
                                {post.postCreatorLName}
                            </Typography>                                        
                            <p style={{margin: 0, padding: 0, color: '#AAA'}}>{post.postCreationTimestamp}</p>  
                        </div>                                                              
                                            
                    </div>

                    <p style={{margin: 0, marginTop: 15}}>{post.postTextContent}</p>                    

                    {post.postSolved == true ? 
                        <Button style={{display: 'flex', width: 90, height: 25, marginTop: 10, marginBottom: 5}} variant="contained">Solved</Button>
                        :
                        null
                    }

                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 0, padding: 0, marginTop: 10}}>
                        <ChatBubbleOutlineIcon fontSize="small" style={{margin: 0, padding: 0, marginRight: 5}}/>                        
                        <p style={{margin: 0, padding: 0}}>{post.postNumKnowledgeBits}</p>
                    </div>
                    
                </Paper>                  
            </li>
        ); 
    }
    renderChildKnowledgeBits = (childKnowledgeBit, idx) => {
        return(
            <li style={{margin: 0, padding: 0, marginTop: 5}}>
                <div style={{backgroundColor: '#FFF', margin: 0, padding: 15, borderRadius: 2.5, marginTop: 0, position: 'relative'}}>
                    {this.state.focusedPostCreator == this.state.auth.currentUser['email'] ?
                    <div>
                        {this.state.focusedPostSolutions.includes(childKnowledgeBit.id) ? 
                            <div style={{width: 30, height: 30, backgroundColor: '#1976d2', position: 'absolute', right: 0, top: 0, borderTopRightRadius: 2.5, borderBottomLeftRadius: 2.5, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>                                      
                                <IconButton onClick={() => {
                                        this.setState({solutionKnowledgeBitTextContent: childKnowledgeBit.textContent});
                                        let focusedPostSolutionsList = this.state.focusedPostSolutions;
                                        let solutionIDIndex = focusedPostSolutionsList.indexOf(childKnowledgeBit.id);
                                        focusedPostSolutionsList.splice(solutionIDIndex, 1)
                                        this.setState({focusedPostSolutionsUpdateAction: 'delete'});
                                        this.setState({focusedPostSolutionsUpdateSolver: childKnowledgeBit.creator})
                                        this.setState({focusedPostSolutions: focusedPostSolutionsList}, () => {
                                            this.updateFocusedPostSolutions(childKnowledgeBit.rootID, childKnowledgeBit.id)
                                        });
                                }}>
                                    <CheckIcon style={{color: '#FFF'}}/>
                                </IconButton>
                            </div>                    
                            :      
                            <div style={{width: 30, height: 30, backgroundColor: '#E8E8E8', position: 'absolute', right: 0, top: 0, borderTopRightRadius: 2.5, borderBottomLeftRadius: 2.5, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <IconButton onClick={() => {
                                    this.setState({solutionKnowledgeBitTextContent: childKnowledgeBit.textContent});
                                    let focusedPostSolutionsList = this.state.focusedPostSolutions;
                                    focusedPostSolutionsList.push(childKnowledgeBit.id)
                                    this.setState({focusedPostSolutionsUpdateAction: 'add'});
                                    this.setState({focusedPostSolutionsUpdateSolver: childKnowledgeBit.creator})
                                    this.setState({focusedPostSolutions: focusedPostSolutionsList}, () => {
                                        this.updateFocusedPostSolutions(childKnowledgeBit.rootID, childKnowledgeBit.id)
                                    });                                        
                                }}>
                                    <CheckIcon style={{color: '#FFF'}}/>
                                </IconButton>
                            </div>                                  
                        }     
                    </div>           
                    :
                    <div>
                        {this.state.focusedPostSolutions.includes(childKnowledgeBit.id) ? 
                            <div style={{width: 30, height: 30, backgroundColor: '#1976d2', position: 'absolute', right: 0, top: 0, borderTopRightRadius: 2.5, borderBottomLeftRadius: 2.5, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>                                                                      
                                <CheckIcon style={{color: '#FFF'}}/>
                            </div>                    
                            :      
                            null                                 
                        }     
                    </div>                      
                }                    
                <div style={{display: 'flex', flexDirection: 'row', marginBottom: 15, alignItems: 'center'}}>               
                    <ReplyIcon style={{marginRight: 5, color: '#1976d2'}}/>
                    <p style={{margin: 0, padding: 0, color: '#1976d2'}}>Replying to {childKnowledgeBit.replyingToFullName}</p>
                </div>                    
                
                <div style={{display: 'flex', flexDirection: 'row', marginBottom: 15}}>
                    {childKnowledgeBit.creatorProfilePictureURL != "" ? 
                        <div style={{marginRight: 15, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img draggable={false} style={{width: 45, height: 45, borderRadius: 45/2}} src={childKnowledgeBit.creatorProfilePictureURL} alt="logo" />
                        </div>                                  
                        :
                        <div style={{marginRight: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', width: 40, height: 40, backgroundColor: '#f1f6fb', borderRadius: 5}}>
                            <p style={{marginRight: 1, color: '#b0b0b0'}}>{childKnowledgeBit.creatorFName[0]}</p>
                            <p style={{color: '#b0b0b0'}}>{childKnowledgeBit.creatorLName[0]}</p>
                        </div>                                       
                    }     
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <p style={{margin: 0, padding: 0, color: '#AAA'}}>{childKnowledgeBit.creatorFName} {childKnowledgeBit.creatorLName}</p>  
                        <p style={{margin: 0, padding: 0, color: '#AAA'}}>{childKnowledgeBit.creationTimestamp}</p>  
                    </div>
                </div>
                <p style={{margin: 0, padding: 0, marginBottom: 15}}>{childKnowledgeBit.textContent}</p>              
                
                {childKnowledgeBit.images.length > 0 ? 
                    <ul style={{marginBottom: 15, width: '100%', display: 'inline-block', overflow: 'auto', maxWidth: '100%', whiteSpace: 'nowrap', padding: 0, height: 200}}>
                        <FlatList
                            style={{margin: 0, padding: 0}}
                            list={childKnowledgeBit.images}
                            renderItem={this.renderChildKnowledgeBitImages}
                            renderWhenEmpty={() => <div>No Images</div>}                                    
                        />
                    </ul>                           
                :
                    null
                }

                {childKnowledgeBit.equations.length > 0 ? 
                        <ul style={{marginBottom: 15, width: '100%', display: 'inline-block', overflow: 'auto', maxWidth: '100%', whiteSpace: 'nowrap', paddingTop: 15, paddingBottom: 15, paddingRight: 0, paddingLeft: 0, height: 'auto', backgroundColor: '#FFF', verticalAlign: 'center', alignItems: 'center', borderRadius: 5}}>
                            <p style={{margin: 0, padding: 0, fontSize: 17, color: '#000', fontWeight: '600', marginBottom: 10}}>Added Equations ({childKnowledgeBit.equations.length})</p>
                            <FlatList
                                style={{margin: 0, padding: 0}}
                                list={childKnowledgeBit.equations}
                                renderItem={this.renderKnowledgeBitEquations}
                                renderWhenEmpty={() => <div>No Children</div>}                                    
                            />
                        </ul>                                    
                    :
                        null
                }  

                {childKnowledgeBit.codeSnippets.length > 0 ? 
                    <div>
                        
                        {this.state.codeSnippetsOpened.includes(childKnowledgeBit.id) == true ? 
                            <div style={{width: '100%'}}>
                                <Button style={{marginBottom: 15, width: '100%'}} variant="outlined" onClick={() => {
                                    let newOpenedCodeSnippetsList = [];

                                    this.state.codeSnippetsOpened.forEach((openedCodeSnippetID) => {
                                        if(openedCodeSnippetID != childKnowledgeBit.id){
                                            newOpenedCodeSnippetsList.push(openedCodeSnippetID);
                                        };
                                    })
                                    this.setState({codeSnippetsOpened: newOpenedCodeSnippetsList});
                                    
                                }}>
                                    Hide Attached Code Snippets ({childKnowledgeBit.codeSnippets.length}) 
                                </Button>   
                                <ul style={{listStyle: 'none', margin: 0, padding: 0, marginBottom: 15}}>
                                    <FlatList
                                        list={childKnowledgeBit.codeSnippets}
                                        renderItem={this.renderKnowledgeBitCodeSnippets}
                                        renderWhenEmpty={() => 
                                            <div/>
                                        }
                                    />  
                                </ul>                                                                                                                  
                            </div>                                             
                            :
                            <div style={{width: '100%'}}>
                                <Button style={{marginBottom: 15, width: '100%'}} variant="outlined" onClick={() => {
                                    let newOpenedCodeSnippetsList = this.state.codeSnippetsOpened;
                                    newOpenedCodeSnippetsList.push(childKnowledgeBit.id);
                                    this.setState({codeSnippetsOpened: newOpenedCodeSnippetsList});
                                }}>
                                    Show Attached Code Snippets ({childKnowledgeBit.codeSnippets.length}) 
                                </Button>                                                                          
                            </div> 
                        }
                    </div>
                    :
                    null
                }                   
                            
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 0}}>
                    <div style={{width: '100%', display: 'flex', padding: 0, margin: 0}}>
                        <Button style={{width: '100%'}} variant="outlined" onClick={() => {
                            this.setState({newChildKnowledgeBitTextContent: ""});
                            this.setState({newChildKnowledgeBitParentID: childKnowledgeBit.id})
                            this.setState({newChildKnowledgeBitParentCreator: childKnowledgeBit.creator})
                            this.setState({newChildKnowledgeBitParentKnowledgeBitTextContent: childKnowledgeBit.textContent})
                            this.setState({newChildKnowledgeBitRootID: childKnowledgeBit.rootID})
                            this.setState({newChildKnowledgeBitParentCreatorFName: childKnowledgeBit.creatorFName})
                            this.setState({newChildKnowledgeBitParentCreatorFullName: childKnowledgeBit.creatorFName + " " + childKnowledgeBit.creatorLName})
                            this.setState({newChildKnowledgeBitModalOpen: true})
                        }}>
                            + Reply
                        </Button>                               
                    </div>                              
                </div>                     
                </div>                               
            </li>                
        )
    }

    renderFocusedPostKnowledgeBits = (knowledgeBit, idx) => {
        return (
            <li style={{marginBottom: 25}} key={idx}>                  
                <div style={{backgroundColor: '#FFF', margin: 0, padding: 15, borderRadius: 2.5, marginTop: 0, position: 'relative'}}>
                    {this.state.focusedPostCreator == this.state.auth.currentUser['email'] ?
                        <div>
                            {this.state.focusedPostSolutions.includes(knowledgeBit.id) ? 
                                    <div style={{width: 30, height: 30, backgroundColor: '#1976d2', position: 'absolute', right: 0, top: 0, borderTopRightRadius: 2.5, borderBottomLeftRadius: 2.5, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <IconButton onClick={() => {
                                            // alert('remove')
                                            this.setState({solutionKnowledgeBitTextContent: knowledgeBit.textContent});
                                            let focusedPostSolutionsList = this.state.focusedPostSolutions;
                                            let solutionIDIndex = focusedPostSolutionsList.indexOf(knowledgeBit.id);
                                            focusedPostSolutionsList.splice(solutionIDIndex, 1)
                                            this.setState({focusedPostSolutionsUpdateAction: 'delete'});
                                            this.setState({focusedPostSolutionsUpdateSolver: knowledgeBit.creator})
                                            this.setState({focusedPostSolutions: focusedPostSolutionsList}, () => {
                                                this.updateFocusedPostSolutions(knowledgeBit.id, knowledgeBit.id)
                                            });
                                        }}>
                                            <CheckIcon style={{color: '#FFF'}}/>
                                        </IconButton>
                                    </div>                    
                                :      
                                    <div style={{width: 30, height: 30, backgroundColor: '#E8E8E8', position: 'absolute', right: 0, top: 0, borderTopRightRadius: 2.5, borderBottomLeftRadius: 2.5, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <IconButton onClick={() => {
                                            // alert('remove')
                                            this.setState({solutionKnowledgeBitTextContent: knowledgeBit.textContent});
                                            let focusedPostSolutionsList = this.state.focusedPostSolutions;
                                            focusedPostSolutionsList.push(knowledgeBit.id)
                                            this.setState({focusedPostSolutionsUpdateAction: 'add'});
                                            this.setState({focusedPostSolutionsUpdateSolver: knowledgeBit.creator})
                                            this.setState({focusedPostSolutions: focusedPostSolutionsList});
                                            this.setState({focusedPostSolutions: focusedPostSolutionsList}, () => {
                                                this.updateFocusedPostSolutions(knowledgeBit.id, knowledgeBit.id)
                                            });
                                        }}>
                                            <CheckIcon style={{color: '#FFF'}}/>
                                        </IconButton>
                                    </div>                                  
                            }     
                        </div>           
                        :
                        <div>
                            {this.state.focusedPostSolutions.includes(knowledgeBit.id) ? 
                                <div style={{width: 30, height: 30, backgroundColor: '#1976d2', position: 'absolute', right: 0, top: 0, borderTopRightRadius: 2.5, borderBottomLeftRadius: 2.5, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>                                                                      
                                    <CheckIcon style={{color: '#FFF'}}/>
                                </div>                    
                                :      
                                null                                 
                            }     
                        </div>                      
                    }
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: 15}}>
                        {knowledgeBit.creatorProfilePictureURL != "" ? 
                            <div style={{marginRight: 15, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <img draggable={false} style={{width: 45, height: 45, borderRadius: 45/2}} src={knowledgeBit.creatorProfilePictureURL} alt="logo" />
                            </div>                                  
                            :
                            <div style={{marginRight: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', width: 40, height: 40, backgroundColor: '#f1f6fb', borderRadius: 5}}>
                                <p style={{marginRight: 1, color: '#b0b0b0'}}>{knowledgeBit.creatorFName[0]}</p>
                                <p style={{color: '#b0b0b0'}}>{knowledgeBit.creatorLName[0]}</p>
                            </div>                                       
                        }                        
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <p style={{margin: 0, padding: 0, color: '#AAA'}}>{knowledgeBit.creatorFName} {knowledgeBit.creatorLName}</p>  
                            <p style={{margin: 0, padding: 0, color: '#AAA'}}>{knowledgeBit.creationTimestamp}</p>  
                        </div>
                    </div>
                    
                    <p style={{margin: 0, padding: 0, marginBottom: 15}}>{knowledgeBit.textContent}</p>


                    {knowledgeBit.images.length > 0 ? 
                        <ul style={{marginBottom: 15, width: '100%', display: 'inline-block', overflow: 'auto', maxWidth: '100%', whiteSpace: 'nowrap', padding: 0, height: 200}}>
                            <FlatList
                                style={{margin: 0, padding: 0}}
                                list={knowledgeBit.images}
                                renderItem={this.renderKnowledgeBitImages}
                                renderWhenEmpty={() => <div>No Images</div>}                                    
                            />
                        </ul>                           
                    :
                        null
                    }


                        
                    {knowledgeBit.equations.length > 0 ? 
                            <ul style={{marginBottom: 15, width: '100%', display: 'inline-block', overflow: 'auto', maxWidth: '100%', whiteSpace: 'nowrap', paddingTop: 15, paddingBottom: 15, paddingRight: 0, paddingLeft: 0, height: 'auto', backgroundColor: '#FFF', verticalAlign: 'center', alignItems: 'center', borderRadius: 5}}>
                                <p style={{margin: 0, padding: 0, fontSize: 17, color: '#000', fontWeight: '600', marginBottom: 10}}>Added Equations ({knowledgeBit.equations.length})</p>
                                <FlatList
                                    style={{margin: 0, padding: 0}}
                                    list={knowledgeBit.equations}
                                    renderItem={this.renderKnowledgeBitEquations}
                                    renderWhenEmpty={() => <div>No Children</div>}                                    
                                />
                            </ul>                                    
                        :
                            null
                    }   

                    {knowledgeBit.codeSnippets.length > 0 ? 
                        <div>
                            
                            {this.state.codeSnippetsOpened.includes(knowledgeBit.id) == true ? 
                                <div style={{width: '100%'}}>
                                    <Button style={{marginBottom: 15, width: '100%'}} variant="outlined" onClick={() => {
                                        let newOpenedCodeSnippetsList = [];

                                        this.state.codeSnippetsOpened.forEach((openedCodeSnippetID) => {
                                            if(openedCodeSnippetID != knowledgeBit.id){
                                                newOpenedCodeSnippetsList.push(openedCodeSnippetID);
                                            };
                                        })
                                        this.setState({codeSnippetsOpened: newOpenedCodeSnippetsList});
                                        
                                    }}>
                                        Hide Attached Code Snippets ({knowledgeBit.codeSnippets.length}) 
                                    </Button>   
                                    <ul style={{listStyle: 'none', margin: 0, padding: 0, marginBottom: 15}}>
                                        <FlatList
                                            list={knowledgeBit.codeSnippets}
                                            renderItem={this.renderKnowledgeBitCodeSnippets}
                                            renderWhenEmpty={() => 
                                                <div/>
                                            }
                                        />  
                                    </ul>                                                                                                                  
                                </div>                                             
                                :
                                <div style={{width: '100%'}}>
                                    <Button style={{marginBottom: 15, width: '100%'}} variant="outlined" onClick={() => {
                                        let newOpenedCodeSnippetsList = this.state.codeSnippetsOpened;
                                        newOpenedCodeSnippetsList.push(knowledgeBit.id);
                                        this.setState({codeSnippetsOpened: newOpenedCodeSnippetsList});
                                    }}>
                                        Show Attached Code Snippets ({knowledgeBit.codeSnippets.length}) 
                                    </Button>                                                                          
                                </div> 
                            }
                        </div>
                        :
                        null
                    }                                                

                    <div>
                        {knowledgeBit.children.length > 0 ?
                            <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 0}}>
                                <div style={{width: '50%', padding: 0, margin: 0}}>
                                    {!this.state.openedChildKnowledgeBits.includes(knowledgeBit.id) ?                                    
                                            <Button style={{width: '95%'}} variant="contained" onClick={() => {
                                                let currentOpenedChildKnowledgeBits = this.state.openedChildKnowledgeBits
                                                if(currentOpenedChildKnowledgeBits.includes(knowledgeBit.id)){
                                                    let knowledgeBitIndex = currentOpenedChildKnowledgeBits.indexOf(knowledgeBit.id)
                                                    currentOpenedChildKnowledgeBits.splice(knowledgeBitIndex, 1)
                                                    this.setState({openedChildKnowledgeBits: currentOpenedChildKnowledgeBits})
                                                    console.log('hide')
                                                }else{
                                                    currentOpenedChildKnowledgeBits.push(knowledgeBit.id)
                                                    this.setState({openedChildKnowledgeBits: currentOpenedChildKnowledgeBits})
                                                    console.log('show')
                                                }
                            
                                            }}>
                                                Show Replies {knowledgeBit.children.length}
                                            </Button>       
                                        :
                                            <Button style={{width: '95%'}} variant="contained" onClick={() => {
                                                let currentOpenedChildKnowledgeBits = this.state.openedChildKnowledgeBits
                                                if(currentOpenedChildKnowledgeBits.includes(knowledgeBit.id)){
                                                    let knowledgeBitIndex = currentOpenedChildKnowledgeBits.indexOf(knowledgeBit.id)
                                                    currentOpenedChildKnowledgeBits.splice(knowledgeBitIndex, 1)
                                                    this.setState({openedChildKnowledgeBits: currentOpenedChildKnowledgeBits})
                                                    console.log('hide')
                                                }else{
                                                    currentOpenedChildKnowledgeBits.push(knowledgeBit.id)
                                                    this.setState({openedChildKnowledgeBits: currentOpenedChildKnowledgeBits})
                                                    console.log('show')
                                                }
                            
                                            }}>
                                                Hide Replies {knowledgeBit.children.length}
                                            </Button> 
                                                
                                    } 
                                    
                                    
                                </div>
                                <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                    <Button style={{width: '95%'}} variant="outlined" onClick={() => {
                                        this.setState({newChildKnowledgeBitTextContent: ""});                                        
                                        this.setState({newChildKnowledgeBitParentID: knowledgeBit.id})
                                        this.setState({newChildKnowledgeBitParentCreatorFName: knowledgeBit.creatorFName})
                                        this.setState({newChildKnowledgeBitParentCreator: knowledgeBit.creator})
                                        this.setState({newChildKnowledgeBitParentKnowledgeBitTextContent: knowledgeBit.textContent})
                                        this.setState({newChildKnowledgeBitRootID: knowledgeBit.id});
                                        this.setState({newChildKnowledgeBitParentCreatorFullName: knowledgeBit.creatorFName + " " + knowledgeBit.creatorLName})
                                        this.setState({newChildKnowledgeBitModalOpen: true})                                        

                                    }}>
                                        + Reply
                                    </Button>                               
                                </div>      
                            </div>
                            :
                            <div style={{width: '100%', display: 'flex', padding: 0, margin: 0}}>
                                <Button style={{width: '100%'}} variant="outlined" onClick={() => {
                                    this.setState({newChildKnowledgeBitTextContent: ""});                                    
                                    this.setState({newChildKnowledgeBitParentID: knowledgeBit.id})
                                    this.setState({newChildKnowledgeBitParentCreatorFName: knowledgeBit.creatorFName})
                                    this.setState({newChildKnowledgeBitParentCreator: knowledgeBit.creator})
                                    this.setState({newChildKnowledgeBitRootID: knowledgeBit.id});
                                    this.setState({newChildKnowledgeBitParentKnowledgeBitTextContent: knowledgeBit.textContent})
                                    this.setState({newChildKnowledgeBitParentCreatorFullName: knowledgeBit.creatorFName + " " + knowledgeBit.creatorLName})
                                    this.setState({newChildKnowledgeBitModalOpen: true})                                    
                                }}>
                                    + Reply
                                </Button>                               
                            </div>                                    
                        }                 
                    </div> 
                </div>                                          

                {knowledgeBit.children.length > 0 ?
                    <div>
                        {this.state.openedChildKnowledgeBits.includes(knowledgeBit.id) ?
                                <ul style={{margin: 0, marginTop: 5, marginBottom: 0, padding: 0, listStyle: 'none', listStyleType: 'none', borderLeft: '8px solid #1976d2'}}>
                                    <FlatList
                                        style={{margin: 0, padding: 0,}}
                                        list={knowledgeBit.children}
                                        renderItem={this.renderChildKnowledgeBits}
                                        renderWhenEmpty={() => <div>No Children</div>}
                                    />        
                                </ul>                                
                            :
                                null
                        }
                    
                    </div>
            
                    :
                    null
                }  
            </li>                                  
        ); 
    }
    renderNewClassBanners = (banner, idx) => {
        return(
            <li key={idx} style={{display: 'inline-block', verticalAlign: 'top', height: '100%', width: 150, marginRight: 15}}>
                {banner.id == this.state.newClassBannerID ? 
                    <img draggable={false} onClick={() => {this.setState({newClassBannerID: banner.id})}} style={{width: '100%', height: '100%', objectFit: 'cover', backgroundColor: '#000', borderRadius: 5, padding: 5}} src={banner.banner} />
                    :
                    <img draggable={false} onClick={() => {this.setState({newClassBannerID: banner.id})}} style={{width: '100%', height: '100%', objectFit: 'cover', backgroundColor: '#E8E8E8', borderRadius: 5, padding: 5}} src={banner.banner} />                    
                }
                
            </li>
        )
    }

    renderUpdateClassBanners = (banner, idx) => {
        return(
            <li key={idx} style={{display: 'inline-block', verticalAlign: 'top', height: '100%', width: 150, marginRight: 15}}>
                {banner.id == this.state.classSettingsBanner ? 
                    <img draggable={false} onClick={() => {this.setState({classSettingsBanner: banner.id})}} style={{width: '100%', height: '100%', objectFit: 'cover', backgroundColor: '#000', borderRadius: 5, padding: 5}} src={banner.banner} />
                    :
                    <img draggable={false} onClick={() => {this.setState({classSettingsBanner: banner.id})}} style={{width: '100%', height: '100%', objectFit: 'cover', backgroundColor: '#E8E8E8', borderRadius: 5, padding: 5}} src={banner.banner} />                    
                }
                
            </li>
        )
    }    
    renderNewPostImages = (image, idx) => {
        return(
                <li key={idx} style={{display: 'inline-block', verticalAlign: 'top', height: '100%', width: 150, marginRight: 15, position: 'relative'}}>
                    <div style={{width: 20, height: 20, backgroundColor: 'red', borderRadius: 20/2, alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', backgroundColor: '#2E91FC', position: 'absolute', top: -5, right: -5, zIndex: 3}} onClick={() => {
                        let newPostImagesList = [];

                        this.state.newPostImages.forEach((imageItem) => {
                            if(imageItem.imageFileToPush != image.imageFileToPush){
                                newPostImagesList.push({
                                    imageFileName: imageItem.imageFileName, 
                                    imageFileToPush: imageItem.imageFileToPush, 
                                    imageFileTimestamp: imageItem.imageFileTimestamp
                                });
                            }
                        });

                        this.setState({newPostImages: newPostImagesList});

                    }}>
                        <p style={{padding: 0, margin: 0, color: '#FFF', textAlign: 'center', fontWeight: 'bold'}}>-</p>
                    </div>                    
                    <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 5}} src={URL.createObjectURL(image.imageFileToPush)} />                    
                </li>        
        )
    }

    renderNewPostEquations = (equation, idx) => {
        return(
            <li key={idx} style={{position: 'relative', display: 'block', margin: 0, padding: 0, marginBottom: 10}}>
                <div style={{width: 20, height: 20, backgroundColor: 'red', borderRadius: 20/2, alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', backgroundColor: '#2E91FC', position: 'absolute', top: -5, right: -5, zIndex: 3}} onClick={() => {
                    let newEquationsList = [];

                    this.state.newPostEquationsList.forEach((equationItem) => {
                        if(equationItem.equationIndex != equation.equationIndex){
                            newEquationsList.push({
                                equationIndex: equationItem.equationIndex,
                                equationValue: equationItem.equationValue
                            });
                        }
                    });

                    this.setState({newPostEquationsList: newEquationsList});
                }}>
                    <p style={{padding: 0, margin: 0, color: '#FFF', textAlign: 'center', fontWeight: 'bold', marginBottom: 10}}>-</p>
                </div>

                <MathView
                    style={{fontSize: 30, backgroundColor: '#222', padding: 10, borderRadius: 5, color: '#FFF'}}
                    value={equation.equationValue}
                />                                 
                <div style={{width: '100%', height: '100%', backgroundColor: 'transparent', opacity: 0.25, position: 'absolute', top: 0, left: 0}}>
                </div>             
            </li>            
        )
    }
    renderNewPostCodeSnippets = (codeSnippet, idx) => {
        return(
            <li key={idx} style={{position: 'relative', display: 'block', margin: 0, padding: 0, marginBottom: 10, borderRadius: 5}}>
                <div style={{width: 20, height: 20, backgroundColor: 'red', alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', backgroundColor: '#2E91FC', position: 'absolute', top: -5, right: -5, zIndex: 3, borderRadius: 10}} onClick={() => {
                    let newCodeSnippetList = [];

                    this.state.newPostCodeSnippetsList.forEach((codeSnippetItem) => {
                        if(codeSnippetItem.codeSnippetIndex != codeSnippet.codeSnippetIndex){
                            newCodeSnippetList.push({
                                codeSnippetValue: codeSnippetItem.codeSnippetValue,
                                codeSnippetIndex: codeSnippetItem.codeSnippetIndex
                            });
                        }
                    });

                    this.setState({newPostCodeSnippetsList: newCodeSnippetList});
                }}>
                    <p style={{padding: 0, margin: 0, color: '#FFF', textAlign: 'center', fontWeight: 'bold'}}>-</p>
                </div>

                <ReactQuill
                    readOnly={true}
                    modules={this.listModules}
                    theme="snow"
                    
                    style={{fontSize: 60, backgroundColor: '#222', padding: 0, borderRadius: 5, color: '#222'}}
                    value={codeSnippet.codeSnippetValue}
                    onChange={(nothingToChange) => {
                        
                    }}
                />   
                {/* <div style={{width: '100%', height: '100%', backgroundColor: 'transparent', opacity: 0.25, position: 'absolute', top: 0, left: 0}}>
                </div>              */}
            </li>
        );
    }
    renderNewKnowledgeBitImages = (image, idx) => {
        return(
            <li key={idx} style={{display: 'inline-block', position: 'relative', verticalAlign: 'top', height: '100%', width: 150, marginRight: 15}}>
                <div style={{width: 20, height: 20, backgroundColor: 'red', alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', backgroundColor: '#2E91FC', position: 'absolute', top: -5, right: -5, zIndex: 3, borderRadius: 10}} onClick={() => {
                    let newKnowledgeBitImagesList = [];

                    this.state.newKnowledgeBitImages.forEach((imageInList) => {
                        if(image.id != imageInList.id){
                            newKnowledgeBitImagesList.push({
                                id: image.id, 
                                uploadedImageFileToPush: image.uploadedImageFileToPush,
                                uploadedImageFileModTimestamp: image.uploadedImageFileModTimestamp
                            });
                        }
                    });

                    this.setState({newKnowledgeBitImages: newKnowledgeBitImagesList});
                }}>
                    <p style={{padding: 0, margin: 0, color: '#FFF', textAlign: 'center', verticalAlign: 'center', fontWeight: 'bold'}}>-</p>
                </div>                
                <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 5}} src={URL.createObjectURL(image.uploadedImageFileToPush)} />                    
            </li>
        )
    }

    renderNewKnowledgeBitCodeSnippets = (codeSnippet, idx) => {
        return(
            <li key={idx} style={{position: 'relative', display: 'block', margin: 0, padding: 0, marginBottom: 10, borderRadius: 5}}>
                <div style={{width: 20, height: 20, backgroundColor: 'red', alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', backgroundColor: '#2E91FC', position: 'absolute', top: -5, right: -5, zIndex: 3, borderRadius: 10}} onClick={() => {
                    let newCodeSnippetList = [];

                    this.state.newKnowledgeBitCodeSnippetsList.forEach((codeSnippetItem) => {
                        if(codeSnippetItem.codeSnippetIndex != codeSnippet.codeSnippetIndex){
                            newCodeSnippetList.push({
                                codeSnippetValue: codeSnippetItem.codeSnippetValue,
                                codeSnippetIndex: codeSnippetItem.codeSnippetIndex
                            });
                        }
                    });

                    this.setState({newKnowledgeBitCodeSnippetsList: newCodeSnippetList});
                }}>
                    <p style={{padding: 0, margin: 0, color: '#FFF', textAlign: 'center', verticalAlign: 'center', fontWeight: 'bold'}}>-</p>
                </div>

                <ReactQuill
                    readOnly={true}
                    modules={this.listModules}
                    theme="snow"
                    
                    style={{fontSize: 60, backgroundColor: '#222', padding: 0, borderRadius: 5, color: '#222'}}
                    value={codeSnippet.codeSnippetValue}
                    onChange={(nothingToChange) => {
                        
                    }}
                />   
            </li>
        );
    }

    renderNewChildKnowledgeBitImages = (image, idx) => {
        return(
            <li key={idx} style={{display: 'inline-block', position: 'relative', verticalAlign: 'top', height: '100%', width: 150, marginRight: 15}}>
                <div style={{width: 20, height: 20, backgroundColor: 'red', alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', backgroundColor: '#2E91FC', position: 'absolute', top: -5, right: -5, zIndex: 3, borderRadius: 10}} onClick={() => {
                    let newKnowledgeBitImagesList = [];

                    this.state.newChildKnowledgeBitImages.forEach((imageInList) => {
                        if(image.uploadedImageFileToPush != imageInList.uploadedImageFileToPush){
                            newKnowledgeBitImagesList.push({
                                id: image.id, 
                                uploadedImageFileToPush: image.uploadedImageFileToPush,
                                uploadedImageFileModTimestamp: image.uploadedImageFileModTimestamp
                            });
                        }
                    });

                    this.setState({newChildKnowledgeBitImages: newKnowledgeBitImagesList});
                }}>
                    <p style={{padding: 0, margin: 0, color: '#FFF', textAlign: 'center', verticalAlign: 'center', fontWeight: 'bold'}}>-</p>
                </div>                
                <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 5}} src={URL.createObjectURL(image.uploadedImageFileToPush)} />                    
            </li>
        )
    }

    renderNewChildKnowledgeBitCodeSnippets = (codeSnippet, idx) => {
        return(
            <li key={idx} style={{position: 'relative', display: 'block', margin: 0, padding: 0, marginBottom: 10, borderRadius: 5}}>
                <div style={{width: 20, height: 20, backgroundColor: 'red', alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', backgroundColor: '#2E91FC', position: 'absolute', top: -5, right: -5, zIndex: 3, borderRadius: 10}} onClick={() => {
                    let newCodeSnippetList = [];

                    this.state.newChildKnowledgeBitCodeSnippetsList.forEach((codeSnippetItem) => {
                        if(codeSnippetItem.codeSnippetIndex != codeSnippet.codeSnippetIndex){
                            newCodeSnippetList.push({
                                codeSnippetValue: codeSnippetItem.codeSnippetValue,
                                codeSnippetIndex: codeSnippetItem.codeSnippetIndex
                            });
                        }
                    });

                    this.setState({newChildKnowledgeBitCodeSnippetsList: newCodeSnippetList});
                }}>
                    <p style={{padding: 0, margin: 0, color: '#FFF', textAlign: 'center', fontWeight: 'bold'}}>-</p>
                </div>

                <ReactQuill
                    readOnly={true}
                    modules={this.listModules}
                    theme="snow"
                    
                    style={{fontSize: 60, backgroundColor: '#222', padding: 0, borderRadius: 5, color: '#222'}}
                    value={codeSnippet.codeSnippetValue}
                    onChange={(nothingToChange) => {
                        
                    }}
                />   
            </li>
        );
    }    

    renderNewKnowledgeBitEquations = (equation, idx) => {
        return(
            <li key={idx} style={{position: 'relative', display: 'block', margin: 0, padding: 0, marginBottom: 10}}>
                <div style={{width: 20, height: 20, backgroundColor: 'red', borderRadius: 20/2, alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', backgroundColor: '#2E91FC', position: 'absolute', top: -5, right: -5, zIndex: 3}} onClick={() => {
                    let newEquationsList = [];

                    this.state.newKnowledgeBitEquationsList.forEach((equationItem) => {
                        if(equationItem.equationIndex != equation.equationIndex){
                            newEquationsList.push({
                                equationIndex: equationItem.equationIndex,
                                equationValue: equationItem.equationValue
                            });
                        }
                    });

                    this.setState({newKnowledgeBitEquationsList: newEquationsList});
                }}>
                    <p style={{padding: 0, margin: 0, color: '#FFF', textAlign: 'center', fontWeight: 'bold'}}>-</p>
                </div>

                <MathView
                    style={{fontSize: 30, backgroundColor: '#222', padding: 10, borderRadius: 5, color: '#FFF'}}
                    value={equation.equationValue}
                />                                 
                <div style={{width: '100%', height: '100%', backgroundColor: 'transparent', opacity: 0.25, position: 'absolute', top: 0, left: 0}}>
                </div>             
            </li>            
        )        
    }
    
    renderNewChildKnowledgeBitEquations = (equation, idx) => {
        return(
            <li key={idx} style={{position: 'relative', display: 'block', margin: 0, padding: 0, marginBottom: 10}}>
                <div style={{width: 20, height: 20, backgroundColor: 'red', borderRadius: 20/2, alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', backgroundColor: '#2E91FC', position: 'absolute', top: -5, right: -5, zIndex: 3}} onClick={() => {
                    let newEquationsList = [];

                    this.state.newChildKnowledgeBitEquationsList.forEach((equationItem) => {
                        if(equationItem.equationIndex != equation.equationIndex){
                            newEquationsList.push({
                                equationIndex: equationItem.equationIndex,
                                equationValue: equationItem.equationValue
                            });
                        }
                    });

                    this.setState({newChildKnowledgeBitEquationsList: newEquationsList});
                }}>
                    <p style={{padding: 0, margin: 0, color: '#FFF', textAlign: 'center', fontWeight: 'bold'}}>-</p>
                </div>

                <MathView
                    style={{fontSize: 30, backgroundColor: '#222', padding: 10, borderRadius: 5, color: '#FFF'}}
                    value={equation.equationValue}
                />                                 
                <div style={{width: '100%', height: '100%', backgroundColor: 'transparent', opacity: 0.25, position: 'absolute', top: 0, left: 0}}>
                </div>             
            </li>            
        ) 
    }

    renderFocusedPostImages = (image, idx) => {
        return(
            <li key={idx} style={{display: 'inline-block', verticalAlign: 'top', height: '100%', width: 150, marginRight: 15}} onClick={() => {
                this.setState({imageViewerImages: image.imageViewerURLS}, () => {
                    this.setState({imageViewerOpen: true});
                    console.log(this.state.imageViewerImages);
                });
            }}>
                <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 5}} src={image.postImageURL}/>
            </li>
        )
    }

    renderFocusedPostCodeSnippets = (codeSnippet, idx) => {
        return(
            <li key={idx} style={{backgroundColor: '#222', position: 'relative', display: 'block', margin: 0, padding: 0, marginBottom: 10}}>
                <ReactQuill
                    readOnly={true}
                    modules={this.listModules}
                    theme="snow"
                    style={{marginBottom: 15}}
                    value={codeSnippet}
                    onChange={(nothingToChange) => {
                        
                    }}
                />   
            </li>
        ); 
    } 
    
    renderKnowledgeBitCodeSnippets = (codeSnippet, idx) => {
        return(
            <li key={idx} style={{backgroundColor: '#222', position: 'relative', display: 'block', margin: 0, padding: 0, marginBottom: 10}}>
                <ReactQuill
                    readOnly={true}
                    modules={this.listModules}
                    theme="snow"
                    style={{marginBottom: 15}}
                    value={codeSnippet}
                    onChange={(nothingToChange) => {
                        
                    }}
                />   
            </li>
        ); 
    }

    // renderFocusedPostEquations = (equation, idx) => {
    //     return(
    //         <li key={idx} style={{backgroundColor: 'red', margin: 0, padding: 0, marginBottom: 10}}>
    //             <MathView
    //                 style={{fontSize: 35, backgroundColor: '#f1f6fb'}}
    //                 value={"x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}+\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}+\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}+\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}+\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}"}
    //             />                  
    //             {/* <div style={{width: '100%', height: '100%', backgroundColor: 'transparent', opacity: 0.25, position: 'absolute', top: 0, left: 0, overflowX: 'scroll'}}>
    //             </div>              */}
    //         </li> 
    //     ); 
    // }    
    renderFocusedPostEquations = (equation, idx) => {
        return(
            <li key={idx} style={{display: 'inline-block', position: 'relative', height: '100%', width: 'auto', marginRight: 15, backgroundColor: '#222', padding: 10, borderRadius: 5}} onClick={() => {
            }}>
                <p style={{margin: 0, padding: 0, marginBottom: 10, color: '#FFF'}}>{equation.index})</p>
                 <MathView
                    style={{fontSize: 25,  width: 'auto', color: '#FFF'}}
                    value={equation.equationValue}
                /> 
                <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'transparent', zIndex: 4}}>
                </div>               
            </li>
        )
    }     

    renderKnowledgeBitEquations = (equation, idx) => {
        return(
            <li key={idx} style={{display: 'inline-block', position: 'relative', height: 'auto', width: 'auto', marginRight: 15, backgroundColor: '#222', padding: 10, borderRadius: 5}} onClick={() => {
            }}>
                <p style={{margin: 0, padding: 0, marginBottom: 10, color: '#FFF'}}>{equation.index})</p>
                 <MathView
                    style={{fontSize: 25,  width: 'auto', color: '#FFF'}}
                    value={equation.equationValue}
                /> 
                <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'transparent', zIndex: 4}}>
                </div>               
            </li>
        )
    }
    renderKnowledgeBitImages = (image, idx) => {
        return(
            <li key={idx} style={{display: 'inline-block', verticalAlign: 'top', height: '100%', width: 150, marginRight: 15}} onClick={() => {
                this.setState({imageViewerImages: image.imageViewerURLS}, () => {
                    this.setState({imageViewerOpen: true});
                    console.log(this.state.imageViewerImages);
                });                
            }}>
                <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 5}} src={image.parentKnowledgeBitImageURL}/>
            </li>
        )
    }
    renderChildKnowledgeBitImages = (image, idx) => {
        return(
            <li key={idx} style={{display: 'inline-block', verticalAlign: 'top', height: '100%', width: 150, marginRight: 15}} onClick={() => {
                this.setState({imageViewerImages: image.imageViewerURLS}, () => {
                    this.setState({imageViewerOpen: true});
                    console.log(this.state.imageViewerImages);
                });                  
            }}>
                <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 5}} src={image.childKnowledgeBitImageURL}/>
            </li>
        )
    }     
    renderClassSettingsMembers = (member, idx) => {
        return(
            <li key={idx} style={{display: 'flex', flexDirection: 'column', width: '100%', padding: 0, margin: 0, borderBottom: '1px solid #000', marginBottom: 15, paddingBottom: 10}}>
                <p style={{margin: 0, padding: 0, fontWeight: 'bold'}}>{member.memberFName} {member.memberLName}</p>

                {this.state.currentUserRole == 'instructor' ? 
                    <p style={{margin: 0, padding: 0, color: '#AAA'}}>{member.memberID}</p>
                    :
                    null
                }
                
                {this.state.currentUserRole == 'instructor' ? 
                    <div>
                        {this.state.currentUserEmail != member.memberID ? 
                            <Button variant="outlined" color="error" style={{margin: 0, padding: 0, width: '100%', marginTop: 5}} onClick={() => {
                                this.removeStudent(member.memberID);
                            }}> 
                                Remove from Class
                            </Button>    
                            :
                            null                            
                        }
                    </div>

                    :
                    null            
                }
               
                
            </li>
        )
    }
    renderCurrentUserInbox = (inboxItem, idx) => {
        switch (inboxItem.type) {
            case 'newPost':
                return(
                    <li key={idx} style={{margin: 0, padding: 0, marginBottom: 25, position: 'relative'}}>           
                        <Paper sx={{display: 'flex', flexDirection: 'row', padding: 0, margin: 0, padding: 2}}>
                            <div style={{margin: 0, padding: 0, marginRight: 25, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <QuizIcon style={{marginBottom: 10}} fontSize="large"/>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', width: 40, height: 40, backgroundColor: '#f1f6fb', borderRadius: 5}}>
                                    <p style={{marginRight: 1, color: '#b0b0b0'}}>{inboxItem.postCreatorFullName.split(" ")[0][0]}{inboxItem.postCreatorFullName.split(" ")[1][0]}</p>
                                </div>                                  
                            </div>
                            <div style={{margin: 0, padding: 0}}>
                                <Button style={{display: 'flex',  height: 30, marginBottom: 10}} variant="contained">{inboxItem.postTargetClassName}</Button>                                
                                <p style={{margin: 0, padding: 0, marginBottom: 5}}>{inboxItem.postCreatorFullName} posted a new question</p>
                                <p style={{fontWeight: 'bold', padding: 0, margin: 0, marginBottom: 15}}>{inboxItem.postTitle}</p>
                                <p style={{margin: 0, padding: 0, color: '#AAA'}}>{inboxItem.postCreationTimestampFormatted}</p>
                            </div>
                        </Paper>                  
                    </li>
                );
            case 'newClassMember':
                return(
                    <li key={idx} style={{margin: 0, padding: 0, marginBottom: 25, position: 'relative'}}>           
                        <Paper sx={{display: 'flex', flexDirection: 'row', padding: 0, margin: 0, padding: 2}}>
                            <div style={{margin: 0, padding: 0, marginRight: 25, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <PersonAddIcon style={{marginBottom: 10}} fontSize="large"/>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', width: 40, height: 40, backgroundColor: '#f1f6fb', borderRadius: 5}}>
                                    <p style={{marginRight: 1, color: '#b0b0b0'}}>{inboxItem.newMemberFullName.split(" ")[0][0]}{inboxItem.newMemberFullName.split(" ")[1][0]}</p>
                                </div>                                  
                            </div>
                            <div style={{margin: 0, padding: 0}}>
                                <Button style={{display: 'flex',  height: 30, marginBottom: 10}} variant="contained">{inboxItem.newMemberClassName}</Button>                                
                                <p style={{margin: 0, padding: 0, marginBottom: 5}}>{inboxItem.newMemberFullName} joined your class</p>
                                <p style={{fontWeight: 'bold', padding: 0, margin: 0, marginBottom: 15}}>{inboxItem.newMemberClassName}</p>
                                <p style={{margin: 0, padding: 0, color: '#AAA'}}>{inboxItem.newMemberJoinedFormattedTimestamp}</p>
                            </div>
                        </Paper>                  
                    </li>              

                    // <li key={idx} style={{margin: 0, padding: 0, marginBottom: 25, position: 'relative'}}>           
                    //     <Paper sx={{display: 'flex', flexDirection: 'row', padding: 0, margin: 0, padding: 2}}>
                    //         <div style={{margin: 0, padding: 0, marginRight: 15}}>
                    //             <PersonAddIcon/>
                    //         </div>
                    //         <div style={{margin: 0, padding: 0}}>
                    //             <Button style={{display: 'flex', width: 95, height: 30, marginBottom: 5}} variant="contained">{inboxItem.newMemberClassName}</Button>
                    //             <p style={{margin: 0, padding: 0}}>{inboxItem.newMemberFullName} joined your class: {inboxItem.newMemberClassName}</p>
                    //             <p style={{margin: 0, padding: 0, color: '#AAA'}}>{inboxItem.newMemberJoinedFormattedTimestamp}</p>
                    //         </div>
                    //     </Paper>                  
                    // </li>
                );
            case 'newParentKnowledgeBit':
                return(
                    <li key={idx} style={{margin: 0, padding: 0, marginBottom: 25, position: 'relative'}}>           
                        <Paper sx={{display: 'flex', flexDirection: 'row', padding: 0, margin: 0, padding: 2}}>
                            <div style={{margin: 0, padding: 0, marginRight: 25, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <AddCommentIcon style={{marginBottom: 10}} fontSize="large"/>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', width: 40, height: 40, backgroundColor: '#f1f6fb', borderRadius: 5}}>
                                    <p style={{marginRight: 1, color: '#b0b0b0'}}>{inboxItem.newKnowledgeBitCreatorFullName.split(" ")[0][0]}{inboxItem.newKnowledgeBitCreatorFullName.split(" ")[1][0]}</p>
                                </div>                                  
                            </div>
                            <div style={{margin: 0, padding: 0}}>
                                <Button style={{display: 'flex',  height: 30, marginBottom: 10}} variant="contained">{inboxItem.newKnowledgeBitClass}</Button>                                
                                <p style={{margin: 0, padding: 0, marginBottom: 5}}>{inboxItem.newKnowledgeBitCreatorFullName} added a Knowledge Bit to your post, {inboxItem.newKnowledgeBitFocusedPostTitle} </p>
                                <p style={{fontWeight: 'bold', padding: 0, margin: 0, marginBottom: 15}}>{inboxItem.newKnowledgeBitTextContent}</p>
                                <p style={{margin: 0, padding: 0, color: '#AAA'}}>{inboxItem.newKnowledgeBitCreationFormattedTimestamp}</p>
                            </div>
                        </Paper>                  
                    </li>  

                    // <li key={idx} style={{margin: 0, padding: 0, marginBottom: 25, position: 'relative'}}>           
                    //     <Paper sx={{display: 'flex', flexDirection: 'row', padding: 0, margin: 0, padding: 2}}>
                    //         <div style={{margin: 0, padding: 0, marginRight: 15}}>
                    //             <AddCommentIcon/>
                    //         </div>
                    //         <div style={{margin: 0, padding: 0}}>
                    //             <Button style={{display: 'flex', width: 95, height: 30, marginBottom: 5}} variant="contained">{inboxItem.newKnowledgeBitClass}</Button>
                    //             <p style={{margin: 0, padding: 0}}>{inboxItem.newKnowledgeBitCreatorFullName} added a Knowledge Bit to your question {inboxItem.newKnowledgeBitFocusedPostTitle}: {inboxItem.newKnowledgeBitTextContent}</p>
                    //             <p style={{margin: 0, padding: 0, color: '#AAA'}}>{inboxItem.newKnowledgeBitCreationFormattedTimestamp}</p>
                    //         </div>
                    //     </Paper>                  
                    // </li>
                );  
            // send to post creator 
            // case 'newChildKnowledgeBit':
            //     return(
            //         <li key={idx} style={{margin: 0, padding: 0, marginBottom: 25, position: 'relative'}}>           
            //             <Paper sx={{display: 'flex', flexDirection: 'row', padding: 0, margin: 0, padding: 2}}>
            //                 <div style={{margin: 0, padding: 0, marginRight: 15}}>
            //                     <AddCommentIcon/>
            //                 </div>
            //                 <div style={{margin: 0, padding: 0}}>
            //                     <Button style={{display: 'flex', width: 95, height: 30, marginBottom: 5}} variant="contained">{inboxItem.newChildKnowledgeBitClass}</Button>                                
            //                     <p style={{margin: 0, padding: 0}}>{inboxItem.newChildKnowledgeBitCreatorFullName} replied to {inboxItem.newChildKnowledgeBitParentCreatorFullName} inside your post thread {inboxItem.newChildKnowledgeBitFocusedPostTitle}: {inboxItem.newChildKnowledgeBitTextContent}</p>
            //                     <p style={{margin: 0, padding: 0, color: '#AAA'}}>{inboxItem.newChildKnowledgeBitCreationTimestampFinalString}</p>
            //                 </div>
            //             </Paper>                  
            //         </li>
            //     ); 
            case 'newKnowledgeBitReply':
                return(

                    <li key={idx} style={{margin: 0, padding: 0, marginBottom: 25, position: 'relative'}}>           
                        <Paper sx={{display: 'flex', flexDirection: 'row', padding: 0, margin: 0, padding: 2}}>
                            <div style={{margin: 0, padding: 0, marginRight: 25, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <ReplyIcon style={{marginBottom: 10}} fontSize="large"/>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', width: 40, height: 40, backgroundColor: '#f1f6fb', borderRadius: 5}}>
                                    <p style={{marginRight: 1, color: '#b0b0b0'}}>{inboxItem.newReplyKnowledgeBitCreatorFullName.split(" ")[0][0]}{inboxItem.newReplyKnowledgeBitCreatorFullName.split(" ")[1][0]}</p>
                                </div>                                  
                            </div>
                            <div style={{margin: 0, padding: 0}}>
                                <Button style={{display: 'flex',  height: 30, marginBottom: 10}} variant="contained">{inboxItem.newReplyKnowledgeBitClass}</Button>                                
                                <p style={{margin: 0, padding: 0, marginBottom: 5}}>{inboxItem.newReplyKnowledgeBitCreatorFullName} replied to your Knowledge Bit, {inboxItem.newReplyKnowledgeBitParentKnowledgeBitTextContent}</p>
                                <p style={{fontWeight: 'bold', padding: 0, margin: 0, marginBottom: 15}}>{inboxItem.newReplyKnowledgeBitTextContent}</p>
                                <p style={{margin: 0, padding: 0, color: '#AAA'}}>{inboxItem.newReplyKnowledgeBitCreationTimestampFinalString}</p>
                            </div>
                        </Paper>                  
                    </li>                     
                    // <li key={idx} style={{margin: 0, padding: 0, marginBottom: 25, position: 'relative'}}>           
                    //     <Paper sx={{display: 'flex', flexDirection: 'row', padding: 0, margin: 0, padding: 2}}>
                    //         <div style={{margin: 0, padding: 0, marginRight: 15}}>
                    //             <AddCommentIcon/>
                    //         </div>
                    //         <div style={{margin: 0, padding: 0}}>
                    //             <Button style={{display: 'flex', width: 95, height: 30, marginBottom: 5}} variant="contained">{inboxItem.newReplyKnowledgeBitClass}</Button>                                                                
                    //             <p style={{margin: 0, padding: 0}}>{inboxItem.newReplyKnowledgeBitCreatorFullName} replied to your comment {inboxItem.newReplyKnowledgeBitParentKnowledgeBitTextContent}: {inboxItem.newReplyKnowledgeBitTextContent}</p>
                    //             <p style={{margin: 0, padding: 0, color: '#AAA'}}>{inboxItem.newReplyKnowledgeBitCreationTimestampFinalString}</p>
                    //         </div>
                    //     </Paper>                  
                    // </li>
                );   
            case 'newMarkedSolution':
                return(
                    <li key={idx} style={{margin: 0, padding: 0, marginBottom: 25, position: 'relative'}}>           
                        <Paper sx={{display: 'flex', flexDirection: 'row', padding: 0, margin: 0, padding: 2}}>
                            <div style={{margin: 0, padding: 0, marginRight: 25, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <LibraryAddCheckIcon style={{marginBottom: 10}} fontSize="large"/>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', width: 40, height: 40, backgroundColor: '#f1f6fb', borderRadius: 5}}>
                                    <p style={{marginRight: 1, color: '#b0b0b0'}}>{inboxItem.postCreatorName.split(" ")[0][0]}{inboxItem.postCreatorName.split(" ")[1][0]}</p>
                                </div>                                  
                            </div>
                            <div style={{margin: 0, padding: 0}}>
                                <Button style={{display: 'flex',  height: 30, marginBottom: 10}} variant="contained">{inboxItem.postClassName}</Button>                                
                                <p style={{margin: 0, padding: 0, marginBottom: 5}}>{inboxItem.postCreatorName} marked your Knowledge Bit as a solution to their post {inboxItem.postTitle}</p>
                                <p style={{fontWeight: 'bold', padding: 0, margin: 0, marginBottom: 15}}>{inboxItem.solutionKnowledgeBitTextContent}</p>
                                <p style={{margin: 0, padding: 0, color: '#AAA'}}>{inboxItem.newMarkedSolutionTimestampFinalString}</p>
                            </div>
                        </Paper>                  
                    </li> 

                    // <li key={idx} style={{margin: 0, padding: 0, marginBottom: 25, position: 'relative'}}>           
                    //     <Paper sx={{display: 'flex', flexDirection: 'row', padding: 0, margin: 0, padding: 2}}>
                    //         <div style={{margin: 0, padding: 0, marginRight: 15}}>
                    //             <LibraryAddCheckIcon/>
                    //         </div>
                    //         <div style={{margin: 0, padding: 0}}>
                    //             <Button style={{display: 'flex', width: 95, height: 30, marginBottom: 5}} variant="contained">{inboxItem.postClassName}</Button>                                                                                                
                    //             <p style={{margin: 0, padding: 0}}>{inboxItem.postCreatorName} marked your Knowledge Bit as a solution to their question {inboxItem.postTitle}: {inboxItem.solutionKnowledgeBitTextContent}</p>
                    //             <p style={{margin: 0, padding: 0, color: '#AAA'}}>{inboxItem.newMarkedSolutionTimestampFinalString}</p>
                    //         </div>
                    //     </Paper>                  
                    // </li>
                );                                
            default:
                return(
                    <li key={idx} style={{marginBottom: 25, position: 'relative'}}>           
                        <Paper sx={{display: 'flex', flexDirection: 'row', padding: 0, margin: 0}}>
                        </Paper>                  
                    </li>
                );
        }
    }

    handleNewPostTitleChange(event){
        this.setState({newPostTitle: event.target.value});
    }
    handleNewPostTextContentChange(event){
        this.setState({newPostTextContent: event.target.value});
    }
    resetNewModal(){
        this.setState({newModalOpen: false});
        this.setState({newModalView: 'main'});
        this.setState({newPostTargetClass: ''});
        this.setState({newPostTextContent: ""});
        this.setState({newPostTitle: ""});
    }
    handNewKnowledgeBitTextContentChange(event){
        this.setState({newKnowledgeBitTextContent: event.target.value});
    }
    handNewClassNameChange(event){
        this.setState({newClassName: event.target.value});
    }

    componentDidMount(){
        this.onMount();
    }

    DashboardViewController = () => {
        switch (this.state.dashboardView) {
            case 'loading':
                return(
                    <Paper sx={{display: 'flex', flexDirection: 'column', paddingTop: 5, margin: 0, textAlign: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}}>
                        <CircularProgress />
                        <h2>Loading...</h2>
                    </Paper>                    
                );
            case 'classFeed':
                return(
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{position: 'relative', textAlign: 'center', color: 'white', height: 150, borderRadius: 5}}>
                            <div style={{width: 50, height: 50, position: 'absolute', top: 5, right: 5, background: 'transparent', alignContent: 'center', justifyItems: 'center', justifyContent: 'center', alignItems: 'center', textAlign: 'center', zIndex: 5}}>
                                <Button onClick={() => {
                                    navigator.clipboard.writeText("Join " + this.state.focusedClassName + " on Peer to Peer with this ID " + this.state.focusedClass + ". If you're on mobile simply click this link " +  this.state.focusedClassMobileJoinLink);
                                    this.showSnackbar("Copied class invitation to your clipboard")
                                }}>
                                    <ContentCopyIcon style={{fontWeight: 'bold', fontSize: 30, color: '#FFF'}}/>
                                </Button>
                                
                            </div>
                            {/* <this.FocusedClassBanner/> */}
                            {(() => {
                                switch (this.state.focusedClassBanner) {
                                    case 1:
                                        return(
                                            <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', opacity: 1, zIndex: 1, borderRadius: 5}} src={classBanner1} alt="logo" />                    
                                        )
                                    case 2:
                                        return(
                                            <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', opacity: 1, zIndex: 1, borderRadius: 5}} src={classBanner2} alt="logo" />                    
                                        )   
                                    case 3:
                                        return(
                                            <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', opacity: 1, zIndex: 1, borderRadius: 5}} src={classBanner3} alt="logo" />                    
                                        )
                                    case 4:
                                        return(
                                            <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', opacity: 1, zIndex: 1, borderRadius: 5}} src={classBanner4} alt="logo" />                    
                                        ) 
                                    case 5:
                                        return(
                                            <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', opacity: 1, zIndex: 1, borderRadius: 5}} src={classBanner5} alt="logo" />                    
                                        ) 
                                    case 6:
                                        return(
                                            <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', opacity: 1, zIndex: 1, borderRadius: 5}} src={classBanner6} alt="logo" />                    
                                        ) 
                                    case 7:
                                        return(
                                            <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', opacity: 1, zIndex: 1, borderRadius: 5}} src={classBanner7} alt="logo" />                    
                                        ) 
                                    case 8:
                                        return(
                                            <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', opacity: 1, zIndex: 1, borderRadius: 5}} src={classBanner8} alt="logo" />                    
                                        ) 
                                    case 9:
                                        return(
                                            <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', opacity: 1, zIndex: 1, borderRadius: 5}} src={classBanner9} alt="logo" />                    
                                        ) 
                                    case 10:
                                        return(
                                            <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', opacity: 1, zIndex: 1, borderRadius: 5}} src={classBanner10} alt="logo" />                    
                                        ) 
                                    case 11:
                                        return(
                                            <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', opacity: 1, zIndex: 1, borderRadius: 5}} src={classBanner11} alt="logo" />                    
                                        ) 
                                    case 12:
                                        return(
                                            <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', opacity: 1, zIndex: 1, borderRadius: 5}} src={classBanner12} alt="logo" />                    
                                        ) 
                                    case 13:
                                        return(
                                            <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', opacity: 1, zIndex: 1, borderRadius: 5}} src={classBanner13} alt="logo" />                    
                                        )                                                                                                                                                                                                                                                                                                                                                                         
                                    default:
                                        return(
                                            <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', opacity: 1, zIndex: 1, borderRadius: 5}} src={classBanner5} alt="logo" />                                        
                                        )   
                                }                                
                            })()}
                            <div style={{position: 'absolute', width: '100%', height: '100%', top: 0, right: 0, left: 0, bottom: 0, zIndex: 2, backgroundColor: '#1976d2', opacity: 0.4, borderRadius: 5}}>

                            </div>

                            <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 3}}>
                                <h1 style={{fontWeight: 'bold', color: '#FFF', margin: 0}}>{this.state.focusedClassName}</h1>
                                <div style={{display: 'flex', flexDirection: 'row', margin: 0, padding: 0, alignContent: 'center', alignItems: 'center', justifyItems: 'center', justifyContent: 'center'}}>
                                    <p style={{margin: 0, padding: 0, marginRight: 10}}>Questions: {this.state.focusedClassNumPosts}</p>
                                    <p style={{margin: 0, padding: 0, marginRight: 5}}>Solutions: {this.state.focusedClassNumSolvedPosts}</p>
                                    <p style={{margin: 0, padding: 0, marginLeft: 5}}>Peers: {this.state.focusedClassNumStudents}</p>
                                </div>
                            </div>
                        </div>

                        <ul style={{listStyle: 'none', padding: 0, margin: 0, marginTop: 15}}>
                            <FlatList
                                list={this.state.focusedClassFeed}
                                renderItem={this.renderFocusedClassFeed}
                                renderWhenEmpty={() => 
                                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginTop: 0, alignItems: 'center', justifyContent: 'center', height: 100}}>
                                        <h1 style={{margin: 0, padding: 0, color: '#AAA'}}>No Questions Here Yet</h1>
                                    </Paper>    
                                }
                            />
                        </ul>                                           
                    </div>                       
              
                ); 
            case 'post':
                return(
                    <div>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
                            <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 0}}>
                                <div style={{width: '50%', padding: 0, margin: 0}}>
                                    <IconButton style={{margin: 0, padding: 0}} onClick={() => {
                                        this.setState({dashboardView: 'classFeed'});
                                        this.setState({focusedPostID: ''});
                                        this.setState({focusedPostSolved: ''});
                                        this.setState({focusedPostCreator: ''});
                                        this.setState({focusedPostCreatorFName: ''});
                                        this.setState({focusedPostCreatorLName: ''});
                                        this.setState({focusedPostNumKnowledgeBits: 0});
                                        this.setState({focusedPostTitle: ''});
                                        this.setState({focusedPostTextContent: ''});
                                        this.setState({focusedPostCreationTimestamp: ''});
                                        this.setState({focusedPostKnowledgeBits: []});
                                        this.setState({focusedPostEquations: []});
                                        this.setState({focusedPostCodeSnippets: []});
                                    }}>
                                        <KeyboardBackspaceIcon/>
                                    </IconButton>     
                                </div>
                                <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                    {this.state.currentUserRole == 'instructor' ?
                                        <div>
                                            <Button style={{padding: 0, margin: 0}} onClick={() => {
                                                (async () => {
                                                    await updateDoc(doc(this.state.db, "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID), {
                                                        hidden: true
                                                    }).then(() => {
                                                        this.getClass2();
                                                        this.showSnackbar("Question will be hidden from class feed");
                                                    }).catch((error) => {});
                                                })().catch((error) => {});                            
                                            }}>
                                                <VisibilityOffIcon style={{color: '#000'}}/>
                                            </Button>                                                                     
                                        </div>
                                        :
                                        <div>
                                            {this.state.currentUserEmail == this.state.focusedPostCreator ?
                                                <div>
                                                    <Button style={{padding: 0, margin: 0}} onClick={() => {
                                                        (async () => {
                                                            await updateDoc(doc(this.state.db, "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID), {
                                                                hidden: true
                                                            }).then(() => {
                                                                this.getClass2();
                                                                this.showSnackbar("Question will be hidden from class feed");
                                                            }).catch((error) => {});
                                                        })().catch((error) => {});                            
                                                    }}>
                                                        <VisibilityOffIcon style={{color: '#000'}}/>
                                                    </Button>                                            
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    }
                                    {/* <IconButton style={{margin: 0, padding: 0}} onClick={() => {
                                        this.setState({focusedPostID: this.state.focusedPostID}, this.getPost3)
                                    }}>
                                        <ReplayIcon style={{color: '#000'}}/>
                                    </IconButton>                                 */}
                                </div>                            
                            </div>   
                                                
                            <h1 style={{margin: 0, marginTop: 10, padding: 0, marginBottom: 5}}>{this.state.focusedPostTitle}</h1>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 15}}>
                                {this.state.focusedPostCreatorProfilePicture != "" ? 
                                    <div style={{marginRight: 15, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <img draggable={false} style={{width: 40, height: 40, borderRadius: 5, objectFit: 'cover'}} src={this.state.focusedPostCreatorProfilePicture} alt="logo" />
                                    </div>                                  
                                    :
                                    <div style={{marginRight: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', width: 40, height: 40, backgroundColor: '#f1f6fb', borderRadius: 5}}>
                                        <p style={{marginRight: 1, color: '#b0b0b0'}}>{this.state.focusedPostCreatorFName[0]}</p>
                                        <p style={{color: '#b0b0b0'}}>{this.state.focusedPostCreatorLName[0]}</p>
                                    </div>                                       
                                }
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <Typography style={{color: '#AAA'}}>
                                        {this.state.focusedPostCreatorFName}
                                        {" "}
                                        {this.state.focusedPostCreatorLName}
                                    </Typography>                                        
                                    <p style={{margin: 0, padding: 0, color: '#AAA'}}>{this.state.focusedPostCreationTimestamp}</p>  
                                </div>                                                              
                                                 
                            </div>

                            <p style={{margin: 0, padding: 0, fontSize: 17, marginBottom: 15}}>{this.state.focusedPostTextContent}</p>
                            
                            {this.state.focusedPostImages.length > 0 ? 
                                    <ul style={{ width: '100%', display: 'inline-block', overflow: 'auto', maxWidth: '100%', whiteSpace: 'nowrap', padding: 0, height: 200, marginBottom: 15}}>
                                        <FlatList
                                            style={{margin: 0, padding: 0,}}
                                            list={this.state.focusedPostImages}
                                            renderItem={this.renderFocusedPostImages}
                                            renderWhenEmpty={() => <div>No Children</div>}                                    
                                        />
                                    </ul>                                    
                                :
                                    null
                            }
                            
                            {this.state.focusedPostEquations.length > 0 ? 
                                    <ul style={{ width: '100%', display: 'inline-block', overflow: 'auto', maxWidth: '100%', whiteSpace: 'nowrap', paddingTop: 15, paddingBottom: 15, paddingRight: 0, paddingLeft: 0, height: 'auto', backgroundColor: '#FFF', verticalAlign: 'center', alignItems: 'center', borderRadius: 5, marginBottom: 15}}>
                                        <p style={{margin: 0, padding: 0, fontSize: 17, color: '#000', fontWeight: '600', marginBottom: 10}}>Added Equations ({this.state.focusedPostEquations.length})</p>
                                        <FlatList
                                            style={{margin: 0, padding: 0}}
                                            list={this.state.focusedPostEquations}
                                            renderItem={this.renderFocusedPostEquations}
                                            renderWhenEmpty={() => <div>No Children</div>}                                    
                                        />
                                    </ul>                                    
                                :
                                    null
                            } 

                            {(() => {
                                if(this.state.focusedPostCodeSnippets.length > 0){
                                    if(this.state.focusedPostCodeSnippetsOpen == true){
                                        return(
                                            <Button style={{marginBottom: 15}} variant="outlined" onClick={() => {
                                                this.setState({focusedPostCodeSnippetsOpen: !this.state.focusedPostCodeSnippetsOpen});
                                            }}>
                                                Hide Attached Code Snippets ({this.state.focusedPostCodeSnippets.length}) 
                                            </Button>   
                                        );
                                    }else{
                                        return(
                                            <Button style={{marginBottom: 15}} variant="outlined" onClick={() => {
                                                this.setState({focusedPostCodeSnippetsOpen: !this.state.focusedPostCodeSnippetsOpen});
                                            }}>
                                                Show Attached Code Snippets ({this.state.focusedPostCodeSnippets.length}) 
                                            </Button>   
                                        );
                                    }
                                }
                            })()} 

                            {this.state.focusedPostCodeSnippetsOpen == true ? 
                                <ul style={{listStyle: 'none', margin: 0, padding: 0, marginBottom: 15}}>
                                    <FlatList
                                        list={this.state.focusedPostCodeSnippets}
                                        renderItem={this.renderFocusedPostCodeSnippets}
                                        renderWhenEmpty={() => 
                                            <div/>
                                        }
                                    />  
                                </ul>                                              
                                
                                :
                                null
                            }                                                       

                            <Button style={{marginBottom: 15}} variant="outlined" onClick={() => {
                                this.setState({newKnowledgeBitModalOpen: true})
                                this.setState({newKnowledgeBitTextContent: ''});
                            }}>
                                + Add Knowledge Bit
                            </Button>

                            {this.state.focusedPostSolutions.length > 0 ? 
                                <Button style={{display: 'flex', marginBottom: 15}} variant="contained" onClick={() => {
                                    this.setState({viewSolutionsModalOpen: true});
                                    (async () => {
                                        let focusedPostSolutionsList = [];

                                        for(const postSolution of this.state.focusedPostSolutions){
                                            (async () => {
                                                await getDoc(doc(this.state.db, "Classes/" + this.state.focusedClass + "/Feed/" + this.state.focusedPostID + "/KnowledgeBits/" + postSolution)).then((postSolutionDoc) => {
                                                    (async () => {                                                    
                                                        await getDoc(doc(this.state.db, "Users/" + postSolutionDoc.data()['creator'])).then((solutionCreatorDoc) => {
                                                            let solutionCreatorProfilePicture = "";
                                                            if(solutionCreatorDoc.data()['profilePicture'] != ""){
                                                                (async () => {
                                                                    const solutionCreatorProfilePictureRef = ref(this.state.storage, "ProfileBanners/" + solutionCreatorDoc.data()['profilePicture']);
                                                                    await getDownloadURL(solutionCreatorProfilePictureRef).then((url) => {
                                                                        solutionCreatorProfilePicture = url
                                                                    }).catch((error) => {}); 
                                                                })().catch((error) => {});
                                                            }
                                                            let solutionImagesList = [];
        
                                                            for(const solutionImage of postSolutionDoc.data()['images']){
                                                                (async () => {
                                                                    const solutionImageRef = ref(this.state.storage, "KnowledgeBitsImages/" + solutionImage);
                                                                    await getDownloadURL(solutionImageRef).then((url) => {
                                                                        solutionImagesList.push({
                                                                            solutionImage: url,
                                                                        });
                                                                    }).catch((error) => {}); 
                                                                })().catch((error) => {});                                                        
                                                            }

                                                            let solutionCreationTimestampFinalString = "";
                                                            let solutionCreationTimestampArray = postSolutionDoc.data()['creationTimestamp'].toDate().toString().split(" ");
                                                            let solutionCreationTimestampPeriod = "pm";
                                                            let solutionCreationTimestampHour = solutionCreationTimestampArray[4].split(":")[0];
                                                            let solutionCreationTimestampMinute = solutionCreationTimestampArray[4].split(":")[1]
                                                            if(solutionCreationTimestampHour > 12){
                                                                solutionCreationTimestampHour = solutionCreationTimestampHour - 12;
                                                                if(solutionCreationTimestampHour >= 12){
                                                                    solutionCreationTimestampPeriod = "pm";
                                                                }
                                                            }else{
                                                                if(solutionCreationTimestampHour == "00"){
                                                                    solutionCreationTimestampHour = "12"    
                                                                }else{
                                                                    solutionCreationTimestampHour = solutionCreationTimestampHour;
                                                                }
                                                                solutionCreationTimestampPeriod = "am";
                                                            }
                                                            solutionCreationTimestampFinalString = solutionCreationTimestampArray[1] + " " + solutionCreationTimestampArray[2] + " " + solutionCreationTimestampHour + ":" + solutionCreationTimestampMinute + solutionCreationTimestampPeriod;                                                                            

                                                            let equationListToPush = [];
                                                            let index = 1;
                                                            postSolutionDoc.data()['equations'].forEach((equation) => {
                                                                equationListToPush.push({
                                                                    index: index,
                                                                    equationValue: equation
                                                                })
                                                                index = index + 1;
                                                            });

                                                            focusedPostSolutionsList.push({
                                                                solutionID: postSolutionDoc.id,
                                                                solutionTextContent: postSolutionDoc.data()['textContent'],
                                                                solutionCreatorFullName: postSolutionDoc.data()['creatorFullName'],
                                                                solutionCreationTimestamp: solutionCreationTimestampFinalString,
                                                                solutionCreatorProfilePicture: solutionCreatorProfilePicture,
                                                                solutionImages: solutionImagesList,
                                                                solutionCodeSnippets: postSolutionDoc.data()['codeSnippets'],
                                                                solutionEquations: equationListToPush

                                                            });
                                                            this.setState({viewSolutionsModalFullData: focusedPostSolutionsList})
                                                        }).catch((error) => {});
                                                    })().catch((error) => {});
                                                }).catch((error) => {});
                                            })().catch((error) => {});
                                        }
                                        setTimeout(() => {
                                            this.setState({viewSolutionsModalView: 'list'});
                                        }, 1000);
                                    })().catch((error) => {});
                                }}>
                                    View Solutions ({this.state.focusedPostSolutions.length})
                                </Button>
                                :
                                null
                            }                            

                        </Paper>   
                        {this.state.focusedPostNumKnowledgeBits > 0 ? 
                            <div>
                                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 0, marginTop: 25}}>
                                    <div style={{width: '50%', padding: 0, margin: 0}}>
                                        <h2 style={{margin: 0}}>Knowledge Bits ({this.state.focusedPostNumKnowledgeBits})</h2>
                                    </div>
                                    <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                    </div>                            
                                </div>                                            
                                <ul style={{listStyleType: 'none', listStyle: 'none', margin: 0, padding: 0, marginTop: 20}}>
                                    <FlatList
                                        list={this.state.focusedPostKnowledgeBits}
                                        renderItem={this.renderFocusedPostKnowledgeBits}
                                        renderWhenEmpty={() => <div>No Knowledge Bits</div>}
                                    />
                                </ul>  
                            </div>
                        :
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginTop: 0, alignItems: 'center', justifyContent: 'center', height: 100, marginTop: 2.5}}>
                                <h1 style={{margin: 0, padding: 0, color: '#AAA'}}>No Knowledge Bits Here Yet</h1>
                            </Paper>                         
                        
                        }                         
                    </div>
                );
            case 'inbox':
                return(
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{margin: 0, padding: 0, display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 15}}>
                            <div style={{margin: 0, padding: 0, width: '70%'}}>
                                <h1 style={{margin: 0, padding: 0}}>Inbox</h1>
                            </div>                            
                            <div style={{margin: 0, padding: 0, width: '30%'}}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.inboxFilter}
                                            label="Filter"
                                            onChange={(filterUpdate) => {
                                                this.setState({inboxFilter: filterUpdate.target.value}, () => {
                                                    if(this.state.inboxFilter == 'all'){
                                                        this.getInbox('all')
                                                    }else if(this.state.inboxFilter == 'new'){
                                                        this.getInbox('new')
                                                    }
                                                });
                                            }}
                                        >
                                            <MenuItem value={'all'}>All</MenuItem>
                                            <MenuItem value={'new'}>New</MenuItem>
                                        </Select>
                                </FormControl>                                                                
                            </div>
                        </div>
                        <ul style={{listStyle: 'none', padding: 0, margin: 0, marginTop: 15}}>
                            <FlatList
                                list={this.state.currentUserInbox}
                                renderItem={this.renderCurrentUserInbox}
                                renderWhenEmpty={() => 
                                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginTop: 0, alignItems: 'center', justifyContent: 'center', height: 100}}>
                                        <h1 style={{margin: 0, padding: 0, color: '#AAA'}}>Nothing New In Your Inbox</h1>
                                    </Paper>    
                                }
                            />
                        </ul>                                           
                    </div>                     
                );
            case 'currentUserProfile':
                return(
                    <div>
                    <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', position: 'relative',}}>
                        {this.state.currentUserBanner != "" ? 
                            <div style={{width: '100%', height: 200}}>
                                {this.state.accountSettingsBannerUpdate == true ?
                                    <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover'}} src={URL.createObjectURL(this.state.currentUserBanner)}/>   
                                    :
                                    <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover'}} src={this.state.currentUserBanner}/>   
                                }
                            </div>      
                        :
                            <div style={{width: '100%', height: 200, borderTopLeftRadius: 5, borderTopRightRadius: 5}}>
                                <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', borderTopLeftRadius: 5, borderTopRightRadius: 5}} src={ProfileBannerPlaceholder}/>   
                            </div>                            
                        }
                        <div style={{width: '100%', height: 'auto', display: 'flex',  flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: 15}}>
                            {this.state.currentUserProfilePicture != "" ?
                                <div>
                                    {this.state.accountSettingsProfilePictureUpdate == true ? 
                                        // <img draggable={false} src={URL.createObjectURL(this.state.currentUserProfilePicture)} style={{height: 200, width: 200, borderRadius: 10, border: '5px solid #000', alignSelf: 'center', marginBottom: 15}}/>                        
                                        <img draggable={false} src={URL.createObjectURL(this.state.currentUserProfilePicture)} style={{height: 200, width: 200, marginTop: -100, borderRadius: 10, border: '5px solid #FFF', objectFit: 'cover'}}/>
                                        :
                                        <img draggable={false} src={this.state.currentUserProfilePicture} style={{height: 200, width: 200, marginTop: -100, borderRadius: 10, border: '5px solid #FFF', objectFit: 'cover'}}/>
                                    }
                                </div>   
                                :
                                <div style={{height: 200, width: 200, marginTop: -100, borderRadius: 10, border: '5px solid #FFF', backgroundColor: '#1976d2', display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center', justifyItems: 'center'}}>
                                    <h1 style={{padding: 0, margin: 0, marginRight: 5, color: '#FFF', fontSize: 100}}>{this.state.currentUserFirstName[0]}</h1>
                                    <h1 style={{padding: 0, margin: 0, color: '#FFF', fontSize: 100}}>{this.state.currentUserLastName[0]}</h1>
                                </div>

                            }    

                            

                            <IconButton style={{position: 'absolute', top: 210, right: 10}} onClick={() => {
                                this.setState({accountSettingsModalOpen: true})
                            }}>
                                <SettingsIcon/>
                            </IconButton>
                            <h1 style={{padding: 0, margin: 0}}>{this.state.currentUserFirstName} {this.state.currentUserLastName}</h1>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 10}}> 
                                    <p style={{padding: 0, margin: 0}}>Classes</p>
                                    <p style={{padding: 0, margin: 0}}>{this.state.currentUserClasses.length}</p>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 10, marginRight: 10}}>
                                    <p style={{padding: 0, margin: 0}}>Questions</p>
                                    <p style={{padding: 0, margin: 0}}>{this.state.currentUserQuestions.length}</p>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 10}}>
                                    <p style={{padding: 0, margin: 0}}>Solutions</p>
                                    <p style={{padding: 0, margin: 0}}>{this.state.currentUserSolutions.length}</p>
                                </div>
                            </div> 

                            <div style={{width: '100%', height: 'auto', paddingRight: 15, paddingLeft: 15, marginTop: 15, backgroundColor: '#FFF'}}>
                                <h3 style={{padding: 0, margin: 0, paddingLeft: 5, marginBottom: 15}}>Your Classes</h3>
                                <ul style={{display: 'inline-block', padding: 0, margin: 0, width: '100%', height: 'auto', backgroundColor: '#FFF', marginBottom: 35, overflowX: 'scroll', whiteSpace: 'nowrap', paddingTop: 10, paddingBottom: 10, paddingLeft: 5}}>
                                    <FlatList
                                        style={{margin: 0, padding: 0}}
                                        list={this.state.currentUserClasses}
                                        renderItem={this.renderCurrentUserProfileClasses}
                                        renderWhenEmpty={() => 
                                            <div style={{width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f2f2f2', margin: 0, padding: 0, borderRadius: 5}}>
                                                {this.state.currentUserRole == 'instructor' ? 
                                                    <h1 style={{margin: 0, padding: 0, color: '#AAA'}}>You have not created any classes</h1>
                                                    :
                                                    <h1 style={{margin: 0, padding: 0, color: '#AAA'}}>You have not added any classes</h1>
                                                }
                                            </div>
                                        }                                    
                                    />
                                </ul>
                                <h3 style={{padding: 0, margin: 0, paddingLeft: 5, marginBottom: 15}}>Your Questions</h3>
                                <ul style={{display: 'inline-block', padding: 0, margin: 0, width: '100%', height: 'auto', backgroundColor: '#FFF', overflowX: 'scroll', whiteSpace: 'nowrap', paddingTop: 10, paddingBottom: 10, paddingLeft: 5, marginBottom: 35}}>
                                    <FlatList
                                        style={{margin: 0, padding: 0}}
                                        list={this.state.currentUserQuestions}
                                        renderItem={this.renderCurrentUserProfileQuestions}
                                        renderWhenEmpty={() => 
                                            <div style={{width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f2f2f2', margin: 0, padding: 0, borderRadius: 5}}>
                                                <h1 style={{margin: 0, padding: 0, color: '#AAA'}}>You have not asked any questions</h1>
                                            </div>
                                        }                                    
                                    />
                                </ul>                                
                                <h3 style={{padding: 0, margin: 0, paddingLeft: 5, marginBottom: 15}}>Your Solutions</h3>
                                <ul style={{display: 'inline-block', padding: 0, margin: 0, width: '100%', height: 'auto', backgroundColor: '#FFF', overflowX: 'scroll', whiteSpace: 'nowrap', paddingTop: 10, paddingBottom: 10, paddingLeft: 5}}>
                                    <FlatList
                                        style={{margin: 0, padding: 0}}
                                        list={this.state.currentUserSolutions}
                                        renderItem={this.renderCurrentUserSolutions}
                                        renderWhenEmpty={() => 
                                            <div style={{width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f2f2f2', margin: 0, padding: 0, borderRadius: 5}}>
                                                <h1 style={{margin: 0, padding: 0, color: '#AAA'}}>You have not solved any questions</h1>
                                            </div>
                                        }                                    
                                    />
                                </ul>    
                            </div>    
                        </div>

                    </Paper> 
                    {/* <p style={{textAlign: 'center', color: '#AAA'}}>Need Help? Visit our <a style={{color: '#2E91FC'}} href="/helpCenter" target={"_blank"}>Help Center</a></p>                                           */}
                    </div>    
                    
                    
                 
                );                
            case 'error':
                return(
                    <Paper sx={{p: 2, display: 'flex', flexDirection: 'column', paddingTop: 5, margin: 0, textAlign: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}}>
                        <h1 style={{margin: 0, padding: 0, color: '#AAA', marginBottom: 15}}>There was an error getting this page.</h1>
                        <Button style={{width: '100%'}} variant="contained" onClick={() => {this.state.auth.signOut().then(() => {console.log("Signed out user")}).catch((error) => {console.log(error)})}}>Sign Out</Button>                                                                                                    
                    </Paper>                                      
                );
            default:
                return(
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
                        <p>loading...</p>                     
                    </Paper>                    
                );                
        }

    }

    FocusedClassBanner = () => {
        switch (this.state.focusedClassBanner) {
            case 1:
                return(
                    <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', opacity: 1, zIndex: 1, borderRadius: 5}} src={banner1} alt="logo" />                    
                )
            case 2:
                return(
                    <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', opacity: 1, zIndex: 1, borderRadius: 5}} src={banner2} alt="logo" />                    
                )   
            case 3:
                return(
                    <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', opacity: 1, zIndex: 1, borderRadius: 5}} src={banner3} alt="logo" />                    
                )
            case 4:
                return(
                    <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', opacity: 1, zIndex: 1, borderRadius: 5}} src={banner4} alt="logo" />                    
                ) 
            default:
                return(
                    <img draggable={false} style={{width: '100%', height: '100%', objectFit: 'cover', opacity: 1, zIndex: 1, borderRadius: 5}} src={banner1} alt="logo" />                                        
                )   
        }                                         
    }   

    NewModalView = () => {
        switch (this.state.newModalView) {
            case 'loading':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 5, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}}>
                        <CircularProgress />
                        <h2>Loading</h2>
                    </Box> 
                );
            case 'main':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '50%', padding: 0, margin: 0}}>
                                <h2 style={{margin: 0}}>New</h2>
                            </div>
                            <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                <IconButton onClick={() => {
                                    this.setState({newModalOpen: false});
                                }}>
                                    <CloseIcon/>
                                </IconButton>                                
                            </div>                            
                        </div>

                        <Button onClick={() => {this.setState({newModalView: 'newPost'})}} style={{marginBottom: 15}} variant="outlined">Ask a question</Button>                        

                        {this.state.currentUserRole == 'instructor' ? 
                            <Button onClick={() => {this.setState({newModalView: 'newClass'})}} variant="outlined">Create Class</Button>                         
                        : 
                            <Button onClick={() => {this.setState({newModalView: 'addClass'})}} variant="outlined">Add Class</Button>                                                
                        }

                        
                    </Box>                 
                ); 
            case 'addClass':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, p: 4}}>
                        
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '50%', padding: 0, margin: 0}}>
                                <h2 style={{margin: 0}}>Add Class</h2>
                            </div>
                            <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                <IconButton onClick={() => {
                                    this.setState({newModalOpen: false});
                                    this.setState({newModalView: 'main'})
                                    this.setState({addClassError: ''});
                                    this.setState({addClassID: ''});
                                }}>
                                    <CloseIcon/>
                                </IconButton>                                
                            </div>                            
                        </div>

                        <TextField style={{marginBottom: 10}} id="filled-basic" label="Class ID" value={this.state.addClassID} onChange={(text) => {this.setState({addClassID: text.target.value})}} variant="filled" />                                                                        

                        {this.state.addClassError != "" ? 
                            <p style={{margin: 0, padding: 0, color: 'red', marginTop: 5, marginBottom: 5}}>{this.state.addClassError}</p>
                            :
                            null
                        }

                        {this.state.addClassID.trim() != "" ? 
                            <Button onClick={() => {
                                this.addNewClass();
                            }} 
                            variant="contained">Add Class</Button>                                                                                                
                            :
                            <Button disabled="true" variant="contained">Add Class</Button>                                                                                                    
                        }

                    </Box>                  
                ); 
            case 'newClass':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '50%', padding: 0, margin: 0}}>
                                <h2 style={{margin: 0}}>Create Class</h2>
                            </div>
                            <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                <IconButton onClick={() => {
                                    this.setState({newModalOpen: false});
                                    this.setState({newModalView: "main"});
                                    this.setState({newClassName: ""});
                                    this.setState({newClassID: ""});
                                    this.setState({newClassBannerID: ""});
                                    this.setState({newClassError: ""});
                                }}>
                                    <CloseIcon/>
                                </IconButton>                                
                            </div>                            
                        </div>

                        <TextField style={{marginBottom: 10}} id="filled-basic" label="Name" autoComplete="false" autoCorrect="false" autoFocus="true" value={this.state.newClassName} onChange={(text) => {this.setState({newClassName: text.target.value})}} variant="filled" />                        
                        
                        <h3 style={{margin: 0, padding: 0, marginTop: 10}}>Banner</h3>

                        <ul style={{marginTop: 5, width: '100%', display: 'inline-block', overflow: 'auto', maxWidth: '100%', whiteSpace: 'nowrap', padding: 0, height: 200}}>
                            <FlatList
                                list={this.state.newClassBanners}
                                renderItem={this.renderNewClassBanners}
                                renderWhenEmpty={() => <div/>}
                            />
                        </ul>       

                        {this.state.newClassError != "" ? 
                            <p style={{marginTop: 5, marginBottom: 0, color: 'red'}}>{this.state.newClassError}</p>
                        :   
                            null
                        } 

                        {this.state.newClassName.trim().length > 0 && this.state.newClassBannerID != '' ? 
                            <Button style={{marginTop: 15}} variant="contained" onClick={() => {
                                this.setState({newModalView: 'loading'}, () => {
                                    this.createNewClass();    
                                })
                                
                            }}>
                                <p style={{margin: 0, padding: 0, paddingTop: 5}}>Create</p>
                            </Button>                            
                        : 
                            <Button style={{marginTop: 15}} disabled="true" variant="contained">
                                <p style={{margin: 0, padding: 0, paddingTop: 5}}>Create</p>
                            </Button>                                                
                        }
                    </Box>                                    
                );
            case 'newPost':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>        
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '50%', padding: 0, margin: 0}}>
                                <h2 style={{margin: 0}}>Create Post</h2>
                            </div>
                            <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                <IconButton onClick={() => {
                                    this.setState({newModalOpen: false});
                                    this.setState({newModalView: 'main'});
                                    this.setState({newPostTitle: ''});
                                    this.setState({newPostTextContent: ''});
                                    this.setState({newPostTargetClass: ''});
                                    this.setState({newPostError: ''});
                                    this.setState({newPostImages: []});
                                }}>
                                    <CloseIcon/>
                                </IconButton>                                
                            </div>                            
                        </div>  

                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: 500, overflowY: 'scroll', paddingRight: 10}}>
                            {this.state.currentUserClasses.length > 0 ? 
                                <FormControl variant="filled" style={{margin: 0, padding: 0, marginBottom: 15, width: '100%'}}>
                                    <InputLabel id="demo-simple-select-filled-label">Target Class</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-filled-label"
                                    style={{margin: 0}}
                                    id="demo-simple-select-filled"
                                    value={this.state.newPostTargetClass}
                                    onChange={(value) => {
                                        this.setState({newPostTargetClass: value.target.value});

                                        (async () => {
                                            await getDoc(doc(this.state.db, "Classes/" + value.target.value)).then((targetClassDoc) => {
                                                if(targetClassDoc.data()['postingEnabled'] == true){
                                                    this.setState({newPostTargetClassPostingEnabled: true});
                                                }else{
                                                    this.setState({newPostTargetClassPostingEnabled: false});
                                                }
                                            }).catch((error) => {alert(error)});
                                        })().catch((error) => {
                                            alert(error);
                                        })
                                        
                                    }}
                                    >
                                        {this.state.currentUserClasses?.map(classOption => {
                                            return(
                                                <MenuItem value={classOption.classID}>{classOption.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>            
                                :
                                <div style={{width: '100%', height: 75, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f2f2f2', margin: 0, padding: 0, borderRadius: 5, marginBottom: 15}}>
                                    <h2 style={{margin: 0, padding: 0, color: '#AAA'}}>Join a class to ask a question</h2>
                                </div>
                            }

                            <TextField style={{marginBottom: 15}} id="filled-basic" label="Title" value={this.state.newPostTitle} onChange={(text) => {this.setState({newPostTitle: text.target.value})}} variant="filled" />                                            

                            <TextField style={{marginBottom: 15}} id="filled-basic" label="Text Content" value={this.state.newPostTextContent} onChange={(text) => {this.setState({newPostTextContent: text.target.value})}} variant="filled" />                                              

                            <input
                                id="newPostImageInput"
                                style={{display: 'none'}}
                                type="file"
                                accept="image/*"
                                name="myImage"
                                onChange={(event) => {
                                    let currentPostImages = this.state.newPostImages
                                    currentPostImages.push(
                                        {
                                            imageFileName: event.target.files[0].name, 
                                            imageFileToPush: event.target.files[0], 
                                            imageFileTimestamp: event.target.files[0].lastModified
                                        }
                                    )
                                    this.setState({newPostImages: currentPostImages})
                                }}
                            />
                            <Box style={{marginBottom: 20}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Button variant="outlined" style={{width: '100%', height: 50}} onClick={() => {
                                            this.setState({newModalView: 'newPostCodeSnippet'});                                        
                                        }}>
                                            + Code Snippet
                                        </Button>
                                    {/* <Item>xs=8</Item> */}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button variant="outlined" style={{width: '100%', height: 50}} onClick={() => {
                                            document.getElementById("newPostImageInput").click();                                    
                                        }}>
                                            + Image
                                        </Button>                                    
                                    {/* <Item>xs=8</Item> */}
                                    </Grid>    
                                    <Grid item xs={4}>
                                        <Button variant="outlined" style={{width: '100%', height: 50}} onClick={() => {
                                            this.setState({newModalView: 'newPostEquation'});                                        
                                        }}>
                                           + Equation
                                        </Button>                                    
                                    {/* <Item>xs=8</Item> */}
                                    </Grid>              
                                </Grid>
                            </Box>

                            {this.state.newPostCodeSnippetsList.length > 0 ?
                                <div style={{padding: 0}}>
                                    <ul style={{listStyle: 'none', margin: 0, padding: 0, marginBottom: 10}}>
                                        <FlatList
                                            style={{padding: 0}}
                                            list={this.state.newPostCodeSnippetsList}
                                            renderItem={this.renderNewPostCodeSnippets}
                                            renderWhenEmpty={() => 
                                                <div/>
                                            }
                                        />  
                                    </ul>
                                </div>
                                :
                                null
                            }   

                            {this.state.newPostEquationsList.length > 0 ?
                                <div>
                                    <ul style={{listStyle: 'none', margin: 0, padding: 0, marginBottom: 10}}>
                                        <FlatList
                                            list={this.state.newPostEquationsList}
                                            renderItem={this.renderNewPostEquations}
                                            renderWhenEmpty={() => 
                                                <div/>
                                            }
                                        />  
                                    </ul>
                                </div>                                
                                :
                                null
                            }

                            {this.state.newPostImages.length > 0 ?
                                <div>
                                    <ul style={{width: '100%', display: 'inline-block', maxWidth: '100%', whiteSpace: 'nowrap', padding: 0, height: 200}}>                                
                                        <FlatList
                                            list={this.state.newPostImages}
                                            renderItem={this.renderNewPostImages}
                                            renderWhenEmpty={() => 
                                                <div/>
                                            }
                                        />
                                    </ul>                                    
                                </div>
                                :
                                null
                            }

                            {this.state.newPostError != "" ? 
                                <p style={{marginTop: 10, marginBottom: 0, color: 'red'}}>{this.state.newPostError}</p>
                            :   
                                null
                            } 

                            {this.state.newPostTargetClass != "" ?
                                <div style={{width: '100%'}}>
                                    {this.state.newPostTargetClassPostingEnabled == true ? 
                                        <div style={{width: '100%'}}>
                                            {this.state.newPostTitle.trim().length != 0 && this.state.newPostTextContent.trim().length != 0 ? 
                                                <Button style={{marginTop: 0, width: '100%'}} variant="contained" onClick={() => {
                                                    this.setState({newModalView: 'loading'}, () => {
                                                        this.uploadNewPost()
                                                    });
                                                    
                                                }}>
                                                    <p style={{margin: 0, padding: 0, paddingTop: 5}}>Upload</p>
                                                </Button>                            
                                            : 
                                                <Button style={{marginTop: 0, width: '100%'}} disabled="true" variant="contained">
                                                    <p style={{margin: 0, padding: 0, paddingTop: 5}}>Upload</p>
                                                </Button>                                                
                                            }                                             
                                        </div>
                                        :
                                        <Button style={{marginTop: 0, width: '100%'}} disabled="true" variant="contained">
                                            <p style={{margin: 0, padding: 0, paddingTop: 5}}>Posting is Currently Disabled for this Class</p>
                                        </Button>                                           
                                    }
                                                                      
                                </div>
                                :
                                <Button style={{marginTop: 0, width: '100%'}} disabled="true" variant="contained">
                                    <p style={{margin: 0, padding: 0, paddingTop: 5}}>Upload</p>
                                </Button>                                       
                            }                          

                            {/* <Button>
                                <p>ADD image</p>
                            </Button>

                            <Button>
                                <p>ADD file</p>
                            </Button>

                            <Button onClick={() => {
                                this.setState({newModalView: 'newPostCodeSnippet'})
                            }}>
                                <p>ADD code snippet</p>
                            </Button>      

                            <Button>
                                <p>ADD math equation</p>
                            </Button>                                                                              */}

                        </div>                  
                    </Box>                    
                    // <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                    //     <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                    //         <div style={{width: '50%', padding: 0, margin: 0}}>
                    //             <h2 style={{margin: 0}}>Create Post</h2>
                    //         </div>
                    //         <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                    //             <IconButton onClick={() => {
                    //                 this.setState({newModalOpen: false});
                    //                 this.setState({newModalView: 'main'});
                    //                 this.setState({newPostTitle: ''});
                    //                 this.setState({newPostTextContent: ''});
                    //                 this.setState({newPostTargetClass: ''});
                    //                 this.setState({newPostError: ''});
                    //                 this.setState({newPostImages: []});
                    //             }}>
                    //                 <CloseIcon/>
                    //             </IconButton>                                
                    //         </div>                            
                    //     </div>
                        // {this.state.currentUserClasses.length > 0 ? 
                        //     <FormControl variant="filled" style={{margin: 0, padding: 0, marginBottom: 15}}>
                        //         <InputLabel id="demo-simple-select-filled-label">Target Class</InputLabel>
                        //         <Select
                        //         labelId="demo-simple-select-filled-label"
                        //         style={{margin: 0}}
                        //         id="demo-simple-select-filled"
                        //         value={this.state.newPostTargetClass}
                        //         onChange={(value) => {
                        //             this.setState({newPostTargetClass: value.target.value})
                        //         }}
                        //         >
                        //             {this.state.currentUserClasses?.map(classOption => {
                        //                 return(
                        //                     <MenuItem value={classOption.classID}>{classOption.name}</MenuItem>
                        //                 )
                        //             })}
                        //         </Select>
                        //     </FormControl>            
                        //     :
                        //     <div style={{width: '100%', height: 75, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f2f2f2', margin: 0, padding: 0, borderRadius: 5, marginBottom: 15}}>
                        //         <h2 style={{margin: 0, padding: 0, color: '#AAA'}}>Join a class to ask a question</h2>
                        //     </div>
                                         
                        // }
                                              
                    //     <TextField style={{marginBottom: 15}} id="filled-basic" label="Title" value={this.state.newPostTitle} onChange={(text) => {this.setState({newPostTitle: text.target.value})}} variant="filled" />                                            

                    //     <TextField style={{marginBottom: 15}} id="filled-basic" label="Text Content" value={this.state.newPostTextContent} onChange={(text) => {this.setState({newPostTextContent: text.target.value})}} variant="filled" />                                              

                    //     <TextField style={{marginBottom: 15}} id="filled-basic" label="Text Content" value={this.state.newPostTextContent} onChange={(text) => {this.setState({newPostTextContent: text.target.value})}} variant="filled" />                                              

                    //     <TextField style={{marginBottom: 15}} id="filled-basic" label="Text Content" value={this.state.newPostTextContent} onChange={(text) => {this.setState({newPostTextContent: text.target.value})}} variant="filled" />                                              

                    //     <TextField style={{marginBottom: 15}} id="filled-basic" label="Text Content" value={this.state.newPostTextContent} onChange={(text) => {this.setState({newPostTextContent: text.target.value})}} variant="filled" />                                              

                    //     <TextField style={{marginBottom: 15}} id="filled-basic" label="Text Content" value={this.state.newPostTextContent} onChange={(text) => {this.setState({newPostTextContent: text.target.value})}} variant="filled" />                                              

                    //     <TextField style={{marginBottom: 15}} id="filled-basic" label="Text Content" value={this.state.newPostTextContent} onChange={(text) => {this.setState({newPostTextContent: text.target.value})}} variant="filled" />                                              

                    //     <TextField style={{marginBottom: 15}} id="filled-basic" label="Text Content" value={this.state.newPostTextContent} onChange={(text) => {this.setState({newPostTextContent: text.target.value})}} variant="filled" />                                              

                    //     <TextField style={{marginBottom: 15}} id="filled-basic" label="Text Content" value={this.state.newPostTextContent} onChange={(text) => {this.setState({newPostTextContent: text.target.value})}} variant="filled" />                                              

                    //     <TextField style={{marginBottom: 15}} id="filled-basic" label="Text Content" value={this.state.newPostTextContent} onChange={(text) => {this.setState({newPostTextContent: text.target.value})}} variant="filled" />                                              

                    //     <TextField style={{marginBottom: 15}} id="filled-basic" label="Text Content" value={this.state.newPostTextContent} onChange={(text) => {this.setState({newPostTextContent: text.target.value})}} variant="filled" />                                              

                    //     <TextField style={{marginBottom: 15}} id="filled-basic" label="Text Content" value={this.state.newPostTextContent} onChange={(text) => {this.setState({newPostTextContent: text.target.value})}} variant="filled" />                                              


                    //     {/* {this.state.newPostEquationEditorOpen == true ?
                    //         <div style={{width: '100%'}}>
                    //             <div style={{width: '100%', height: 50, backgroundColor: '#f2f2f2', borderRadius: 5, margin: 0, padding: 0, marginBottom: 15, display: 'flex', alignContent: 'center', alignItems: 'center', verticalAlign: 'center', paddingRight: 5 , paddingLeft: 5}}>
                    //                 <EquationEditor
                    //                     value={this.state.newPostEquationValue}
                    //                     onChange={(change) => {
                    //                         this.setState({newPostEquationValue: change})
                    //                     }}
                    //                     autoCommands="pi theta sqrt sum prod alpha beta gamma rho"
                    //                     autoOperatorNames="sin cos tan"
                    //                 />  
                    //             </div>  
                    //             <div style={{display: 'flex', flexDirection: 'row', marginBottom: 10}}>
                    //                 <p style={{padding: 0, margin: 0, textAlign: 'center', color: '#AAA'}}>^(superscript), _(Subscript), int(integral), sum(summation), prod(product), sqrt(square root)</p>
                    //             </div>                         

                    //             {this.state.newPostEquationValue.trim() != "" ?
                    //                 <Button style={{marginTop: 0, width: '100%'}} variant="outlined" onClick={() => {
                    //                     let currentPostAttachments = this.state.newPostAttachments
                    //                     currentPostAttachments.push(
                    //                         {
                    //                             type: 'equation',
                    //                             equationValue: this.state.newPostEquationValue,
                    //                         }
                    //                     )
                    //                     this.setState({newPostAttachments: currentPostAttachments})                                        
                    //                     this.setState({newPostEquationValue: "y=x^2"});
                    //                 }}>
                    //                     <p style={{margin: 0, padding: 0, paddingTop: 5}}>+ Add Equation To Post</p>
                    //                 </Button>  
                    //                 :
                    //                 <Button style={{marginTop: 0, width: '100%'}} variant="outlined" disabled={true} onClick={() => {
                                        
                    //                 }}>
                    //                     <p style={{margin: 0, padding: 0, paddingTop: 5}}>+ Add Equation To Post</p>
                    //                 </Button>                                                      
                    //             }    
                    //         </div> 
                    //         :
                    //         <Button style={{marginTop: 0}} variant="outlined" onClick={() => {
                    //             this.setState({newPostEquationEditorOpen: true});
                    //         }}>
                    //             <p style={{margin: 0, padding: 0, paddingTop: 5}}>Open equation editor</p>
                    //         </Button>                                                   
                    //     }                       */}

                    //     {/* <ul style={{marginTop: 15, width: '100%', display: 'inline-block', overflow: 'auto', maxWidth: '100%', whiteSpace: 'nowrap', padding: 0, height: 200}}>
                    //         <li  style={{display: 'inline-block', verticalAlign: 'top', height: '100%', width: 150, marginRight: 15}}>
                                // <div style={{color: '#AAA', width: '100%', height: '100%', backgroundColor: '#E8E8E8', borderRadius: 5, padding: 5, display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center', justifyItems: 'center', verticalAlign: 'center'}} onClick={() => {
                                //     document.getElementById("newPostImageInput").click();
                                // }}>
                                //     <p style={{color: '#AAA', margin: 0, padding: 0}}>+</p>
                                //     <p style={{color: '#AAA', margin: 0, padding: 0}}>Upload Image</p>
                                //     <input
                                //         id="newPostImageInput"
                                //         style={{display: 'none'}}
                                //         type="file"
                                //         accept="image/*"
                                //         name="myImage"
                                //         onChange={(event) => {
                                //             let currentPostAttachments = this.state.newPostAttachments
                                //             currentPostAttachments.push(
                                //                 {
                                //                     type: 'image',
                                //                     // ID BOTTOM
                                //                     imageFileName: event.target.files[0].name, 
                                //                     imageFileToPush: event.target.files[0], 
                                //                     imageFileTimestamp: event.target.files[0].lastModified
                                //                 }
                                //             )
                                //             this.setState({newPostAttachments: currentPostAttachments})
                                //         }}
                                //     />                                    
                                // </div>
                    //         </li>                              
                    //         <FlatList
                    //             list={this.state.newPostAttachments}
                    //             renderItem={this.renderNewPostAttachments}
                    //             renderWhenEmpty={() => 
                    //                 <div/>
                    //             }
                    //         />                            
                    //     </ul> */}

                    //     {this.state.newPostError != "" ? 
                    //         <p style={{marginTop: 5, marginBottom: 0, color: 'red'}}>{this.state.newPostError}</p>
                    //     :   
                    //         null
                    //     } 

                    //     {this.state.newPostTitle.trim().length != 0 && this.state.newPostTextContent.trim().length != 0 && this.state.newPostTargetClass != "" ? 
                    //         <Button style={{marginTop: 15}} variant="contained" onClick={() => {
                    //             this.uploadNewPost()
                    //         }}>
                    //             <p style={{margin: 0, padding: 0, paddingTop: 5}}>Upload</p>
                    //         </Button>                            
                    //     : 
                    //         <Button style={{marginTop: 15}} disabled="true" variant="contained">
                    //             <p style={{margin: 0, padding: 0, paddingTop: 5}}>Upload</p>
                    //         </Button>                                                
                    //     }
                    // </Box>                                       
                );                    
            case 'newPostCodeSnippet':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>        
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '50%', padding: 0, margin: 0}}>
                                <KeyboardBackspaceIcon onClick={() => {
                                    this.setState({newModalView: 'newPost'});
                                    this.setState({newPostCodeSnippet: '<pre class="ql-syntax" spellcheck="false">System.out.println("Add your code here");</pre>'})
                                }}/>                                 
                            </div>
                            <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>                              
                            </div>                            
                        </div>  

                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: 500, overflowY: 'scroll',paddingRight: 15}}>
                        <p style={{margin: 0, padding: 0, fontWeight: '700', color: '#000', marginBottom: 10, fontSize: 17}}>Enter your code snippet below</p>                            
                                <ReactQuill
                                    modules={this.modules}
                                    theme="snow"
                                    style={{marginBottom: 15}}
                                    value={this.state.newPostCodeSnippet}
                                    onChange={(newCodeSnippetValue) => {
                                        console.log(newCodeSnippetValue)
                                        this.setState({newPostCodeSnippet: newCodeSnippetValue});
                                    }}
                                />    
                                <Button style={{marginTop: 50}} variant="outlined" onClick={() => {
                                    let currentNewPostCodeSnippetsList = this.state.newPostCodeSnippetsList;
                                    currentNewPostCodeSnippetsList.push({
                                        codeSnippetIndex: this.state.newPostCodeSnippetsList.length + 1,
                                        codeSnippetValue: this.state.newPostCodeSnippet
                                    });
                                    this.setState({newPostCodeSnippetsList: currentNewPostCodeSnippetsList}, () => {
                                        this.setState({newPostCodeSnippet: '<pre class="ql-syntax" spellcheck="false">System.out.println("Add your code here");</pre>'});
                                        this.setState({newModalView: 'newPost'});
                                    });

                                }}>
                                    + Add Code Block To Post
                                </Button>                                                             
                        </div>                  

                    </Box>  
                );
            case 'newPostEquation':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>        
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '50%', padding: 0, margin: 0}}>
                                <KeyboardBackspaceIcon onClick={() => {
                                    this.setState({newModalView: 'newPost'});
                                    this.setState({newPostEquationValue: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}"})
                                }}/>                                 
                            </div>
                            <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>                              
                            </div>                            
                        </div>  

                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: 500,paddingRight: 15}}>
                                <h2 style={{margin: 0, padding: 0, fontWeight: '700', color: '#000', marginBottom: 0}}>Type out your equation below</h2>
                                <p style={{margin: 0, padding: 0, color: '#AAA', marginTop: 5, marginBottom: 10}}>Enter a "\" to type out special symbols. Full mathematical inputs and shortcuts <a style={{color: '#2E91FC'}} href="https://cortexjs.io/mathlive/reference/keybindings/#:~:text=%5C~-,Inline%20Shortcuts,-Some%20sequence%20of" target={"_blank"}>here</a></p>              
                                <MathView
                                    style={{fontSize: 30, backgroundColor: '#FFF', padding: 10, borderRadius: 5, color: '#000', borderWidth: 1, borderColor: '#1976d2', borderStyle: 'solid'}}
                                    // defaultValue={this.state.def}
                                    onContentDidChange={(newEquation) => {
                                        this.setState({newPostEquationValue: newEquation.getValue()});
                                        
                                    }}
                                />                                     
                                                                      
                                <Button style={{marginTop: 15}} variant="outlined" onClick={() => {
                                    let newPostEquationValueToAdd = this.state.newPostEquationValue;
                                    let currentNewPostEquationsList = this.state.newPostEquationsList;

                                    currentNewPostEquationsList.push({
                                        equationIndex: this.state.newPostEquationsList.length + 1,
                                        equationValue: newPostEquationValueToAdd
                                    });

                                    this.setState({newPostEquationsList: currentNewPostEquationsList}, () => {
                                        this.setState({newPostEquationValue: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}"});
                                        this.setState({newModalView: 'newPost'});
                                    });


                                }}>
                                    + Add Equation to Post
                                </Button>                                                             
                        </div>                  

                    </Box>  
                );                
            case 'newClassID':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '50%', padding: 0, margin: 0}}>
                                <h2 style={{margin: 0}}>Share this Link and ID with your students</h2>
                            </div>
                            <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                <IconButton onClick={() => {
                                    this.setState({newModalOpen: false});                             
                                    this.setState({activeTab: "class"});
                                    this.setState({focusedClass: this.state.newClassID}, this.getClass2); 
                                    this.setState({newModalView: "main"});                 
                                    this.setState({newClassName: ""});
                                    this.setState({newClassError: ""});
                                    this.setState({newClassBannerID: ""});
                                    this.setState({newClassID: ''});
                                }}>
                                    <CloseIcon/>
                                </IconButton>                                
                            </div>                            
                        </div>                        
                        <TextField id="outlined-basic" label="Class Link" style={{color: '#000'}} value={this.state.newClassJoinLink} disabled="true" variant="outlined" />       
                        <p style={{color: '#AAA', padding: 0, margin: 0, marginTop: 5}}>This link is exlusive for mobile users</p>                                         
                        <TextField id="outlined-basic" label="Class ID" style={{color: '#000', marginTop: 15}} value={this.state.newClassID} disabled="true" variant="outlined" />                        
                        <p style={{color: '#000', padding: 0, margin: 0, marginTop: 5, fontWeight: 'bold'}}>This invitation can also be found top right off class banner</p>
                        <Button style={{marginTop: 15}} variant="contained" onClick={() => {
                            navigator.clipboard.writeText(
                                "Join " + this.state.newClassName + " on Peer to Peer with this ID " + this.state.newClassID + ". If you're on mobile simply click this link " +  this.state.newClassJoinLink
                            );
                            this.showSnackbar("Copied class invitation to your clipboard")
                        }}>
                            <p style={{margin: 0, padding: 0, paddingTop: 5}}>Copy Class Invitation</p>
                        </Button>                                                  
                    </Box> 
                );
            default:
                return(
                    <Box sx={{position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, p: 4}}>
                        <p>loading</p>                                            
                    </Box>                 
                );            
        }             
    }
    NewChildKnowledgeBitModaView = () => {
        switch (this.state.newChildKnowledgeBitModalView) {
            case 'loading':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 5, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}}>
                        <CircularProgress />
                        <h2>Loading</h2>
                    </Box>               
                );                   
            case 'main':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '50%', padding: 0, margin: 0}}>
                                <h2 style={{margin: 0}}>Reply to {this.state.newChildKnowledgeBitParentCreatorFName}</h2>
                            </div>
                            <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                <IconButton onClick={() => {
                                    this.setState({newChildKnowledgeBitModalOpen: false});
                                    this.setState({newChildKnowledgeBitModalView: 'main'});
                                    this.setState({newChildKnowledgeBitTextContent: ''});
                                    this.setState({newChildKnowledgeBitParentCreatorFName: ''});
                                    this.setState({newChildKnowledgeBitParentID: ''});
                                    this.setState({newChildKnowledgeBitRootID: ''});
                                    this.setState({newChildKnowledgeBitError: ''});
                                    this.setState({newChildKnowledgeBitImages: []});
                                    this.setState({newChildKnowledgeBitCodeSnippet: '<pre class="ql-syntax" spellcheck="false">System.out.println("Add your code here");</pre>'});
                                    this.setState({newChildKnowledgeBitCodeSnippetsList: []});
                                    this.setState({newChildKnowledgeBitEquationsList: []});
                                    this.setState({newChildKnowledgeBitEquationValue: []});
                                }}>
                                    <CloseIcon/>
                                </IconButton>                                
                            </div>                            
                        </div>  
                                           
                        <div style={{maxHeight: 500, overflowY: 'scroll', width: '100%', paddingRight: 10}}>
                            <TextField style={{width: '100%'}} id="filled-basic" autoFocus="true" label="Text Content" value={this.state.newChildKnowledgeBitTextContent} onChange={(text) => {this.setState({newChildKnowledgeBitTextContent: text.target.value})}} variant="filled" />                        

                            <input
                                id="newChildKnowledgeBitImageInput"
                                style={{display: 'none'}}
                                type="file"
                                accept="image/*"
                                name="myChildKBImage"
                                onChange={(event) => {
                                    let currentNewChildKnowledgeBitImages = this.state.newChildKnowledgeBitImages
                                    currentNewChildKnowledgeBitImages.push(
                                        {
                                            id: event.target.files[0].name, 
                                            uploadedImageFileToPush: event.target.files[0], 
                                            uploadedImageFileModTimestamp: event.target.files[0].lastModified
                                        }
                                    )
                                    this.setState({newChildKnowledgeBitImages: currentNewChildKnowledgeBitImages})
                                }}
                            /> 

                            <Box style={{marginBottom: 20, marginTop: 15}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Button variant="outlined" style={{width: '100%', height: 50}} onClick={() => {
                                            this.setState({newChildKnowledgeBitModalView: 'newKnowledgeBitCodeSnippet'});                                        
                                        }}>
                                            + Code Snippet
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button variant="outlined" style={{width: '100%', height: 50}} onClick={() => {
                                            document.getElementById("newChildKnowledgeBitImageInput").click();
                                        }}>
                                            + Image
                                        </Button>                                    
                                    </Grid>    
                                    <Grid item xs={4}>
                                        <Button variant="outlined" style={{width: '100%', height: 50}} onClick={() => {
                                            this.setState({newChildKnowledgeBitModalView: 'newKnowledgeBitEquation'});                                        
                                        }}>
                                            + Equation
                                        </Button>                                    
                                    </Grid>              
                                </Grid>
                            </Box>      

                            {this.state.newChildKnowledgeBitCodeSnippetsList.length > 0 ?
                                <div style={{padding: 0}}>
                                    <ul style={{listStyle: 'none', margin: 0, padding: 0, marginBottom: 10}}>
                                        <FlatList
                                            style={{padding: 0}}
                                            list={this.state.newChildKnowledgeBitCodeSnippetsList}
                                            renderItem={this.renderNewChildKnowledgeBitCodeSnippets}
                                            renderWhenEmpty={() => 
                                                <div/>
                                            }
                                        />  
                                    </ul>
                                </div>
                                :
                                null
                            }   

                            {this.state.newChildKnowledgeBitEquationsList.length > 0 ?
                                <div>
                                    <ul style={{listStyle: 'none', margin: 0, padding: 0, marginBottom: 10}}>
                                        <FlatList
                                            list={this.state.newChildKnowledgeBitEquationsList}
                                            renderItem={this.renderNewChildKnowledgeBitEquations}
                                            renderWhenEmpty={() => 
                                                <div/>
                                            }
                                        />  
                                    </ul>
                                </div>                                
                                :
                                null
                            }


                            {this.state.newChildKnowledgeBitImages.length > 0 ?
                                <div>
                                    <ul style={{width: '100%', display: 'inline-block', maxWidth: '100%', whiteSpace: 'nowrap', padding: 0, height: 200}}>                                
                                        <FlatList
                                            list={this.state.newChildKnowledgeBitImages}
                                            renderItem={this.renderNewChildKnowledgeBitImages}
                                            renderWhenEmpty={() => 
                                                <div/>
                                            }
                                        />
                                    </ul>                                    
                                </div>
                                :
                                null
                            }                                

                            {this.state.newChildKnowledgeBitError != "" ? 
                                <p style={{marginTop: 5, marginBottom: 0, color: 'red'}}>{this.state.newChildKnowledgeBitError}</p>
                            :   
                                null
                            } 

                            {this.state.newChildKnowledgeBitTextContent.trim().length != 0 ? 
                                <Button style={{marginTop: 15, width: '100%'}} variant="contained" onClick={this.uploadNewChildKnowledgeBit}>
                                    <p style={{margin: 0, padding: 0, paddingTop: 5}}>Reply</p>
                                </Button>                            
                            : 
                                <Button style={{marginTop: 15, width: '100%'}} disabled="true" variant="contained">
                                    <p style={{margin: 0, padding: 0, paddingTop: 5}}>Reply</p>
                                </Button>                                                
                            }
                        </div>
                    </Box>                             
                );                
                case 'newKnowledgeBitEquation':     
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>        
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '50%', padding: 0, margin: 0}}>
                                <KeyboardBackspaceIcon onClick={() => {
                                    this.setState({newChildKnowledgeBitModalView: 'main'});
                                    this.setState({newChildKnowledgeBitEquationValue: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}"})
                                }}/>                                 
                            </div>
                            <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>                              
                            </div>                            
                        </div>  

                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: 500, overflowY: 'scroll',paddingRight: 15}}>
                                <h2 style={{margin: 0, padding: 0, fontWeight: '700', color: '#000', marginBottom: 0}}>Type out your equation below</h2>    
                                <p style={{margin: 0, padding: 0, color: '#AAA', marginTop: 5, marginBottom: 10}}>Enter a "\" to type out special symbols. Full mathematical inputs and shortcuts <a style={{color: '#2E91FC'}} href="https://cortexjs.io/mathlive/reference/keybindings/#:~:text=%5C~-,Inline%20Shortcuts,-Some%20sequence%20of" target={"_blank"}>here</a></p>                                                              
                                <MathView
                                    style={{fontSize: 30, backgroundColor: '#FFF', padding: 10, borderRadius: 5, color: '#000', borderWidth: 1, borderColor: '#1976d2', borderStyle: 'solid'}}

                                    onContentDidChange={(equationValueChange) => {
                                        this.setState({newChildKnowledgeBitEquationValue: equationValueChange.getValue()});
                                    }}
                                />                                 
                                {this.state.newChildKnowledgeBitEquationValue != "" ? 
                                    <Button style={{marginTop: 15}} variant="outlined" onClick={() => {
                                        let newPostEquationValueToAdd = this.state.newChildKnowledgeBitEquationValue;
                                        let currentNewPostEquationsList = this.state.newChildKnowledgeBitEquationsList;

                                        currentNewPostEquationsList.push({
                                            equationIndex: this.state.newChildKnowledgeBitEquationsList.length + 1,
                                            equationValue: newPostEquationValueToAdd
                                        });

                                        this.setState({newChildKnowledgeBitEquationsList: currentNewPostEquationsList}, () => {
                                            this.setState({newChildKnowledgeBitEquationValue: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}"});
                                            this.setState({newChildKnowledgeBitModalView: 'main'});
                                        });


                                    }}>
                                        + Add Equation to Knowledge Bit
                                    </Button> 
                                    :
                                    <Button style={{marginTop: 15}} variant="outlined" disabled={true}>
                                        + Add Equation to Knowledge Bit
                                    </Button>                                     
                                }                                                            
                        </div>                  

                    </Box>  
                );                        
            case 'newKnowledgeBitCodeSnippet':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>        
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '50%', padding: 0, margin: 0}}>
                                <KeyboardBackspaceIcon onClick={() => {
                                    this.setState({newChildKnowledgeBitModalView: 'main'});
                                    this.setState({newChildKnowledgeBitCodeSnippet: '<pre class="ql-syntax" spellcheck="false">System.out.println("Add your code here");</pre>'})
                                }}/>                                 
                            </div>
                            <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>                              
                            </div>                            
                        </div>  

                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: 500, overflowY: 'scroll',paddingRight: 15}}>
                        <p style={{margin: 0, padding: 0, fontWeight: '700', color: '#000', marginBottom: 10, fontSize: 17}}>Enter your code snippet below</p>                            
                                <ReactQuill
                                    modules={this.modules}
                                    theme="snow"
                                    style={{marginBottom: 15}}
                                    value={this.state.newChildKnowledgeBitCodeSnippet}
                                    onChange={(newCodeSnippetValue) => {
                                        this.setState({newChildKnowledgeBitCodeSnippet: newCodeSnippetValue});
                                    }}
                                />    
                                <Button style={{marginTop: 50}} variant="outlined" onClick={() => {
                                    let currentNewPostCodeSnippetsList = this.state.newChildKnowledgeBitCodeSnippetsList;
                                    currentNewPostCodeSnippetsList.push({
                                        codeSnippetIndex: this.state.newChildKnowledgeBitCodeSnippetsList.length + 1,
                                        codeSnippetValue: this.state.newChildKnowledgeBitCodeSnippet
                                    });
                                    this.setState({newChildKnowledgeBitCodeSnippetsList: currentNewPostCodeSnippetsList}, () => {
                                        this.setState({newChildKnowledgeBitCodeSnippet: '<pre class="ql-syntax" spellcheck="false">System.out.println("Add your code here");</pre>'});
                                        this.setState({newChildKnowledgeBitModalView: 'main'});
                                    });

                                }}>
                                    + Add Code Block To Post
                                </Button>                                                             
                        </div>                  

                    </Box>                     
                );              
            default:
                return(
                    <Box sx={{position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, p: 4}}>
                        <p>loading</p>                                            
                    </Box>                 
                );            
        }             
    }   
    
    NewKnowledgeBitModaView = () => {
        switch (this.state.newKnowledgeBitModalView) {
            case 'loading':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 5, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}}>
                        <CircularProgress />
                        <h2>Loading</h2>
                    </Box>               
                );                   
            case 'main':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '50%', padding: 0, margin: 0}}>
                                <h2 style={{margin: 0}}>Add Knowledge Bit</h2>
                            </div>
                            <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                <IconButton onClick={() => {
                                    this.setState({newKnowledgeBitModalOpen: false});
                                    this.setState({newKnowledgeBitModalView: 'main'});
                                    this.setState({newKnowledgeBitTextContent: ''});
                                    this.setState({newKnowledgeBitError: ''});
                                    this.setState({newKnowledgeBitImages: []});
                                    this.setState({newKnowledgeBitCodeSnippet: '<pre class="ql-syntax" spellcheck="false">System.out.println("Add your code here");</pre>'});
                                    this.setState({newKnowledgeBitCodeSnippetsList: []});
                                    this.setState({newKnowledgeBitEquationsList: []});
                                    this.setState({newKnowledgeBitEquationValue: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}"});
                                }}>
                                    <CloseIcon/>
                                </IconButton>                                
                            </div>                            
                        </div>                      
                        <div style={{maxHeight: 500, overflowY: 'scroll', width: '100%', paddingRight: 10}}>

                            <TextField style={{width: '100%'}} id="filled-basic" autoFocus="true" label="Text Content" value={this.state.newKnowledgeBitTextContent} onChange={(text) => {this.setState({newKnowledgeBitTextContent: text.target.value})}} variant="filled" />                        

                            <input
                                id="newParentKnowledgeBitImageInput"
                                style={{display: 'none'}}
                                type="file"
                                accept="image/*"
                                name="myParentKBImage"
                                onChange={(event) => {
                                    let currentNewKnowledgeBitImages = this.state.newKnowledgeBitImages
                                    currentNewKnowledgeBitImages.push(
                                        {
                                            id: event.target.files[0].name, 
                                            uploadedImageFileToPush: event.target.files[0], 
                                            uploadedImageFileModTimestamp: event.target.files[0].lastModified
                                        }
                                    )
                                    this.setState({newKnowledgeBitImages: currentNewKnowledgeBitImages})
                                }}
                            />

                            <Box style={{marginBottom: 20, marginTop: 15}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <Button variant="outlined" style={{width: '100%', height: 50}} onClick={() => {
                                            this.setState({newKnowledgeBitModalView: 'newKnowledgeBitCodeSnippet'});                                        
                                        }}>
                                            + Code Snippet
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button variant="outlined" style={{width: '100%', height: 50}} onClick={() => {
                                            document.getElementById("newParentKnowledgeBitImageInput").click();
                                        }}>
                                            + Image
                                        </Button>                                    
                                    </Grid>    
                                    <Grid item xs={4}>
                                        <Button variant="outlined" style={{width: '100%', height: 50}} onClick={() => {
                                            this.setState({newKnowledgeBitModalView: 'newKnowledgeBitEquation'});                                        
                                        }}>
                                            + Equation
                                        </Button>                                    
                                    </Grid>              
                                </Grid>
                            </Box>      

                            {this.state.newKnowledgeBitCodeSnippetsList.length > 0 ?
                                <div style={{padding: 0}}>
                                    <ul style={{listStyle: 'none', margin: 0, padding: 0, marginBottom: 10}}>
                                        <FlatList
                                            style={{padding: 0}}
                                            list={this.state.newKnowledgeBitCodeSnippetsList}
                                            renderItem={this.renderNewKnowledgeBitCodeSnippets}
                                            renderWhenEmpty={() => 
                                                <div/>
                                            }
                                        />  
                                    </ul>
                                </div>
                                :
                                null
                            }   

                            {this.state.newKnowledgeBitEquationsList.length > 0 ?
                                <div>
                                    <ul style={{listStyle: 'none', margin: 0, padding: 0, marginBottom: 10}}>
                                        <FlatList
                                            list={this.state.newKnowledgeBitEquationsList}
                                            renderItem={this.renderNewKnowledgeBitEquations}
                                            renderWhenEmpty={() => 
                                                <div/>
                                            }
                                        />  
                                    </ul>
                                </div>                                
                                :
                                null
                            }


                            {this.state.newKnowledgeBitImages.length > 0 ?
                                <div>
                                    <ul style={{width: '100%', display: 'inline-block', maxWidth: '100%', whiteSpace: 'nowrap', padding: 0, height: 200}}>                                
                                        <FlatList
                                            list={this.state.newKnowledgeBitImages}
                                            renderItem={this.renderNewKnowledgeBitImages}
                                            renderWhenEmpty={() => 
                                                <div/>
                                            }
                                        />
                                    </ul>                                    
                                </div>
                                :
                                null
                            }

                            {this.state.newKnowledgeBitError != "" ? 
                                <p style={{margin: 0, padding: 0, marginTop: 10, color: 'red'}}>{this.state.newKnowledgeBitError}</p>
                            :   
                                null
                            } 

                            {this.state.newKnowledgeBitTextContent.trim().length != 0 ? 
                                <Button style={{marginTop: 15, width: '100%'}} variant="contained" onClick={() => {
                                    this.setState({newKnowledgeBitModalView: 'loading'}, () => {
                                        this.uploadNewKnowledgeBit()
                                    });
                                }}>
                                    <p style={{margin: 0, padding: 0, paddingTop: 5}}>Upload</p>
                                </Button>                            
                            : 
                                <Button style={{marginTop: 15, width: '100%'}} disabled="true" variant="contained" onClick={() => {
                                    
                                }}>
                                    <p style={{margin: 0, padding: 0, paddingTop: 5}}>Upload</p>
                                </Button>                                                
                            }
                        </div>
                    </Box>                                  
                );                
            case 'newKnowledgeBitEquation':     
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>        
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '50%', padding: 0, margin: 0}}>
                                <KeyboardBackspaceIcon onClick={() => {
                                    this.setState({newKnowledgeBitModalView: 'main'});
                                    this.setState({newKnowledgeBitEquationValue: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}"})
                                }}/>                                 
                            </div>
                            <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>                              
                            </div>                            
                        </div>  

                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: 500, overflowY: 'scroll',paddingRight: 15}}>
                                <h2 style={{margin: 0, padding: 0, fontWeight: '700', color: '#000', marginBottom: 0}}>Type out your equation below</h2>
                                <p style={{margin: 0, padding: 0, color: '#AAA', marginTop: 5, marginBottom: 10}}>Enter a "\" to type out special symbols. Full mathematical inputs and shortcuts <a style={{color: '#2E91FC'}} href="https://cortexjs.io/mathlive/reference/keybindings/#:~:text=%5C~-,Inline%20Shortcuts,-Some%20sequence%20of" target={"_blank"}>here</a></p>                                                                                              
                                <MathView
                                    style={{fontSize: 30, backgroundColor: '#FFF', padding: 10, borderRadius: 5, color: '#000', borderWidth: 1, borderColor: '#1976d2', borderStyle: 'solid'}}

                                    onContentDidChange={(equationValueChange) => {
                                        this.setState({newKnowledgeBitEquationValue: equationValueChange.getValue()});
                                        
                                    }}
                                />                            
                                {this.state.newKnowledgeBitEquationValue != "" ? 
                                    <Button style={{marginTop: 15}} variant="outlined" onClick={() => {
                                        let newPostEquationValueToAdd = this.state.newKnowledgeBitEquationValue;
                                        let currentNewPostEquationsList = this.state.newKnowledgeBitEquationsList;

                                        currentNewPostEquationsList.push({
                                            equationIndex: this.state.newKnowledgeBitEquationsList.length + 1,
                                            equationValue: newPostEquationValueToAdd
                                        });

                                        this.setState({newKnowledgeBitEquationsList: currentNewPostEquationsList}, () => {
                                            this.setState({newKnowledgeBitEquationValue: "x=\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}"});
                                            this.setState({newKnowledgeBitModalView: 'main'});
                                        });


                                    }}>
                                        + Add Equation to Knowledge Bit
                                    </Button>   
                                    :
                                    <Button style={{marginTop: 15}} variant="outlined" disabled={true}>
                                        + Add Equation to Knowledge Bit
                                    </Button>                                                                         
                                }                                                              
                        </div>                  

                    </Box>  
                );                        
            case 'newKnowledgeBitCodeSnippet':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>        
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '50%', padding: 0, margin: 0}}>
                                <KeyboardBackspaceIcon onClick={() => {
                                    this.setState({newKnowledgeBitModalView: 'main'});
                                    this.setState({newKnowledgeBitCodeSnippet: '<pre class="ql-syntax" spellcheck="false">System.out.println("Add your code here");</pre>'})
                                }}/>                                 
                            </div>
                            <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>                              
                            </div>                            
                        </div>  

                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: 500, overflowY: 'scroll',paddingRight: 15}}>
                        <p style={{margin: 0, padding: 0, fontWeight: '700', color: '#000', marginBottom: 10, fontSize: 17}}>Enter your code snippet below</p>                            
                                <ReactQuill
                                    modules={this.modules}
                                    theme="snow"
                                    style={{marginBottom: 15}}
                                    value={this.state.newKnowledgeBitCodeSnippet}
                                    onChange={(newCodeSnippetValue) => {
                                        this.setState({newKnowledgeBitCodeSnippet: newCodeSnippetValue}, () => {
                                            console.log(this.state.newKnowledgeBitCodeSnippet)
                                        });
                                    }}
                                />    

                                <Button style={{marginTop: 50}} variant="outlined" onClick={() => {
                                    let currentNewPostCodeSnippetsList = this.state.newKnowledgeBitCodeSnippetsList;
                                    currentNewPostCodeSnippetsList.push({
                                        codeSnippetIndex: this.state.newKnowledgeBitCodeSnippetsList.length + 1,
                                        codeSnippetValue: this.state.newKnowledgeBitCodeSnippet
                                    });
                                    this.setState({newKnowledgeBitCodeSnippetsList: currentNewPostCodeSnippetsList}, () => {
                                        this.setState({newKnowledgeBitCodeSnippet: '<pre class="ql-syntax" spellcheck="false">System.out.println("Add your code here");</pre>'});
                                        this.setState({newKnowledgeBitModalView: 'main'});
                                    });

                                }}>
                                    + Add Code Block To Post
                                </Button>                                                                                     
                        </div>                  

                    </Box>                     
                );   
            default:
                return(
                    <Box sx={{position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, p: 4}}>
                        <p>loading</p>                                            
                    </Box>                 
                );            
        }             
    }   
    
    AccountSettingsModalView = () => {
        switch(this.state.accountSettingsModalView){
            case 'loading':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 15, paddingBottom: 15, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}}>
                        <CircularProgress />
                        <h2>Loading</h2>
                    </Box>                     
                );
            case 'main':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '50%', padding: 0, margin: 0}}>
                                <h2 style={{margin: 0}}>Your Account</h2>
                            </div>
                            <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                <IconButton onClick={() => {
                                    this.setState({accountSettingsModalOpen: false});
                                }}>
                                    <CloseIcon/>
                                </IconButton>                                
                            </div>                            
                        </div>                      
                        <Button variant="outlined"onClick={() => {
                            this.setState({accountSettingsModalView: 'profilePictureUpdate'})
                        }}>
                            Update Profile Picture
                        </Button>                         
                        <Button variant="outlined" style={{marginTop: 15}} onClick={() => {
                            this.setState({accountSettingsModalView: 'bannerUpdate'});
                        }}>
                            Update Banner
                        </Button>    
                        <Button variant="outlined" style={{marginTop: 15}} onClick={() => {
                            (async () => {
                                sendPasswordResetEmail(this.state.auth, this.state.currentUserEmail).then(() => {
                                    this.showSnackbar("Sent password reset email to " + this.state.currentUserEmail);
                                }).catch((error) => {
                                    console.log(error);
                                });
                            })().catch((error) => {});
                        }}>
                            Update Password
                        </Button>   

                        <Button variant="outlined" style={{marginTop: 15}} onClick={() => {
                        }}>
                            <a href="https://peertopeerweb.app/privacy" target={'_blank'} style={{padding: 0, margin: 0, width: '100%', alignContent: 'center', alignItems: 'center',justifyContent: 'center', justifyItems: 'center', textDecoration: 'none'}}>
                                <p style={{padding: 0, margin: 0, alignContent: 'center', alignItems: 'center',justifyContent: 'center', justifyItems: 'center', color: '#1976d2', width: '100%'}}>PRIVACY POLICY</p>
                            </a>

                        </Button>

                        <Button variant="outlined" style={{marginTop: 15}} onClick={() => {
                            this.setState({accountSettingsModalOpen: false}, () => {
                                setTimeout(() => {
                                    this.setState({attributionsModalOpen: true});    
                                }, 500);
                                
                            });
                            
                        }}>
                            Attributions
                        </Button>  
                        <Button variant="outlined" style={{marginTop: 15}} onClick={() => {
                        }}>
                            <a href="https://peertopeerweb.app/support" target={'_blank'} style={{padding: 0, margin: 0, width: '100%', alignContent: 'center', alignItems: 'center',justifyContent: 'center', justifyItems: 'center', textDecoration: 'none'}}>
                                <p style={{padding: 0, margin: 0, alignContent: 'center', alignItems: 'center',justifyContent: 'center', justifyItems: 'center', color: '#1976d2', width: '100%'}}>SUPPORT</p>
                            </a>

                        </Button>                                                                                                                                       
                        <Button variant="outlined" style={{marginTop: 15}} onClick={() => {
                            (async () => {
                                signOut(this.state.auth).then(() => {
                                    console.log("Signed Out");
                                }).catch((error) => {
                                    console.log(error);
                                });
                            })().catch((error) => {});
                        }}>
                            Sign Out
                        </Button>    
                        <p style={{alignSelf: 'center', color: '#AAA', padding: 0, margin: 0, marginBottom: 5, marginTop: 15}}>{this.state.currentUserEmail}</p>                                
                        <p style={{alignSelf: 'center', color: '#AAA', padding: 0, margin: 0, marginBottom: 5}}>{this.state.currentUserRole[0].toUpperCase() + this.state.currentUserRole.substring(1)} Account</p>                                

                                   
                        
                        
                    </Box>                     
                );
            case 'bannerUpdate':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '75%', padding: 0, margin: 0}}>
                                <h2 style={{margin: 0}}>Update Banner</h2>
                            </div>
                            <div style={{width: '25%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                <IconButton onClick={() => {
                                    this.setState({accountSettingsModalOpen: false});
                                    this.setState({accountSettingsModalView: 'main'});
                                    this.setState({accountSettingsBannerError: ''});
                                }}>
                                    <CloseIcon/>
                                </IconButton>                                
                            </div>                            
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <input
                                id="uploadBannerInput"
                                style={{display: 'none'}}
                                type="file"
                                accept="image/*"
                                name="banner"
                                onChange={(event) => {
                                    this.setState({accountSettingsBannerError: ''});
                                    this.setState({accountSettingsModalView: 'loading'});
                                    let newBannerImageID = event.target.files[0].name
                                    let newBannerImageFile = event.target.files[0]
                                    let newBannerImageModTimestamp = event.target.files[0].lastModified;
                                    const storage = getStorage()
                                    let newBannerImagePath = "ProfileBanners/" + newBannerImageModTimestamp + "-" + newBannerImageID;
                                    let newBannerImagePathForFirestore = newBannerImageModTimestamp + "-" + newBannerImageID;
                                    const newBannerImageStorageRef = ref(storage, newBannerImagePath)
                                    uploadBytes(newBannerImageStorageRef, newBannerImageFile).then((snapshot) => {
                                        (async () => {  
                                            await updateDoc(doc(this.state.db, "Users", this.state.currentUserEmail), {
                                                banner: newBannerImagePathForFirestore,
                                            }).then(() => {
                                                this.showSnackbar("Updated your banner");
                                                this.setState({accountSettingsBannerUpdate: true})
                                                this.setState({currentUserBanner: event.target.files[0]})    
                                                this.setState({accountSettingsModalView: 'bannerUpdate'})
                                            }).catch((error) => {
                                                this.setState({accountSettingsBannerUpdate: false})
                                                this.setState({accountSettingsBannerError: 'There was an issue updating your banner'});
                                                this.setState({accountSettingsModalView: 'bannerUpdate'})
                                            })
                                        })()
                                    }).catch((error) => {
                                        this.setState({accountSettingsBannerUpdate: false})
                                        this.setState({accountSettingsBannerError: 'There was an issue updating your banner'});
                                        this.setState({accountSettingsModalView: 'bannerUpdate'})
                                    });                                      
                                    
                                }}
                            />              
                            {this.state.currentUserBanner != "" ?
                                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                    {this.state.accountSettingsBannerUpdate == true ? 
                                        <img draggable={false} src={URL.createObjectURL(this.state.currentUserBanner)} style={{height: 200, width: '100%', borderRadius: 10, border: '5px solid #FFF', alignSelf: 'center', marginBottom: 15, objectFit: 'cover'}}/>                        
                                        :
                                        <img draggable={false} src={this.state.currentUserBanner} style={{height: 200, width: '100%', borderRadius: 10, border: '5px solid #FFF', alignSelf: 'center', marginBottom: 15, objectFit: 'cover'}}/>                        
                                    }
                                </div>   
                                :
                                <img draggable={false} src={ProfileBannerPlaceholder} style={{height: 200, width: '100%', borderRadius: 10, border: '5px solid #FFF', alignSelf: 'center', marginBottom: 15, objectFit: 'cover'}}/>                                                        
                            }           
                            
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <Button variant="outlined" style={{width: '100%', marginBottom: 15}} onClick={() => {
                                    document.getElementById("uploadBannerInput").click();
                                }}>
                                    Upload New
                                </Button>    
                                <Button variant="outlined" style={{width: '100%'}} onClick={() => {
                                    (async () => {
                                        this.setState({accountSettingsBannerError: ''});
                                        this.setState({accountSettingsModalView: 'loading'});

                                        const userDocRef = doc(this.state.db, "Users", this.state.auth.currentUser['email'])
                                        const userDocRefSnap = await getDoc(userDocRef);
    
                                        if(userDocRefSnap.data()['banner'] != ""){
                                            const storage = getStorage()
                                            this.setState({accountSettingsModalView: 'loading'});
                                            await updateDoc(doc(this.state.db, "Users", this.state.currentUserEmail), {
                                                banner: "",
                                            }).then(() => {
                                                const desertRef = ref(storage, 'ProfileBanners/' + userDocRefSnap.data()['banner']);
                                                deleteObject(desertRef).then(() => {
                                                    this.setState({currentUserBanner: ''});
                                                    this.setState({accountSettingsModalView: 'bannerUpdate'});
                                                    this.showSnackbar("Updated your banner");
                                                }).catch((error) => {
                                                    this.setState({accountSettingsBannerError: 'There was an issue updating your banner'});
                                                    this.setState({accountSettingsModalView: 'bannerUpdate'})
                                                });                                            
                                            }).catch((error) => {
                                                this.setState({accountSettingsBannerError: 'There was an issue updating your banner'});
                                                this.setState({accountSettingsModalView: 'bannerUpdate'})
                                            })
                                        }else{
                                            this.setState({accountSettingsModalView: 'bannerUpdate'})
                                        }
                                    })()
                                }}>
                                    Remove 
                                </Button>       
                                {this.state.accountSettingsBannerError != "" ? 
                                    <p style={{marginTop: 5, color: 'red'}}>{this.state.accountSettingsBannerError}</p>
                                    :
                                    null
                                }                                                 
                            </div>
                        </div>
                                
                    </Box> 
                );
            case 'profilePictureUpdate':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '75%', padding: 0, margin: 0}}>
                                <h2 style={{margin: 0}}>Update Profile Picture</h2>
                            </div>
                            <div style={{width: '25%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                <IconButton onClick={() => {
                                    this.setState({accountSettingsModalOpen: false});
                                    this.setState({accountSettingsModalView: 'main'});
                                    this.setState({accountSettingsProfilePictureError: ''});
                                }}>
                                    <CloseIcon/>
                                </IconButton>                                
                            </div>                            
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <input
                                id="uploadProfilePictureInput"
                                style={{display: 'none'}}
                                type="file"
                                accept="image/*"
                                name="profilePicture"
                                onChange={(event) => {
                                    this.setState({accountSettingsProfilePictureError: ''});
                                    this.setState({accountSettingsModalView: 'loading'});
                                    let newProfilePictureImageID = event.target.files[0].name
                                    let newProfilePictureImageFile = event.target.files[0]
                                    let newProfilePictureImageModTimestamp = event.target.files[0].lastModified;
                                    const storage = getStorage()
                                    let newProfilePictureImagePath = "ProfilePictures/" + newProfilePictureImageModTimestamp + "-" + newProfilePictureImageID;
                                    let newProfilePictureImagePathForFirestore = newProfilePictureImageModTimestamp + "-" + newProfilePictureImageID;
                                    const newProfilePictureImageStorageRef = ref(storage, newProfilePictureImagePath)
                                    uploadBytes(newProfilePictureImageStorageRef, newProfilePictureImageFile).then((snapshot) => {
                                        (async () => {  
                                            await updateDoc(doc(this.state.db, "Users", this.state.currentUserEmail), {
                                                profilePicture: newProfilePictureImagePathForFirestore,
                                            }).then(() => {
                                                this.showSnackbar("Updated your profile picture");
                                                this.setState({accountSettingsProfilePictureUpdate: true})
                                                this.setState({currentUserProfilePicture: event.target.files[0]})    
                                                this.setState({accountSettingsModalView: 'profilePictureUpdate'})
                                            }).catch((error) => {
                                                this.setState({accountSettingsProfilePictureUpdate: false});
                                                this.setState({accountSettingsProfilePictureError: 'There was an issue updating your profile picture'});
                                                this.setState({accountSettingsModalView: 'profilePictureUpdate'})
                                            })
                                        })()
                                    }).catch((error) => {
                                        this.setState({accountSettingsProfilePictureUpdate: false});
                                        this.setState({accountSettingsProfilePictureError: 'There was an issue updating your profile picture'});
                                        this.setState({accountSettingsModalView: 'profilePictureUpdate'})
                                    });                                      
                                    
                                }}
                            />              
                            {this.state.currentUserProfilePicture != "" ?
                                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                    {this.state.accountSettingsProfilePictureUpdate == true ? 
                                        <img draggable={false} src={URL.createObjectURL(this.state.currentUserProfilePicture)} style={{height: 200, width: 200, borderRadius: 10, border: '5px solid #FFF', alignSelf: 'center', marginBottom: 15, objectFit: 'cover'}}/>                        
                                        :
                                        <img draggable={false} src={this.state.currentUserProfilePicture} style={{height: 200, width: 200, borderRadius: 10, border: '5px solid #FFF', alignSelf: 'center', marginBottom: 15, objectFit: 'cover'}}/>                        
                                    }
                                </div>   
                                :
                                <div style={{height: 200, width: 200, borderRadius: 10, border: '5px solid #FFF', backgroundColor: '#1976d2', display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center', justifyItems: 'center', marginBottom: 10, alignSelf: 'center'}}>
                                    <h2 style={{padding: 0, margin: 0, marginRight: 5, color: '#FFF', fontSize: 100}}>{this.state.currentUserFirstName[0]}</h2>
                                    <h2 style={{padding: 0, margin: 0, color: '#FFF', fontSize: 100}}>{this.state.currentUserLastName[0]}</h2>
                                </div>

                            }           
                            
                            <div style={{display: 'flex', flexDirection: 'column', marginLeft: 15}}>
                                <Button variant="outlined" style={{width: '100%', marginBottom: 15}} onClick={() => {
                                    document.getElementById("uploadProfilePictureInput").click();
                                }}>
                                    Upload New
                                </Button>    
                                <Button variant="outlined" style={{width: '100%'}} onClick={() => {
                                    (async () => {
                                        this.setState({accountSettingsProfilePictureError: ''});
                                        this.setState({accountSettingsModalView: 'loading'});
                                        const userDocRef = doc(this.state.db, "Users", this.state.auth.currentUser['email'])
                                        const userDocRefSnap = await getDoc(userDocRef);
    
                                        if(userDocRefSnap.data()['profilePicture'] != ""){
                                            const storage = getStorage()
                                            this.setState({accountSettingsModalView: 'loading'});
                                            await updateDoc(doc(this.state.db, "Users", this.state.currentUserEmail), {
                                                profilePicture: "",
                                            }).then(() => {
                                                const desertRef = ref(storage, 'ProfilePictures/' + userDocRefSnap.data()['profilePicture']);
                                                deleteObject(desertRef).then(() => {
                                                    this.showSnackbar("Updated your profile picture");
                                                    this.setState({currentUserProfilePicture: ''});
                                                    this.setState({accountSettingsModalView: 'profilePictureUpdate'});
                                                }).catch((error) => {
                                                    this.setState({accountSettingsProfilePictureError: 'There was an issue updating your profile picture'});
                                                    this.setState({accountSettingsModalView: 'profilePictureUpdate'})
                                                });                                            
                                            }).catch((error) => {
                                                this.setState({accountSettingsProfilePictureError: 'There was an issue updating your profile picture'});
                                                this.setState({accountSettingsModalView: 'profilePictureUpdate'})
                                            })
                                        }else{
                                            this.setState({accountSettingsModalView: 'profilePictureUpdate'})
                                        }
                                    })()
                                }}>
                                    Remove 
                                </Button>            
                                {this.state.accountSettingsProfilePictureError != "" ? 
                                    <p style={{marginTop: 5, color: 'red'}}>{this.state.accountSettingsProfilePictureError}</p>
                                    :
                                    null
                                }                                              
                            </div>
                        </div>
                                
                    </Box>                       
                );                
        }
    }

    ClassSettingsModalView = () => {
        switch(this.state.classSettingsModalView){
            case 'loading':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 15, paddingBottom: 15, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}}>
                        <CircularProgress />
                        <h2>Loading</h2>
                    </Box>                     
                );
            case 'main':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '50%', padding: 0, margin: 0}}>
                                <h2 style={{margin: 0}}>{this.state.classSettingsName}</h2>
                            </div>
                            <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                <IconButton onClick={() => {
                                    this.setState({classSettingsModalOpen: false});
                                }}>
                                    <CloseIcon/>
                                </IconButton>                                
                            </div>                            
                        </div>
                        <Button variant="outlined" style={{display: 'flex', flexDirection: 'column'}}  onClick={() => {
                            navigator.clipboard.writeText(this.state.classSettingsID).then(() => {
                                this.showSnackbar("Copied class ID to your clipboard")
                            });
                        }}>
                            <p style={{margin: 0, padding: 0}}>Copy Class ID</p>
                            <p style={{margin: 0, padding: 0, textTransform: 'lowercase'}}>{this.state.classSettingsID}</p>
                        </Button>     

                        <Button variant="outlined" style={{display: 'flex', flexDirection: 'column', marginTop: 15}}  onClick={() => {
                            navigator.clipboard.writeText(this.state.classSettingsJoinLink).then(() => {
                                this.showSnackbar("Copied class join link to your clipboard")
                            });
                        }}>
                            <p style={{margin: 0, padding: 0}}>Copy Class Mobile Join Link</p>
                            <p style={{margin: 0, padding: 0, textTransform: 'lowercase'}}>{this.state.classSettingsJoinLink}</p>
                        </Button>                                              

                        <Button style={{marginTop: 15}} variant="outlined"onClick={() => {
                            (async () => {      
                                this.setState({classSettingsModalView: 'loading'});
                                let classMembersList = [];
                                const classDocRefString =  "Classes/" + this.state.classSettingsID;
                                const classDocRef = doc(this.state.db, classDocRefString);
                                const classDocSnap = await getDoc(classDocRef).catch((error) => {});
                                if(classDocSnap.exists){
                                    for(let m = 0; m < classDocSnap.data()['peers'].length; m++){
                                        const memberDocRefString = "Users/" + classDocSnap.data()['peers'][m];
                                        const memberDocRef = doc(this.state.db, memberDocRefString);
                                        const memberDocSnap = await getDoc(memberDocRef).catch((error) => {});
                                        if(memberDocSnap.exists){
                                            classMembersList.push({
                                                memberID: classDocSnap.data()['peers'][m],
                                                memberFName: memberDocSnap.data()['fName'],
                                                memberLName: memberDocSnap.data()['lName'],
                                                memberRole: memberDocSnap.data()['role'],
                                            });
                                            this.setState({classSettingsMembers: classMembersList})                                            
                                        }   
                                    }
                                    this.setState({classSettingsModalView: 'classMembers'})
                                }else{
                                    this.setState({classSettingsModalView: 'main'})
                                }
                            })().catch((error) => {});                        
                        }}>
                            View Class Members
                        </Button>    
                        {this.state.currentUserRole == 'student' ? 
                            <Button variant="outlined" style={{marginTop: 15}} onClick={() => {
                                this.setState({classSettingsModalView: 'leaveClassConfirmation'});
                            }}>
                                Leave Class
                            </Button>     
                            :
                            null                          
                        }
                        {this.state.currentUserRole == 'instructor' ? 
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <Button variant="outlined" style={{marginTop: 15}} onClick={() => {
                                    this.setState({classSettingsModalView: 'bannerUpdate'});
                                }}>
                                    Update Class Banner
                                </Button>   
                                <Button variant="outlined" style={{marginTop: 15}} onClick={() => {
                                    this.setState({classSettingsModalView: 'classNameUpdate'});
                                }}>
                                    Update Class Name
                                </Button>              
                                {this.state.classSettingsOpen == false ? 
                                    <Button variant="outlined" style={{marginTop: 15}} onClick={() => {
                                        (async () => {
                                            this.setState({classSettingsModalView: 'loading'});
                                            await updateDoc(doc(this.state.db, "Classes", this.state.classSettingsID), {
                                                open: true
                                            }).then(() => {
                                                this.setState({classSettingsOpen: true});
                                                this.setState({classSettingsModalView: 'main'});
                                                this.showSnackbar("Opened class for new students")
                                            }).catch((error) => {
                                                this.setState({classSettingsModalView: 'main'});
                                            });
                                        })().catch((error) => {});
                                    }}>
                                        Open Class For New Students
                                    </Button>                                   
                                    :
                                    <Button variant="outlined" style={{marginTop: 15}} onClick={() => {
                                        (async () => {
                                            this.setState({classSettingsModalView: 'loading'});
                                            await updateDoc(doc(this.state.db, "Classes", this.state.classSettingsID), {
                                                open: false
                                            }).then(() => {
                                                this.setState({classSettingsOpen: false});
                                                this.setState({classSettingsModalView: 'main'});
                                                this.showSnackbar("Closed class for new students")
                                            }).catch((error) => {
                                                this.setState({classSettingsModalView: 'main'});
                                            });
                                        })().catch((error) => {});
                                    }}>
                                        Close Class For New Students
                                    </Button>                                       
                                }         

                                {this.state.classSettingsPostingEnabled == false ? 
                                    <Button variant="outlined" style={{marginTop: 15}} onClick={() => {
                                        (async () => {
                                            this.setState({classSettingsModalView: 'loading'});
                                            await updateDoc(doc(this.state.db, "Classes", this.state.classSettingsID), {
                                                postingEnabled: true
                                            }).then(() => {
                                                this.setState({classSettingsPostingEnabled: true});
                                                this.setState({classSettingsModalView: 'main'});
                                                this.showSnackbar("Enabled posting")
                                            }).catch((error) => {
                                                this.setState({classSettingsModalView: 'main'});
                                            });
                                        })().catch((error) => {});
                                    }}>
                                        Enable Posting
                                    </Button>                                   
                                    :
                                    <Button variant="outlined" style={{marginTop: 15}} onClick={() => {
                                        (async () => {
                                            this.setState({classSettingsModalView: 'loading'});
                                            await updateDoc(doc(this.state.db, "Classes", this.state.classSettingsID), {
                                                postingEnabled: false
                                            }).then(() => {
                                                this.setState({classSettingsPostingEnabled: false});
                                                this.setState({classSettingsModalView: 'main'});
                                                this.showSnackbar("Disabled posting")
                                            }).catch((error) => {
                                                this.setState({classSettingsModalView: 'main'});
                                            });
                                        })().catch((error) => {});
                                    }}>
                                        Disable Posting
                                    </Button>                                       
                                }                                                                    
                                <Button variant="outlined" style={{marginTop: 15}} onClick={() => {
                                    this.setState({classSettingsModalView: 'deleteClassConfirmation'});
                                }}>
                                    Delete Class
                                </Button>                                   
                            </div>
                            :
                            null                      
                        }                     
                                           
                    </Box>                     
                );
            case 'classMembers':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '50%', padding: 0, margin: 0}}>
                                <h2 style={{margin: 0}}>Class Members</h2>
                            </div>
                            <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                <IconButton onClick={() => {
                                    this.setState({classSettingsModalOpen: false});
                                    this.setState({classSettingsModalView: 'main'});
                                    this.setState({classSettingsID: ""});
                                    this.setState({classSettingsMembers: []});
                                    this.setState({classSettingsName: ""});

                                }}>
                                    <CloseIcon/>
                                </IconButton>                                
                            </div>                            
                        </div>    

                        <ul style={{listStyle: 'none', listStyleType: 'none', padding: 0, margin: 0}}>
                            <FlatList
                                list={this.state.classSettingsMembers}
                                renderItem={this.renderClassSettingsMembers}
                                renderWhenEmpty={() => <div>No Members</div>}
                            />
                        </ul>                                                
                    </Box>                     
                );
            case 'bannerUpdate':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '75%', padding: 0, margin: 0}}>
                                <h2 style={{margin: 0}}>Update Banner</h2>
                            </div>
                            <div style={{width: '25%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                <IconButton onClick={() => {
                                    this.setState({classSettingsModalOpen: false});
                                    this.setState({classSettingsModalView: 'main'});
                                    this.setState({classSettingsBannerError: ""});
                                }}>
                                    <CloseIcon/>
                                </IconButton>                                
                            </div>                            
                        </div>
                        <ul style={{marginTop: 5, width: '100%', display: 'inline-block', overflow: 'auto', maxWidth: '100%', whiteSpace: 'nowrap', padding: 0, height: 200}}>
                            <FlatList
                                list={this.state.newClassBanners}
                                renderItem={this.renderUpdateClassBanners}
                                renderWhenEmpty={() => <div/>}
                            />
                        </ul>      
                        <Button variant="outlined" style={{width: '100%', marginBottom: 15}} onClick={() => {
                            (async () => {
                                this.setState({classSettingsModalView: 'loading'});
                                await updateDoc(doc(this.state.db, "Classes", this.state.classSettingsID), {
                                    banner: this.state.classSettingsBanner
                                }).then(() => {
                                    this.showSnackbar("Updated class banner");
                                    this.setState({classSettingsModalView: 'main'});
                                    this.setState({classSettingsBannerError: ''});
                                    this.setState({classSettingsModalOpen: false});
                                    this.getCurrentUser();
                                }).catch((error) => {
                                    this.setState({classSettingsModalView: 'bannerUpdate'});
                                    this.setState({classSettingsBannerError: 'There was an error updating class banner'});
                                });
                            })().catch((error) => {});
                        }}>
                            Update Banner
                        </Button>                                               
                                
                    </Box> 
                );              
            case 'classNameUpdate':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '50%', padding: 0, margin: 0}}>
                                <h2 style={{margin: 0}}>Update Class Name</h2>
                            </div>
                            <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                <IconButton onClick={() => {
                                    this.setState({classSettingsModalOpen: false});
                                    this.setState({classSettingsModalView: 'main'});
                                    this.setState({classSettingsUpdateNameError: ''});
                                    this.setState({classSettingsID: ''});
                                }}>
                                    <CloseIcon/>
                                </IconButton>                                
                            </div>                            
                        </div>   
                        <TextField id="filled-basic" label="Class Name" value={this.state.classSettingsName} onChange={(text) => {this.setState({classSettingsName: text.target.value})}} variant="filled" />                                                                        
                        {this.state.classSettingsUpdateNameError != ""  ? 
                            <p style={{padding: 0, margin: 0, marginTop: 5, color: 'red'}}>{this.state.classSettingsUpdateNameError}</p>
                            :
                            null
                        }
                        {this.state.classSettingsName.trim().length > 0 ?
                            <Button variant="outlined" style={{marginTop: 15}} onClick={() => {
                                (async () => {
                                    this.setState({classSettingsModalView: 'loading'})
                                    await updateDoc(doc(this.state.db, "Classes", this.state.classSettingsID), {
                                        name: this.state.classSettingsName.trim()
                                    }).then(() => {
                                        this.showSnackbar("Updated class name");
                                        this.setState({classSettingsUpdateNameError: ''})
                                        this.setState({classSettingsModalOpen: false});
                                        this.setState({classSettingsModalView: 'main'});    
                                        this.getCurrentUser();
                                    }).catch((error) => {
                                        this.setState({classSettingsUpdateNameError: 'There was an issue updating class name'});
                                        this.setState({classSettingsModalView: 'main'});  
                                    });
                                })().catch((error) => {});
                            }}>
                                Update
                            </Button>  
                            :
                            <Button disabled={true} variant="outlined" style={{marginTop: 15}}>
                                Update
                            </Button>                          
                        }

                    </Box>   
                );
            case 'leaveClassConfirmation':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '75%', padding: 0, margin: 0}}>
                                <h2 style={{margin: 0}}>Leave Class Confirmation</h2>
                            </div>
                            <div style={{width: '25%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                <IconButton onClick={() => {
                                    this.setState({classSettingsModalOpen: false});
                                    this.setState({classSettingsModalView: 'main'});
                                    this.setState({classSettingsLeaveConfirmationText: ""});
                                    this.setState({classSettingsLeaveError: ""});
                                    this.setState({classSettingsID: ""});
                                }}>
                                    <CloseIcon/>
                                </IconButton>                                
                            </div>                            
                        </div>
                        <p style={{margin: 0, padding: 0, color: '#AAA', marginBottom: 15}}>Type 'leave' below and submit to leave class</p>
                        <TextField style={{}} id="filled-basic" label="Confirmation" value={this.state.classSettingsLeaveConfirmationText} onChange={(text) => {this.setState({classSettingsLeaveConfirmationText: text.target.value})}} variant="filled" />                                                
                        {this.state.classSettingsLeaveError != "" ? 
                            <p style={{color: 'red', margin: 0, padding: 0, marginTop: 5}}>{this.state.classSettingsLeaveError}</p>    
                            :
                            null
                        }
                        {this.state.classSettingsLeaveConfirmationText.trim().toLowerCase() == 'leave' ?
                            <Button style={{marginTop: 10}} variant="contained" onClick={() => {
                                this.leaveClass();
                            }}>
                                <p style={{margin: 0, padding: 0, paddingTop: 5}}>Leave</p>
                            </Button>                           
                            :
                            <Button style={{marginTop: 10}} variant="contained" disabled="true">
                                <p style={{margin: 0, padding: 0, paddingTop: 5}}>Leave</p>
                            </Button>                               
                        }                         


                    
                    </Box>                    
                );
            case 'deleteClassConfirmation':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '75%', padding: 0, margin: 0}}>
                                <h2 style={{margin: 0}}>Delete Class Confirmation</h2>
                            </div>
                            <div style={{width: '25%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                <IconButton onClick={() => {
                                    this.setState({classSettingsModalOpen: false});
                                    this.setState({classSettingsModalView: 'main'});
                                    this.setState({classSettingsDeleteError: ''});
                                    this.setState({classSettingsDeleteConfirmationText: ''});
                                }}>
                                    <CloseIcon/>
                                </IconButton>                                
                            </div>                            
                        </div>
                        <p style={{margin: 0, padding: 0, color: '#AAA', marginBottom: 15}}>Type 'delete' below and submit to delete class</p>
                        <TextField color="error" style={{}} id="filled-basic" label="Confirmation" value={this.state.classSettingsDeleteConfirmationText} onChange={(text) => {this.setState({classSettingsDeleteConfirmationText: text.target.value})}} variant="filled" />                                                
                        {this.state.classSettingsDeleteError != "" ? 
                            <p style={{color: 'red', margin: 0, padding: 0, marginTop: 5}}>{this.state.classSettingsDeleteError}</p>    
                            :
                            null
                        }
                        {this.state.classSettingsDeleteConfirmationText.trim().toLowerCase() == 'delete' ?
                            <Button color="error" style={{marginTop: 10}} variant="outlined" onClick={() => {
                                this.deleteClass();
                            }}>
                                <p style={{margin: 0, padding: 0, paddingTop: 5}}>Delete</p>
                            </Button>                           
                            :
                            <Button color="error" style={{marginTop: 10}} variant="outlined" disabled="true">
                                <p style={{margin: 0, padding: 0, paddingTop: 5}}>Delete</p>
                            </Button>                               
                        }                         


                    
                    </Box>                       
                )
        }


    }

    PostSettingsModalView = () => {
        switch(this.state.postSettingsModalView){
            case 'loading':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 15, paddingBottom: 15, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}}>
                        <CircularProgress />
                        <h2>Loading</h2>
                    </Box>                     
                );
            case 'main':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '50%', padding: 0, margin: 0}}>
                                <h2 style={{margin: 0}}>Question Settings</h2>
                            </div>
                            <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                <IconButton onClick={() => {
                                    this.setState({postSettingsModalOpen: false});
                                }}>
                                    <CloseIcon/>
                                </IconButton>                                
                            </div>                            
                        </div>
                        <Button variant="outlined" style={{marginBottom: 15}}  onClick={() => {
                            this.setState({postSettingsModalView: 'updatePostContent'})
                        }}>
                            Edit Question Content
                        </Button>                             
                    </Box>                     
                );
            case 'updatePostContent':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '75%', padding: 0, margin: 0}}>
                                <h2 style={{margin: 0}}>Edit Question Content</h2>
                            </div>
                            <div style={{width: '25%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                <IconButton onClick={() => {
                                    this.setState({postSettingsModalOpen: false});
                                    this.setState({postSettingsModalView: 'main'});

                                }}>
                                    <CloseIcon/>
                                </IconButton>                                
                            </div>                 
                        </div>    
                        <TextField style={{marginBottom: 15}} id="filled-basic" label="Title" value={this.state.postSettingsTitle} onChange={(text) => {this.setState({postSettingsTitle: text.target.value})}} variant="filled" />                        

                        <TextField style={{}} id="filled-basic" label="Text Content" value={this.state.postSettingsTextContent} onChange={(text) => {this.setState({postSettingsTextContent: text.target.value})}} variant="filled" />                                                
                        {this.state.postSettingsContentUpdateError != "" ? 
                            <p style={{color: 'red', margin: 0, padding: 0, marginTop: 5}}>{this.state.postSettingsContentUpdateError}</p>    
                            :
                            null
                        }
                        {this.state.postSettingsTitle.trim().length > 0 && this.state.postSettingsTextContent.trim().length > 0 ?
                            <Button style={{marginTop: 10}} variant="contained" onClick={() => {
                                (async () => { 
                                    this.setState({postSettingsModalView: 'loading'});
                                    let questionToUpdateRefString = "Classes/" + this.state.postSettingsClassID + "/Feed/" + this.state.postSettingsPostID;
                                    await updateDoc(doc(this.state.db, questionToUpdateRefString), {
                                        title: this.state.postSettingsTitle,
                                        textContent: this.state.postSettingsTextContent
                                    }).then(() => {
                                        this.setState({postSettingsModalView: 'updatePostContent'});
                                        console.log('updated question content');
                                    }).catch((error) => {
                                        this.setState({postSettingsModalView: 'updatePostContent'});
                                        console.log('error updating question content');
                                    })
                                })()
                            }}>
                                <p style={{margin: 0, padding: 0, paddingTop: 5}}>Update</p>
                            </Button>                           
                            :
                            <Button style={{marginTop: 10}} variant="contained" disabled="true">
                                <p style={{margin: 0, padding: 0, paddingTop: 5}}>Update</p>
                            </Button>                               
                        }                                              
                    </Box>                     
                );
            case 'deletePostConfirmation':
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                            <div style={{width: '75%', padding: 0, margin: 0}}>
                                <h2 style={{margin: 0}}>Delete Post</h2>
                            </div>
                            <div style={{width: '25%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                <IconButton onClick={() => {
                                    this.setState({postSettingsModalOpen: false});
                                    this.setState({postSettingsModalView: 'main'});
                                }}>
                                    <CloseIcon/>
                                </IconButton>                                
                            </div>                            
                        </div>
                        <p style={{margin: 0, padding: 0, color: '#AAA', marginBottom: 15}}>Type 'confirm' below and submit to delete this post</p>
                        <TextField style={{}} id="filled-basic" label="Confirmation" value={this.state.postSettingsDeleteConfirmationTextContent} onChange={(text) => {this.setState({postSettingsDeleteConfirmationTextContent: text.target.value})}} variant="filled" />                                                
                        {this.state.postSettingsDeleteConfirmationError != "" ? 
                            <p style={{color: 'red', margin: 0, padding: 0, marginTop: 5}}>{this.state.postSettingsDeleteConfirmationError}</p>    
                            :
                            null
                        }
                        {this.state.postSettingsDeleteConfirmationTextContent.trim().toLowerCase() == 'confirm' ?
                            <Button style={{marginTop: 10}} variant="contained" onClick={() => {
                                this.deletePost(this.state.postSettingsClassID, this.state.postSettingsPostID);
                            }}>
                                <p style={{margin: 0, padding: 0, paddingTop: 5}}>Delete</p>
                            </Button>                           
                            :
                            <Button style={{marginTop: 10}} variant="contained" disabled="true">
                                <p style={{margin: 0, padding: 0, paddingTop: 5}}>Delete</p>
                            </Button>                               
                        }                         


                    
                    </Box> 
                );
        }
    }

    
    render(){
        if(!isMobile){
            const drawerWidth = 240;
            const AppBar = styled(MuiAppBar, {
                shouldForwardProp: (prop) => prop !== 'open',
            })(({ theme, open }) => ({
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            ...(open && {
                marginLeft: 0,
                width: `100`,
                maxHeight: 70,
                transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
                }),
            }),
            }));   
            const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
                ({ theme, open }) => ({
                    '& .MuiDrawer-paper': {
                    position: 'relative',
                    whiteSpace: 'nowrap',
                    width: drawerWidth,
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                    boxSizing: 'border-box',
                    ...(!open && {
                        overflowX: 'scroll',
                        transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                        }),
                        width: theme.spacing(7),
                        [theme.breakpoints.up('sm')]: {
                        width: theme.spacing(9),
                        },
                    }),
                    },
                }),
            );        
            const mdTheme = createTheme();
                
            if(this.state.auth.currentUser.emailVerified){
                return(
                    <ThemeProvider theme={mdTheme}>
                        <ImgsViewer
                            imgs={this.state.imageViewerImages}
                            currImg={this.state.imageViewerCurrentImage}
                            isOpen={this.state.imageViewerOpen}
                            onClickPrev={() => {
                                this.setState({imageViewerCurrentImage: this.state.imageViewerCurrentImage - 1})
                            }}
                            onClickNext={() => {
                                this.setState({imageViewerCurrentImage: this.state.imageViewerCurrentImage + 1})                        
                            }}
                            onClose={() => {
                                this.setState({imageViewerOpen: false});
                                this.setState({imageViewerImages: []});
                                this.setState({imageViewerCurrentImage: 0});
                            }}
                        />
    
                        <Modal  aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={this.state.tutorialModalOpen} 
                        onClose={(event, reason) => {
                            (async () => {
                                await updateDoc(doc(this.state.db, "Users", this.state.auth.currentUser['email']), {
                                    tutorialCompleted: true,
                                }).catch((error) => {});
                            })().catch((error) => {});

                            if (reason !== 'backdropClick') {
                                this.setState({tutorialModalOpen: false})
                            }       
                        }} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500,}} >
    
                            <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                                    <div style={{width: '50%', padding: 0, margin: 0}}>
                                        <h1 style={{margin: 0}}>Welcome!</h1>
                                    </div>
                                    <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                        <IconButton onClick={() => {
                                            this.setState({tutorialModalOpen: false});
                                            (async () => {
                                                await updateDoc(doc(this.state.db, "Users", this.state.auth.currentUser['email']), {
                                                    tutorialCompleted: true,
                                                }).catch((error) => {});
                                            })().catch((error) => {});                                            
                                        }}>
                                            <CloseIcon/>
                                        </IconButton>                                
                                    </div>                            
                                </div>    
    
                                <div style={{width: '100%', backgroundColor: '#FFF', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', flexDirection: 'column', marginTop: 25}}>
                                    <img draggable={false} src={tutorialIcon1} style={{width: 100, height: 100, objectFit: 'cover', marginBottom: 5}}/>
                                    {this.state.currentUserRole == 'student' ? 
                                        <p style={{padding: 0, margin: 0, textAlign: 'center', paddingRight: 15, paddingLeft: 15}}>Start by adding your first class using the button at the top right</p>
                                        :
                                        <p style={{padding: 0, margin: 0, textAlign: 'center', paddingRight: 15, paddingLeft: 15}}>Start by creating your first class using the button at the top right</p>
                                    }
                                    
    
                                    <img draggable={false} src={tutorialIcon2} style={{width: 100, height: 100, objectFit: 'cover', marginBottom: 5, marginTop: 50}}/>                                
    
                                    {this.state.currentUserRole == 'student' ? 
                                        <p style={{padding: 0, margin: 0, textAlign: 'center', paddingRight: 15, paddingLeft: 15}}>Ask your questions and help your peers with theirs!</p>                                
                                        :
                                        <p style={{padding: 0, margin: 0, textAlign: 'center', paddingRight: 15, paddingLeft: 15}}>Monitor student inquiries through individual class forums!</p>
                                    }
                                    
    
                                    <img draggable={false} src={logo} style={{width: 100, height: 100, objectFit: 'cover', backgroundColor: '#FFF', borderRadius: 10, boxShadow: '0 0 3px 3px #ededed', margin: 0, padding: 0, marginTop: 50, marginBottom: 10}}/>                                                                                                                        
                                    <p style={{padding: 0, margin: 0, textAlign: 'center', paddingRight: 15, paddingLeft: 15, fontSize: 20, fontWeight: 'bold', marginBottom: 5}}>Peer to Peer Mobile</p>                                
                                    <p style={{padding: 0, margin: 0, textAlign: 'center', paddingRight: 15, paddingLeft: 15}}>Find us on the App Store. Stay notified whenever, wherever!</p>
                                </div>
    
                                        
                            </Box>                                              
                                                        
                        </Modal>  

                        <Modal  aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={this.state.attributionsModalOpen} 
                        onClose={(event, reason) => {

                            if (reason !== 'backdropClick') {
                                this.setState({attributionsModalOpen: false})
                            }       
                        }} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500,}} >
    
                            <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                                    <div style={{width: '50%', padding: 0, margin: 0}}>
                                        <h2 style={{margin: 0}}>Attributions</h2>
                                    </div>
                                    <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                        <IconButton onClick={() => {
                                            this.setState({attributionsModalOpen: false});
                                                                                   
                                        }}>
                                            <CloseIcon/>
                                        </IconButton>                                
                                    </div>                            
                                </div>    
    
                                <div style={{width: '100%', backgroundColor: '#FFF', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', flexDirection: 'column', marginTop: 25}}>
                                    <a href="https://storyset.com" target={"_blank"}>illustrations by Storyset</a>                                    
                                </div>
    
                                        
                            </Box>                                              
                                                        
                        </Modal>                               
    
                        <Modal  aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={this.state.viewSolutionsModalOpen} 
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {
                                this.setState({viewSolutionsModalOpen: false})
                            }       
                        }} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500,}} >
                            {this.state.viewSolutionsModalView == 'loading' ? 
                                <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 5, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}}>
                                    <CircularProgress />
                                    <h2>Loading</h2>
                                </Box>                     
                                :
                                <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', boxShadow: 24, paddingTop: 4, paddingBottom: 4, paddingRight: 3, paddingLeft: 3}}>
                                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0, marginBottom: 15}}>
                                        <div style={{width: '50%', padding: 0, margin: 0}}>
                                            <h2 style={{margin: 0}}>Post Solutions ({this.state.focusedPostSolutions.length})</h2>
                                        </div>
                                        <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', padding: 0, margin: 0}}>
                                            <IconButton onClick={() => {
                                                this.setState({viewSolutionsModalOpen: false});
                                                this.setState({viewSolutionsModalView: 'loading'});
                                            }}>
                                                <CloseIcon/>
                                            </IconButton>                                
                                        </div>                            
                                    </div>    
        
                                    <ul style={{margin: 0, marginTop: 5, marginBottom: 0, padding: 10, listStyle: 'none', listStyleType: 'none', height: 400, overFlow: 'scroll', overflowY: 'scroll'}}>                        
                                        <FlatList
                                            style={{margin: 0, padding: 0, paddingRight: 10, paddingLeft: 10}}
                                            list={this.state.viewSolutionsModalFullData}
                                            renderItem={this.renderFocusedPostSolutions}
                                            renderWhenEmpty={() => <div>No Solutions</div>}
                                        />
                                    </ul>         
                                </Box>                                              
                            }                             
                        </Modal>  
        


                        <Modal  aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={this.state.newModalOpen} 
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {
                                this.setState({newModalOpen: false})
                            }       
                        }} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500,}}>
                            <this.NewModalView/>                              
                        </Modal>  


                        <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={this.state.newKnowledgeBitModalOpen} 
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {
                                this.setState({newKnowledgeBitModalOpen: false})
                            }
                        }} 
                        closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500,}}>
                            {/* <Fade in={this.state.newModalOpen}> */}
                            <this.NewKnowledgeBitModaView/>   
                            {/* </Fade> */}
                        </Modal>    
        
                        {/* NEW CHILD KNOWLEDGE BIT */}
                        <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={this.state.newChildKnowledgeBitModalOpen} 
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {
                                this.setState({newChildKnowledgeBitModalOpen: false})
        
                            }
                        }} 
                        closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500,}}>
                            {/* <Fade in={this.state.newModalOpen}> */}
                            <this.NewChildKnowledgeBitModaView/>   
                            {/* </Fade> */}
                        </Modal>    
        
                        {/* Account settings modal */}
                        <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={this.state.accountSettingsModalOpen} 
                        onClose={(event, reason) => {
                        }} 
                        closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500,}}>
                            {/* <Fade in={this.state.newModalOpen}> */}
                            <this.AccountSettingsModalView/>   
                            {/* </Fade> */}
                        </Modal>    
        
                        <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={this.state.classSettingsModalOpen} 
                        onClose={(event, reason) => {
                        }} 
                        closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500,}}>
                            {/* <Fade in={this.state.newModalOpen}> */}
                            <this.ClassSettingsModalView/>   
                            {/* </Fade> */}
                        </Modal>    
                         
                        <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={this.state.postSettingsModalOpen} 
                        onClose={(event, reason) => {
                        }} 
                        closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500,}}>
                            {/* <Fade in={this.state.newModalOpen}> */}
                            <this.PostSettingsModalView/>   
                            {/* </Fade> */}
                        </Modal>                    
        
        
        
                        {this.state.settingSignedInView ?
                            <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', paddingTop: 5, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}}>
                                <CircularProgress />
                                <h2>Loading</h2>
                            </Box> 
                            :
                            <Box sx={{ display: 'flex', backgroundColor: '#f5f5f5'}}>
                                <Snackbar
                                    
                                    anchorOrigin={{ vertical: 'top' , horizontal: 'right' }}
                                    open={this.state.snackbarOpen}
                                    onClose={() => {}}
                                    message={this.state.snackbarText}
                                    ContentProps={{
                                        sx: {
                                            background: '#20ab1d',
                                            fontWeight: 600,
                                        }
                                    }}
                                />                        
                                <CssBaseline />
                                <AppBar position="absolute" open={true}>
                                    <Toolbar sx={{pr: '24px',}}>
                                        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                                            <h2>Peer to Peer</h2>
                                        </Typography>
                                        
                                        {/* <button onClick={() => {
        
                                            this.showSnackbar("Hello world");
                                        }}>
                                            <p>send</p>
                                        </button> */}
    
                                        {/* <Walkthrough> */}
                                            <IconButton color="inherit" onClick={() => {
                                                this.setState({newModalOpen: true})
                                                                                                                               
                                            }}>
                                                <AddIcon fontSize="large" />
                                            </IconButton>                                                                  
                                        {/* </Walkthrough> */}
        
                                    </Toolbar>
                                </AppBar>
                        
                                <Drawer style={{marginTop: 70}} variant="permanent" open={true}>
                                    {/* <Divider/> */}
                                    <List style={{padding: 0, maxHeight: 500, overflowY: 'scroll'}}>
                                        {this.state.activeTab == 'class' ?
                                            <ListItem button style={{padding: 0, margin: 0, paddingTop: 10, paddingBottom: 10, borderLeft: '5px solid #1976d2', }}>
                                                <Accordion expanded style={{width: '100%', padding: 0, margin: 0}} elevation={0}>
                                                    <AccordionSummary
                                                        style={{padding: 0, margin: 0}}
                                                    >
                                                        <ListItemIcon style={{marginLeft: 10}}>
                                                            <ClassIcon style={{color: '#1976d2'}}/>
                                                        </ListItemIcon>
                                                        <ListItemText style={{padding: 0, margin: 0}} primary="Your Classes" primaryTypographyProps={{color: '#1976d2', fontWeight: 'bold'}}/>                                            
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{padding: 0, margin: 0}}>
                                                        <ul style={{listStyle: 'none', listStyleType: 'none', padding: 0, margin: 0}}>                                                             
                                                            <FlatList
                                                                list={this.state.currentUserClasses}
                                                                renderItem={this.renderCurrentUserClasses}
                                                                renderWhenEmpty={() => <div>No Classes</div>}
                                                            />
                                                        </ul>                                              
                                                    </AccordionDetails>
                                                </Accordion>                                    
                                            </ListItem>                                 
                                            :
                                            <ListItem button style={{padding: 0, margin: 0, paddingTop: 10, paddingBottom: 10}}>
                                                <Accordion style={{width: '100%', padding: 0, margin: 0}} elevation={0}>
                                                    <AccordionSummary
                                                    style={{padding: 0, margin: 0}}
                                                    >
                                                        <ListItemIcon style={{marginLeft: 10}}>
                                                            <ClassIcon />
                                                        </ListItemIcon>
                                                        <ListItemText style={{padding: 0, margin: 0}} primary="Your Classes" />                                            
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{padding: 0, margin: 0}}>
                                                        <ul style={{listStyle: 'none', listStyleType: 'none', padding: 0, margin: 0}}>                                                          
                                                            <FlatList
                                                                list={this.state.currentUserClasses}
                                                                renderItem={this.renderCurrentUserClasses}
                                                                renderWhenEmpty={() => 
                                                                    <div style={{width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f2f2f2', margin: 0, padding: 0, borderRadius: 5, marginLeft: 15, paddingTop: 10, paddingBottom: 10}}>
                                                                        <p style={{margin: 0, padding: 0, color: '#AAA'}}>No Classes</p>
                                                                    </div>
                                                                }
                                                            />
                                                        </ul>                                              
                                                    </AccordionDetails>
                                                </Accordion>                                    
                                            </ListItem>                                     
                                        }
                                        {this.state.activeTab == 'inbox' ? 
                                            <ListItem style={{padding: 0, paddingTop: 10, paddingBottom: 10, marginTop: 5, borderLeft: '5px solid #1976d2',}} button onClick={() => {
                                                this.getInbox('new')
                                            }}>
                                                <ListItemIcon  style={{marginLeft: 10}}>
                                                    <InboxIcon style={{color: '#1976d2'}}/>
                                                </ListItemIcon>
                                                <ListItemText  primary="Inbox" primaryTypographyProps={{color: '#1976d2', fontWeight: 'bold'}} />                                
                                                {this.state.currentUserNumNewInboxItems > 0 ? 
                                                    <ListItemText  primary={this.state.currentUserNumNewInboxItems} primaryTypographyProps={{color: '#fff', fontWeight: 'bold', backgroundColor: '#1976d2', width: 30, height: 30, borderRadius: 2, textAlign: 'center', paddingTop: 0.5}} />                                
                                                    :
                                                    null
                                                }
                                            </ListItem>                                
                                            :
                                            <ListItem style={{padding: 0, paddingTop: 10, paddingBottom: 10, marginTop: 5}} button onClick={() => {
                                                this.getInbox('new')
                                            }}>
                                                <ListItemIcon  style={{marginLeft: 10}}>
                                                    <InboxIcon/>
                                                </ListItemIcon>
                                                <ListItemText style={{fontWeight: 'bold'}} primary="Inbox" />
                                                {this.state.currentUserNumNewInboxItems > 0 ? 
                                                    <ListItemText  primary={this.state.currentUserNumNewInboxItems} primaryTypographyProps={{color: '#fff', fontWeight: 'bold', backgroundColor: '#1976d2', width: 30, height: 30, borderRadius: 2, textAlign: 'center', paddingTop: 0.5}} />                                
                                                    :
                                                    null
                                                }                                        
                                            </ListItem>                                    
                                        }
                                        {this.state.activeTab == 'account' ? 
                                            <ListItem style={{padding: 0, paddingTop: 10, paddingBottom: 10, marginTop: 10, borderLeft: '5px solid #1976d2'}} button onClick={() => {
                                                this.setState({dashboardView: 'loading'});
                                                this.setState({activeTab: 'account'});
                                                this.setState({focusedClass: ''});
                                                this.getCurrentUser();
                                                
                                            }}>
                                                <ListItemIcon style={{marginLeft: 10}}>
                                                    <PersonIcon style={{color: '#1976d2'}}/>
                                                </ListItemIcon>
                                                <ListItemText primary="Profile" primaryTypographyProps={{color: '#1976d2', fontWeight: 'bold'}}/>
                                            </ListItem>      
                                            :
                                            <ListItem style={{padding: 0, paddingTop: 10, paddingBottom: 10, marginTop: 10}} button onClick={() => {
                                                this.setState({dashboardView: 'loading'});
                                                this.setState({activeTab: 'account'})
                                                this.setState({focusedClass: ''});
                                                this.getCurrentUser();
                                                
                                            }}>
                                                <ListItemIcon style={{marginLeft: 10}}>
                                                    <PersonIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Profile" />
                                            </ListItem>                                                              
                                        }
                                    </List>
                                </Drawer>
        
                                {/* focused window */}
                                <Box component="main" sx={{backgroundColor: (theme) => theme.palette.grey[100], flexGrow: 1, height: '100vh', overflow: 'auto'}}>
                                    <Toolbar/>
        
                                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <this.DashboardViewController/>
                                                {/* paper element is conditional */}
                                                {/* <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', backgroundColor: 'lime'}}>
                                                    <p>ting</p>                     
                                                </Paper> */}
        
                                            </Grid>
                                        </Grid>
                                    </Container>
                                    
                                </Box>        
                            </Box>
                        }
                    </ThemeProvider>            
                );
            }else{
                const mdTheme = createTheme();

                return(
                    <ThemeProvider theme={mdTheme}>
                        <Snackbar
                            anchorOrigin={{ vertical: 'top' , horizontal: 'right' }}
                            open={this.state.snackbarOpen}
                            onClose={() => {}}
                            message={this.state.snackbarText}
                            ContentProps={{
                                sx: {
                                    background: '#20ab1d',
                                    fontWeight: 600,
                                }
                            }}
                        />                      
                        <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', paddingTop: 5, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}}>                      
                            <MailOutlineIcon fontSize="large" style={{fontSize: 100}} />
                            <h1 style={{margin: 0, padding: 0, marginBottom: 15, paddingRight: 10, paddingLeft: 10}}>Please verify your email then refresh this page</h1>
                            <p style={{color: '#AAA', margin: 0, padding: 0, marginBottom: 25}}>Make sure to check your Spam folder or resend this email</p>
                            <Button style={{width: '100%'}} variant="contained" onClick={() => {
                                // console.log(this.state.auth.currentUser);
                                this.state.auth.currentUser.reload();
                                sendEmailVerification(this.state.auth.currentUser).then(() => {
                                    this.showSnackbar("Verification email sent!");
                                }).catch((error) => {
                                    console.log(error);
                                })
                            }}>
                                <p style={{margin: 0, padding: 0, paddingTop: 5}}>Resend to {this.state.auth.currentUser.email}</p>
                            </Button>     
    
                            <Button style={{width: '100%', marginTop: 15}} variant="outlined" onClick={() => {
                                (async () => {
                                    signOut(this.state.auth).then(() => {
                                        console.log("Signed Out");
                                    }).catch((error) => {
                                        console.log(error);
                                    });
                                })().catch((error) => {});                            
                            }}>
                                <p style={{margin: 0, padding: 0, paddingTop: 5}}>Sign Out</p>
                            </Button>                                              
                        </Box>                     
                    </ThemeProvider>
                )
            }
        }else{
            const mdTheme = createTheme();
            return(
                <ThemeProvider theme={mdTheme}>
                        <Container component="main" maxWidth="xs">
                            <CssBaseline />
                            <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <div style={{paddingRight: 25, paddingLeft: 25, display: 'flex', alignItems: 'center', alignContent: 'center', justifyItems: 'center', flexDirection: 'column'}}>
                                    <a href="https://apps.apple.com/us/app/peer-to-peer-mobile/id1608704406" target={"_blank"}><img draggable={false} src={logo} style={{width: 90, height: 90, objectFit: 'cover', textAlign: 'center', alignSelf: 'center', backgroundColor: '#FFF', borderRadius: 10, boxShadow: '0 0 3px 3px #ededed', margin: 0, padding: 0, marginBottom: 10}}/></a>
                                    <h1 style={{textAlign: 'center', fontWeight: 'bold', fontSize: 35, margin: 0, padding: 0, marginBottom: 5}}>Peer to Peer</h1>                                
                                    <p style={{textAlign: 'center', color: '#AAA', margin: 0, padding: 0}}>The Peer to Peer website is specific for desktop use. Find us on the App Store by clicking on our logo above!</p>
                                </div>
                            </Box>
                        </Container>     
                </ThemeProvider>    
            );
        }
    }
}

export default MainDashboard;
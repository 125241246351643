import React from "react";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import logo from './peertopeerlogo.png';
import GettingStartedInfographic from './Assets/FINALGETTINGSTARTED3.png';

class InstructorInfographic extends React.Component{  
    constructor(props) {
        super(props);
        this.state = {
        }        
    }
    render(){
        const mdTheme = createTheme();
        return(
            <ThemeProvider theme={mdTheme}>
                    <Container component="main" maxWidth="s" style={{alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', textAlign: 'center'}}>
                        <img src={GettingStartedInfographic} style={{width: '50%', height: '50%', alignSelf: 'center'}}/>
                    </Container>     
            </ThemeProvider>   
        );        
    }
}

export default InstructorInfographic
import React from "react";

class TermsAndConditionsPage extends React.Component{  
    constructor(props) {
        super(props);
        this.state = {
        }        
    }
    render(){
        return(
            <div>Terms and Conditions Page</div>
        )
    }
}

export default TermsAndConditionsPage;
import React from "react";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import logo from './peertopeerlogo.png';
import GettingStartedInfographic from './Assets/GettingStartedInfographic2.png';
import AppStoreBadge from './appstorebadge.png';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

class AboutPage extends React.Component{  
    constructor(props) {
        super(props);
        this.state = {
            
        }        
    }
    render(){
        const mdTheme = createTheme();
        return(
            <ThemeProvider theme={mdTheme}>
                    <Container component="main" maxWidth="m" style={{marginBottom: 25}}>
                        <CssBaseline />
                        <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{paddingRight: 25, paddingLeft: 25, display: 'flex', alignItems: 'center', alignContent: 'center', justifyItems: 'center', flexDirection: 'column'}}>
                            <img draggable={false} src={logo} style={{width: 90, height: 90, objectFit: 'cover', textAlign: 'center', alignSelf: 'center', backgroundColor: '#FFF', borderRadius: 10, boxShadow: '0 0 3px 3px #ededed', margin: 0, padding: 0, marginBottom: 10}}/>
                                <h1 style={{textAlign: 'center', fontWeight: 'bold', fontSize: 35, margin: 0, padding: 0, marginBottom: 15, lineHeight: 1.25}}>Our Purpose and How We Fulfill It</h1>                                
                                <p style={{marginRight: 50, marginLeft: 50, textAlign: 'center'}}>
                                    Peer to Peer was created to provide students with a tool to seek out meaningful academic clarification on concepts and subjects that they might be struggling with.
                                    The technology behind this platform is pretty straightforward, students can join the classes their instructors create, then when they feel like they need any type of assistance, they can go right ahead and upload a question to the
                                    respected class feed.
                                    When posting, students can use a variety of tools including text content, image uploads, rich mathematical syntax, and even code blocks. 
                                    Once posted, class members will be notified, allowing students and instructors to engage in helpful discussion whenever they are available, fill any gaps in knowledge, and mark
                                    comments that led to solutions! We believe what makes Peer to Peer powerful, is it's potential to help amplify a culture of <b style={{padding: 0, margin: 0}}>confidence</b> and <b style={{padding: 0, margin: 0}}>community</b>, in which every single student not only feels
                                    comfortable asking questions, but are also encouraged to assist others, in one clutter free, focused space. 
                                </p>
                                <h2 style={{textAlign: 'center', fontWeight: 'bold', fontSize: 30, margin: 0, padding: 0, marginBottom: 15, lineHeight: 1.25, marginTop: 25}}>Integrating Peer to Peer Into Your Classroom in 3 Steps</h2>
                                <Box style={{marginBottom: 20, marginTop: 15, width: '100%', alignContent: 'center'}}>
                                    <p style={{textAlign: 'center', padding: 0, margin: 0, marginBottom: 5}}>1. Submit Instructor Interest Form</p>
                                    <p style={{textAlign: 'center', padding: 0, margin: 0, marginBottom: 5}}>2. An Instructor Key will be sent back which can be used on sign up</p>
                                    <p style={{textAlign: 'center', padding: 0, margin: 0, marginBottom: 5}}>3. Create classes</p>
                                    <Grid container spacing={1} justifyContent={'center'} marginTop={0}>
                                        <Grid item xs={4}>
                                            <Button variant="outlined" style={{width: '100%', height: 50}} onClick={() => {
                                                window.open("https://peertopeerweb.app/instructorInfographic", "_blank")
                                            }}>
                                                Getting Started Infographic
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button variant="outlined" style={{width: '100%', height: 50}} onClick={() => {
                                                window.open("https://tinyurl.com/mu2xrhda", "_blank")
                                            }}>
                                                Instructor Interest Form
                                            </Button>                                    
                                        </Grid>    
                                        
                                    </Grid>

                                </Box> 
                                <h2 style={{textAlign: 'center', fontWeight: 'bold', fontSize: 30, margin: 0, padding: 0, marginBottom: 15, lineHeight: 1.25, marginTop: 25}}>We are also on the App Store!</h2>                                
                                <a href="https://apps.apple.com/us/app/peer-to-peer-mobile/id1608704406" target={"_blank"}><img draggable={false} src={AppStoreBadge} style={{width: 150, height: 50}}/></a>

                            </div>
                        </Box>
                    </Container>     
            </ThemeProvider>   
        );        
    }
}

export default AboutPage;

import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import HelpPageGif from './Assets/helpPageGif.gif';
import CreateClassGIF from './Assets/HelpCenterGIFS/CreateClass.gif';

class HelpCenterPage extends React.Component{  
    constructor(props) {
        super(props);
        this.state = {
        }        
    }
    render(){
        const theme = createTheme();
        return(
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="md">

                    <Box sx={{marginTop: 15, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Typography component="h1" variant="h5">
                            Peer to Peer Help Center
                        </Typography>

                        {/* <p>Instructor Features</p> */}
                        <Accordion style={{marginTop: 25, width: '100%'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                            <Typography>Creating a new class</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{backgroundColor: 'red'}}>
                                <img src={CreateClassGIF} style={{width: '100%', objectFit: 'cover', height: 200, marginBottom: 15}}/>                                
                            </AccordionDetails>
                        </Accordion>   

                        <Accordion style={{marginTop: 25}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                            <Typography>Managing Class Members</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <img src={HelpPageGif} style={{width: '100%', height: 200, objectFit: 'cover', marginBottom: 15}}/>                                

                                <Typography>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>                                               
                    </Box>
                </Container>    
            </ThemeProvider>                
        )
    }
}

export default HelpCenterPage;
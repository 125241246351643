import React, { useState, useEffect} from "react";
import * as firebase from 'firebase/app';
import { getAuth, onAuthStateChanged, signOut, } from "firebase/auth";
import UserAuth from "./UserAuth";
import HelpCenterPage from './HelpCenterPage';
import MainDashboard from './MainDashboard';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import logo from './peertopeerlogo.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TermsAndConditionsPage from "./TermsAndConditionsPage";
import SupportPage from "./SupportPage";
import AboutPage from "./AboutPage";
import InstructorInfographic from "./InstructorInfographic";
import TodoPage from "./TodoPage";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";


let config = {
    apiKey: "AIzaSyA2BcvhjEwb4K3J6JV303osDT0ho5SJEto",
    authDomain: "peertopeer-86d00.firebaseapp.com",
    projectId: "peertopeer-86d00",
    storageBucket: "peertopeer-86d00.appspot.com",
    messagingSenderId: "168979251004",
    appId: "1:168979251004:web:de075da2bb4a245b0da839",
    measurementId: "G-EBG1HJ8T7W"
};

firebase.initializeApp(config)

function onAuthStateChange(callback) {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        // user.reload();
        if(user){
            callback({loggedIn: true});
        } else {
            callback({loggedIn: false});
        }
    });
}

function App() {
    const [user, setUser] = useState({ loggedIn: false });
    
    useEffect(() => {
        const unsubscribe = onAuthStateChange(setUser);
        return () => {
            unsubscribe();
        };
    }, []);
    const theme = createTheme();

    // if(!isMobile){
        if (!user.loggedIn) {
            return(
                <Router>
                    <Routes>
                        <Route path="/" element={<UserAuth/>}>
                        </Route>
                        <Route path="/privacy" element={<PrivacyPolicyPage/>}>
                        </Route>                             
                        <Route path="/tos" element={<TermsAndConditionsPage/>}>
                        </Route>         
                        <Route path="/support" element={<SupportPage/>}>
                        </Route>        
                        <Route path="/about" element={<AboutPage/>}>
                        </Route>      
                        <Route path="/instructorInfographic" element={<InstructorInfographic/>}>
                        </Route> 
                        <Route path="/todo" element={<TodoPage/>}>
                        </Route>                                                        
                    </Routes>
                </Router>
                
            );
        }else{
            return(
                <Router>
                    <Routes>
                        <Route path="/" element={<MainDashboard/>}>
                        </Route>
                        <Route path="/privacy" element={<PrivacyPolicyPage/>}>
                        </Route>
                        <Route path="/tos" element={<TermsAndConditionsPage/>}>
                        </Route>  
                        <Route path="/support" element={<SupportPage/>}>
                        </Route>                 
                        <Route path="/about" element={<AboutPage/>}>
                        </Route> 
                        <Route path="/instructorInfographic" element={<InstructorInfographic/>}>
                        </Route>       
                        <Route path="/todo" element={<TodoPage/>}>
                        </Route>                                            
                    </Routes>
                </Router>            
                
            );                 
            
        }


}

export default App;



import React from "react";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import logo from './peertopeerlogo.png';

class SupportPage extends React.Component{  
    constructor(props) {
        super(props);
        this.state = {
        }        
    }
    render(){
        const mdTheme = createTheme();
        return(
            <ThemeProvider theme={mdTheme}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{paddingRight: 25, paddingLeft: 25, display: 'flex', alignItems: 'center', alignContent: 'center', justifyItems: 'center', flexDirection: 'column'}}>
                                <a><img draggable={false} src={logo} style={{width: 90, height: 90, objectFit: 'cover', textAlign: 'center', alignSelf: 'center', backgroundColor: '#FFF', borderRadius: 10, boxShadow: '0 0 3px 3px #ededed', margin: 0, padding: 0, marginBottom: 10}}/></a>
                                <h1 style={{textAlign: 'center', fontWeight: 'bold', fontSize: 35, margin: 0, padding: 0, marginBottom: 15, lineHeight: 1.25}}>Need support, have any questions? Send us an email</h1>                                
                                <p style={{textAlign: 'center', color: '#AAA', margin: 0, padding: 0, fontSize: 20}}>peertopeerweb@gmail.com</p>
                            </div>
                        </Box>
                    </Container>     
            </ThemeProvider>   
        );        
    }
}

export default SupportPage;

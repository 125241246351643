import React, { useState, useEffect} from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {where, collection, addDoc, getFirestore, doc, getDoc, setDoc, updateDoc, arrayUnion, query, getDocs, increment, serverTimestamp} from "firebase/firestore"
import {getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail} from 'firebase/auth';
import logo from './peertopeerlogo.png';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

class UserAuth extends React.Component{  
    constructor(props){
        super(props);
        this.state = {
            authActiveView: "signUp",
            signUpEmail: "",
            signUpIsInstructor: false,
            signUpFirstName: "",
            signUpLastName: "",
            signUpPassword: "",
            signUpInstructorKey: "",
            signUpShowPassword: false,
            signUpError: "",
            logInEmail: "",
            logInPassword: "",
            logInError: "",
            logInShowPassword: false,
            db: getFirestore(),
            auth: getAuth(),
        }
    }

    async instructorSignUp(){
        this.setState({authActiveView: 'creatingAccountLoading'});
        this.setState({signUpFirstName: this.state.signUpFirstName.trim()});
        this.setState({signUpLastName: this.state.signUpLastName.trim()});
        this.setState({signUpFirstName: this.state.signUpFirstName[0].toUpperCase() + this.state.signUpFirstName.substring(1)})
        this.setState({signUpLastName: this.state.signUpLastName[0].toUpperCase() + this.state.signUpLastName.substring(1)});

        this.setState({signUpEmail: this.state.signUpEmail.trim()});
        this.setState({signUpEmail: this.state.signUpEmail.toLowerCase()});
        this.setState({signUpError: ""});
        this.setState({signUpInstructorKey: this.state.signUpInstructorKey.trim()});

        await getDoc(doc(this.state.db, "Users", this.state.signUpEmail)).then((instructorDoc) => {
            if(instructorDoc.exists){
                if(this.state.signUpInstructorKey == instructorDoc.data()['instructorKey']){
                        createUserWithEmailAndPassword(this.state.auth, this.state.signUpEmail, this.state.signUpPassword).then((userCreds) => {
                            console.log(userCreds.user);
                            sendEmailVerification(userCreds.user).then(() => {
                            }).catch((error) => {});
                            (async () => {
                                await updateDoc(doc(this.state.db, "Users", this.state.signUpEmail), {
                                    fName: this.state.signUpFirstName,
                                    lName: this.state.signUpLastName
                                }).then(() => {
                                    this.setState({authActiveView: ""});
                                    this.setState({signUpEmail: ""});
                                    this.setState({signUpIsInstructor: false});
                                    this.setState({signUpLastName: ""});
                                    this.setState({signUpFirstName: ""});
                                    this.setState({signUpPassword: ""});
                                    this.setState({signUpIsInstructor: false});
                                    this.setState({signUpInstructorKey: ""});  
                                    this.setState({signUpError: ""});   
                                    this.setState({signUpShowPassword: false}); 
                                }).catch((error) => {});
                            })().catch((error) => {});
                        }).catch((error) => {
                            this.setState({signUpError: "There was an error signing you up"});
                            this.setState({authActiveView: 'signUp'});
                        });                     
                }else{
                    this.setState({signUpError: "Instructor Key is invalid"});
                    this.setState({authActiveView: 'signUp'});                    
                }
            }else{
                this.setState({signUpError: "There was an error signing you up"});
                this.setState({authActiveView: 'signUp'});
            }
        }).catch((error) => {
            this.setState({signUpError: "There was an error signing you up"});
            this.setState({authActiveView: 'signUp'});
        });         
    }

    async studentSignUp(){
        this.setState({authActiveView: 'creatingAccountLoading'});
        this.setState({signUpFirstName: this.state.signUpFirstName.trim()});
        this.setState({signUpLastName: this.state.signUpLastName.trim()});
        this.setState({signUpEmail: this.state.signUpEmail.trim()});
        this.setState({signUpFirstName: this.state.signUpFirstName[0].toUpperCase() + this.state.signUpFirstName.substring(1)})
        this.setState({signUpLastName: this.state.signUpLastName[0].toUpperCase() + this.state.signUpLastName.substring(1)});
                
        this.setState({signUpError: ""});

        createUserWithEmailAndPassword(this.state.auth, this.state.signUpEmail, this.state.signUpPassword).then((userCreds) => {
            sendEmailVerification(userCreds.user).then(() => {
            }).catch((error) => {});            
            (async () => {
                await setDoc(doc(this.state.db, "Users", this.state.signUpEmail), {
                    classes: [],
                    fName: this.state.signUpFirstName,
                    lName: this.state.signUpLastName,
                    role: 'student',
                    posts: [],
                    profilePicture: "",
                    banner: "",
                    solutions: [],
                    notificationsToken: "",
                    tutorialCompleted: false,
                }).then(() => {
                    this.setState({authActiveView: ""});
                    this.setState({signUpEmail: ""});
                    this.setState({signUpIsInstructor: false});
                    this.setState({signUpLastName: ""});
                    this.setState({signUpFirstName: ""});
                    this.setState({signUpPassword: ""});
                    this.setState({signUpIsInstructor: false});
                    this.setState({signUpInstructorKey: ""});  
                    this.setState({signUpError: ""});   
                    this.setState({signUpShowPassword: false}); 
                }).catch((error) => {
                    this.setState({error: 'There was an issue creating your account'})
                    this.setState({authActiveView: 'signUp'});
                });  
            })()         
        }).catch((error) => {
            this.setState({error: 'There was an issue creating your account'})
            this.setState({authActiveView: 'signUp'}); 
        });                                 
    }     

    async logIn(){
        this.setState({authActiveView: 'loggingInLoading'});
        this.setState({logInError: ''});
        this.setState({logInEmail: this.state.logInEmail.trim()});
        this.setState({logInEmail: this.state.logInEmail.toLowerCase()});

        signInWithEmailAndPassword(this.state.auth, this.state.logInEmail, this.state.logInPassword).then(() => {
            this.setState({logInEmail: ""});
            this.setState({logInPassword: false});
            this.setState({logInShowPassword: ""});
            this.setState({logInError: ""});
            this.setState({authActiveView: "signUp"});
        }).catch((error) => {
            this.setState({logInError: "There was an issue logging you in at the moment"});
            this.setState({authActiveView: 'logIn'});
        });          
    }
    
    AuthActiveView = () => {
        switch (this.state.authActiveView) {
            case "creatingAccountLoading":
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', paddingTop: 5, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}}>
                        <CircularProgress />
                        <h2>Creating your account...</h2>
                    </Box>                       
                );
                break;    
            case "loggingInLoading":
                return(
                    <Box sx={{display: "flex", flexDirection: 'column', position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: '50%', bgcolor: 'background.paper', paddingTop: 5, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}}>
                        <CircularProgress />
                        <h2>Logging into your account...</h2>
                    </Box>                       
                );
                break;                            
            case "signUp":
                return(
                    <Container component="main" maxWidth="xs">
                        <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: 75, boxShadow: '0 0 3px 3px #ededed', display: 'flex', flexDirection: 'row'}}>
                            <h1 style={{marginLeft: 25}}>Peer to Peer</h1>
                            <div style={{marginLeft: 25, display: 'flex', flexDirection: 'row',alignItems: 'center', paddingTop: 15}}>
                                <a style={{textDecoration: 'none'}}  href="https://peertopeerweb.app/about" target={"_blank"}><p style={{padding: 0, margin: 0, marginRight: 25, color: '#000'}}>About</p></a>
                                <a style={{textDecoration: 'none'}}  href="https://tinyurl.com/mu2xrhda" target={"_blank"}><p style={{padding: 0, margin: 0, marginRight: 25, color: '#000'}}>Instructor Request Form</p></a>
                                <a style={{textDecoration: 'none'}}  href="https://peertopeerweb.app/todo" target={"_blank"}><p style={{padding: 0, margin: 0, marginRight: 25, color: '#000'}}>Our Todo List</p></a>
                                <a style={{textDecoration: 'none'}}  href="https://peertopeerweb.app/support" target={"_blank"}><p style={{padding: 0, margin: 0, marginRight: 0, color: '#000'}}>Support</p></a>
                            </div>    
                            
                        </div>
                        <img src={logo} style={{width: 45, height: 45, objectFit: 'cover', backgroundColor: '#FFF', borderRadius: 10, boxShadow: '0 0 3px 3px #ededed', margin: 0, padding: 0, position: 'absolute', top: 15, right: 25}}/>                                                                                    

                        <Box sx={{marginTop: 15, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                            {/* <h1 style={{fontWeight: 'bold'}}>Peer to Peer</h1> */}
                            <Typography component="h1" variant="h5">
                                Welcome, register below
                            </Typography>
                            <Box component="form" sx={{ mt: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="primary" checked={this.state.signUpIsInstructor} onChange={() => {
                                                    this.setState({signUpIsInstructor: !this.state.signUpIsInstructor})
                                                }}/>
                                            }
                                            label="I am an instructor"
                                        />
                                        
                                    </Grid> 
                                    
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="firstName"
                                            required
                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            onChange={(fName) => {this.setState({signUpFirstName: fName.target.value})}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="lastName"
                                            label="Last Name"
                                            name="lastName"
                                            onChange={(lName) => {this.setState({signUpLastName: lName.target.value})}}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            onChange={(email) => {this.setState({signUpEmail: email.target.value})}}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type={this.state.signUpShowPassword == true ? "" : "password"}
                                            id="password"
                                            onChange={(password) => {this.setState({signUpPassword: password.target.value})}}
                                        />
                                    </Grid>
                                    {this.state.signUpIsInstructor == true ? 
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                name="instructorKey"
                                                label="Instructor Key"
                                                id="instructorKey"
                                                onChange={(instructorKey) => {this.setState({signUpInstructorKey: instructorKey.target.value})}}
                                            />
                                            <p style={{color: '#AAA', padding: 0, margin: 0, marginTop: 10}}>Need an Instructor Key? Request one <a href="https://tinyurl.com/mu2xrhda" target={"_blank"}>here</a></p>                                                                                                                   
                                        </Grid>  
                                        :
                                        null                              
                                    }
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="primary" checked={this.state.signUpShowPassword} onChange={() => {
                                                    this.setState({signUpShowPassword: !this.state.signUpShowPassword})
                                                }}/>
                                            }
                                            label="Show password"
                                        />                                        
                                    </Grid>
                                </Grid>


                                {this.state.signUpError != "" ?
                                    <p style={{margin: 0, padding: 0, marginTop: 5, color: 'red'}}>{this.state.signUpError}</p>                                
                                :
                                    null
                                }
                                
                                {this.state.signUpIsInstructor == true ? 
                                    <div>   
                                        {this.state.signUpEmail.trim() != "" && this.state.signUpFirstName.trim() != "" && this.state.signUpLastName.trim() != "" && this.state.signUpPassword.trim() != "" && this.state.signUpInstructorKey.trim() != "" ?
                                            <Button
                                                onSubmit={() => {}}
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                                onClick={() => {
                                                    this.instructorSignUp()
                                                }}
                                            >
                                                Sign Up
                                            </Button>
                                            :
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                                disabled="true"
                                            >
                                                Sign Up
                                            </Button>
                                        }
                                    </div>
                                :
                                    <div>   
                                        {this.state.signUpEmail.trim() != "" && this.state.signUpFirstName.trim() != "" && this.state.signUpLastName.trim() != "" && this.state.signUpPassword.trim() != "" ?
                                            <Button
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            onClick={() => {
                                                this.studentSignUp()
                                            }}
                                            >
                                            Sign Up
                                            </Button>
                                            :
                                            <Button
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            disabled="true"
                                            >
                                            Sign Up
                                            </Button>
                                        }
                                    </div>                            
                                }

                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Link variant="body2" onClick={() => {
                                            this.setState({signUpEmail: ""});
                                            this.setState({signUpIsInstructor: false});
                                            this.setState({signUpLastName: ""});
                                            this.setState({signUpFirstName: ""});
                                            this.setState({signUpPassword: ""});
                                            this.setState({signUpIsInstructor: false});
                                            this.setState({signUpInstructorKey: ""});  
                                            this.setState({signUpError: ""});   
                                            this.setState({signUpShowPassword: false}); 
                                            this.setState({authActiveView: "logIn"});
                                        }}>                                        
                                            Already have an account? Log in
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Container>                      
                );
                break;  
            case "logIn":
                return(
                    <Container component="main" maxWidth="xs">
                        <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: 75, boxShadow: '0 0 3px 3px #ededed', display: 'flex', flexDirection: 'row'}}>
                            <h1 style={{marginLeft: 25}}>Peer to Peer</h1>
                            <div style={{marginLeft: 25, display: 'flex', flexDirection: 'row',alignItems: 'center', paddingTop: 15}}>
                                <a style={{textDecoration: 'none'}}  href="https://peertopeerweb.app/about" target={"_blank"}><p style={{padding: 0, margin: 0, marginRight: 25, color: '#000'}}>About</p></a>
                                <a style={{textDecoration: 'none'}}  href="https://tinyurl.com/mu2xrhda" target={"_blank"}><p style={{padding: 0, margin: 0, marginRight: 25, color: '#000'}}>Instructor Request Form</p></a>
                                <a style={{textDecoration: 'none'}}  href="https://peertopeerweb.app/todo" target={"_blank"}><p style={{padding: 0, margin: 0, marginRight: 25, color: '#000'}}>Our Todo List</p></a>
                                <a style={{textDecoration: 'none'}}  href="https://peertopeerweb.app/support" target={"_blank"}><p style={{padding: 0, margin: 0, marginRight: 0, color: '#000'}}>Support</p></a>
                            </div>                            
                        </div>
                        <img src={logo} style={{width: 45, height: 45, objectFit: 'cover', backgroundColor: '#FFF', borderRadius: 10, boxShadow: '0 0 3px 3px #ededed', margin: 0, padding: 0, position: 'absolute', top: 15, right: 25}}/>                                                                                    

                        <Box
                        sx={{
                            marginTop: 15,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                        >
                            {/* <h1 style={{fontWeight: 'bold'}}>Peer to Peer</h1> */}
                            <Typography component="h1" variant="h5">
                                Welcome back, log in here!
                            </Typography>
                            <Box component="form" sx={{ mt: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        onChange={(email) => {this.setState({logInEmail: email.target.value})}}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type={this.state.logInShowPassword == true ? "" : "password"}
                                        id="password"
                                        onChange={(password) => {this.setState({logInPassword: password.target.value})}}
                                        />
                                          
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Link variant="body2" style={{marginTop: 15}} onClick={() => {
                                                (async () => {
                                                    sendPasswordResetEmail(this.state.auth, this.state.logInEmail).then(() => {
                                                        alert("Sent password reset email to " + this.state.logInEmail);
                                                    }).catch((error) => {
                                                        alert("There was an issue sending password reset email");
                                                    });
                                                })().catch((error) => {});
                                            }}>
                                            I forgot my password. Enter email above first
                                        </Link> 
                                    </Grid>                                     

                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox color="primary" checked={this.state.logInShowPassword} onChange={() => {
                                                    this.setState({logInShowPassword: !this.state.logInShowPassword})
                                                }}/>
                                            }
                                            label="Show password"
                                        />                                        
                                    </Grid>                                    
                                </Grid>

                                {this.state.logInError != "" ?
                                    <p style={{margin: 0, padding: 0, marginTop: 5, color: 'red'}}>{this.state.logInError}</p>                                
                                :
                                    null
                                }
                                
                                {this.state.logInEmail.trim() != "" && this.state.logInPassword.trim() != "" ?
                                    <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={() => {
                                        this.logIn()
                                    }} 
                                    >
                                    Sign In
                                    </Button>
                                    :
                                    <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    disabled="true"
                                    >
                                    Sign In
                                    </Button>
                                }

                                <Grid container justifyContent="flex-end">
                                    <Grid item  display={'flex'}>
                                        <Link variant="body2" onClick={() => {
                                            this.setState({logInEmail: ""});
                                            this.setState({logInPassword: false});
                                            this.setState({logInShowPassword: ""});
                                            this.setState({logInError: ""});
                                            this.setState({authActiveView: "signUp"});
                                        }}>
                                            Don't have an account? Sign up
                                        </Link>                                    
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Container>  
                );                            
                break;        
            default:
                break;
        }
    }

    render(){
        const theme = createTheme();
        
        if(!isMobile){
            return(
                <ThemeProvider theme={theme}>
                    <this.AuthActiveView/>
                </ThemeProvider>              
            )
        }else{
            return(
                <ThemeProvider theme={theme}>
                        <Container component="main" maxWidth="xs">
                            <CssBaseline />
                            <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <div style={{paddingRight: 25, paddingLeft: 25, display: 'flex', alignItems: 'center', alignContent: 'center', justifyItems: 'center', flexDirection: 'column'}}>
                                    <a href="https://apps.apple.com/us/app/peer-to-peer-mobile/id1608704406" target={"_blank"}><img src={logo} style={{width: 90, height: 90, objectFit: 'cover', textAlign: 'center', alignSelf: 'center', backgroundColor: '#FFF', borderRadius: 10, boxShadow: '0 0 3px 3px #ededed', margin: 0, padding: 0, marginBottom: 10}}/></a>
                                    <h1 style={{textAlign: 'center', fontWeight: 'bold', fontSize: 35, margin: 0, padding: 0, marginBottom: 5}}>Peer to Peer</h1>                                
                                    <p style={{textAlign: 'center', color: '#AAA', margin: 0, padding: 0}}>The Peer to Peer website is specific for desktop use. Find us on the App Store by clicking on our logo above!</p>
                                </div>
                            </Box>
                        </Container>     
                </ThemeProvider>                  
            );
        }
    }
}

export default UserAuth;
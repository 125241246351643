import React from "react";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import logo from './peertopeerlogo.png';

class TodoPage extends React.Component{  
    constructor(props) {
        super(props);
        this.state = {
        }        
    }
    render(){
        const mdTheme = createTheme();
        return(
            <ThemeProvider theme={mdTheme}>
                    <Container component="main" maxWidth="m">
                        <CssBaseline />
                        <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{paddingRight: 25, paddingLeft: 25, display: 'flex', alignItems: 'center', alignContent: 'center', justifyItems: 'center', flexDirection: 'column'}}>                            
                                <h1 style={{textAlign: 'center', fontWeight: 'bold', fontSize: 35, margin: 0, padding: 0, marginBottom: 15, lineHeight: 1.25}}>Stuff we Want to Get Done Soon (In Order by Priority)</h1>                                
                                <p style={{margin: 0, padding: 0, marginTop: 10, textAlign: 'center'}}>1. Android Mobile App</p>
                                <p style={{margin: 0, padding: 0, marginTop: 5, textAlign: 'center'}}>2. Mathematical Syntax Input for Mobile App</p>
                                <p style={{margin: 0, padding: 0, marginTop: 5, textAlign: 'center'}}>3. Live Video/Audio Chat</p>
                            </div>
                        </Box>
                    </Container>     
            </ThemeProvider>   
        );        
    }
}

export default TodoPage;